import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ShemeTypeMasterService } from 'src/Services/shemme-type.service';
import { WorkTypeMasterService } from 'src/Services/work-type-master.service';
import { SectionMasterService } from '../Master/section-master/section-master.service';
import { TenderBidderMasterService } from '../Master/tender-bidder-master/tender-bidder-master.service';
import { WorkMasterService } from '../Master/work-master/work-master.service';
import { StaticVariable } from '../../globle.class';
import { MUserMasterPojo } from '../../pojo/management-user-pojo';
import { SchemeTypeMasterPojo } from '../../Pojo/scheme-type-master-pojo';
import { SectionMasterPojo } from '../../Pojo/section-master.pojo';
import { TenderBidderMasterPojo } from '../../Pojo/tender-bidder-pojo';
import { WorkMasterPojo } from '../../Pojo/work-master-pojo';
import { WorkTypeMasterPojo } from '../../Pojo/work-type-master-pojo';
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { reduce } from 'rxjs-compat/operator/reduce';
import { StatusService} from '../../../Services/Status.service';
import { WorkMasterTimeLinePojo } from 'src/app/Pojo/WorkMasterTimeLine.pojo';
import { WorkMasterTimeLine} from '../../../Services/WorkMasterTimeline.Service';
import { TimeLineStatusPojo } from 'src/app/Pojo/TimeLineStatusPojo.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
//pdf
body = [];
tableBody = [];
content = [];

documentTime: string;
  model: NgbDateStruct;
  date: { year: number, month: number };
  autoClose:true;
  addProjDate: NgbDateStruct;
  editProjDate: NgbDateStruct;
  editEntryDate:NgbDateStruct
  editendDate:NgbDateStruct;
  editextendDate1:NgbDateStruct;
  editextendDate2:NgbDateStruct;
  from;
  to;
  status;

  workamount;
  amt;
  bidderId;
  amount;
  dfrom;
  dto;
  projectDate;
  entryDate;
  errorMessage = '';

  
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';
  id: number;
  isLoading: boolean = true;
  loggedUser = new MUserMasterPojo();
  workArr : WorkMasterPojo[] = [];
  addWork = new WorkMasterPojo();
  editWork = new WorkMasterPojo();
  shemeArr : SchemeTypeMasterPojo[] = [];
  workTypeArr : WorkTypeMasterPojo[] = [];
  sectionArr : SectionMasterPojo[] = [];
  bidderarr:TenderBidderMasterPojo[]=[]
  tenderbidderArr:TenderBidderMasterPojo[]=[];
  statusarr: TimeLineStatusPojo[]=[];
  timearr:WorkMasterTimeLinePojo[]=[];
  timeLine= new WorkMasterTimeLinePojo();
  timeLinearr:WorkMasterTimeLinePojo[]=[];
  fromDate:string;
  ran:number=0;
  workdat = new WorkMasterPojo();
  rFrom: any;
  rTo: any;
  rStatus: any;
  rbidderId: any;
  resultSlotId: any;
  dateFlag:any;
  amountFlag:string;
  bid;
  rdateFlag;
  tDate: Date;
  fDate: Date;
  typeId: number;
  bookingList:WorkMasterPojo[]=[];

  
constructor(private workMasterService: WorkMasterService,private schemeTypeService:ShemeTypeMasterService,
        private calendar: NgbCalendar,private workTypeMasterservice:WorkTypeMasterService,private sectionMasterService: SectionMasterService,private tenderBidderMasterService: TenderBidderMasterService,private statusservice: StatusService, private workmastertimeline: WorkMasterTimeLine) { }

ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
    this.addWork.projectDate = moment().toDate();
    this.fDate = moment().toDate();
    this.tDate = moment().toDate();
    $( '#addAfterClick' ).hide();
    $( '#editAfterClick' ).hide();
    $( '#deleteAfterClick' ).hide();
    $( '#showAfterClick' ).hide();
    $('#alret').hide();
    this.getShemeTypeAll();
    this.getWorkTypeAll();
    this.getWorkMasterAll();
    this.getSectionMasterAll();
    this.getTenderMasterMasterAll();
    this.getStatus();
    this.status="ALL";
    this.dateFlag="ALL";
    this.bidderId=0;
    this.amountFlag="ALL";
    this.amount=0;
    this.dateTypeChange();
    this.dateType()
    this.addWork.status ="INITIAL";
    this.addWork.progressPercentage=0;
    let r =  moment().format('HH:mm');
    console.log(r);
    this.addWork.projectTime = r;
}

dateTypeChange(){
  if(this.dateFlag=='ALL'){
      $('#df').hide();
      $('#dt').hide();
      $('#dfe').show();
      $('#dte').show();
  }else if(this.dateFlag=='STARTDATE' || this.dateFlag=='ENDDATE'){
      $('#df').show();
      $('#dt').show();
      $('#dfe').hide();
      $('#dte').hide();
  }
}

dateType(){
  if(this.amountFlag=='ALL'){
      $('#am').hide();
      $('#ame').show();
  }else if(this.amountFlag=='MIN' || this.amountFlag=='MAX'|| this.amountFlag=='MIN AGREEMENT' || this.amountFlag=='MAX AGREEMENT'){
      $('#am').show();
      $('#ame').hide();
  }
}
getStatus(){
    this.statusservice.getStatusAll().subscribe( response => {
        this.statusarr = response;
        console.log(this.statusarr);
    }, error => {
        bootbox.alert( 'Get WorkType All Error- HTTP Status ' + error.status );
    });
}
 
getTenderMasterMasterAll() {
  this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
      this.tenderbidderArr = response;
      if(this.tenderbidderArr.length>0)
      this.addWork.bidderId = this.tenderbidderArr[0].bidderId;
      this.isLoading = false;
  }, error => {
      bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      this.isLoading = false;
  } );
}

getSectionMasterAll() {
  this.sectionMasterService.getSectionMasterAll().subscribe( response => {
      this.sectionArr = response;
      this.isLoading = false;
      if(this.sectionArr.length>0)
        this.addWork.sectionId = this.sectionArr[0].sectionId;
  }, error => {
      bootbox.alert( 'Get Section All Error! - HTTP Status ' + error.status );
      this.isLoading = false;
  } );
}
getWorkMasterAll() {
    this.workMasterService.getWorkMasterAll().subscribe( response => {
        this.workArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( 'Get Work Master All Error! - HTTP Status ' + error.status );
        this.isLoading = false;
    } );
}
getShemeTypeAll() {
    this.schemeTypeService.getSchemeTypeAll().subscribe( response => {
        this.shemeArr = response;
        if(this.shemeArr.length>0)
        this.addWork.schemeTypeId = this.shemeArr[0].schemeTypeId;
    }, error => {
        bootbox.alert( 'Get ShemeType All Error! - HTTP Status ' + error.status );
    } );
}
getWorkTypeAll() {
    this.workTypeMasterservice.getWorkTypeAll().subscribe( response => {
        this.workTypeArr = response;
        if(this.workTypeArr.length>0)
        this.addWork.workTypeId = this.workTypeArr[0].workTypeId;
    }, error => {
        bootbox.alert( 'Get WorkType All Error! - HTTP Status ' + error.status );
    });
}
getWorkTypeById( id ) {
    if ( this.workTypeArr.find( r => r.workTypeId == id ) ) {
        return this.workTypeArr.find( r => r.workTypeId == id ).workType;
    } else {
        return id;
    }
    
}
getSchemeTypeById( id ) {
    if ( this.shemeArr.find( r => r.schemeTypeId == id ) ) {
        return this.shemeArr.find( r => r.schemeTypeId == id ).schemeType;
    } else {
        return id;
    }
}
getSectionTypeById( id ) {
    if ( this.sectionArr.find( r => r.sectionId == id ) ) {
        return this.sectionArr.find( r => r.sectionId == id ).sectionName;
    } else {
        return id;
    }
}
getBidderALL(id){
    if ( this. tenderbidderArr.find( r => r.bidderId == id ) ) {
        return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
    } else {
        return "";
    }  
}

getFetch() {
  $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
  this.isLoading = true;
  this.from = moment($('#df').val()).format('YYYY-MM-DD');
  this.to = moment($('#dt').val()).format('YYYY-MM-DD');
  if ((moment(this.from).diff(moment(this.to), 'days') <= 0)) {
      this.workMasterService.getWorkMaster(this.from,this.to,this.status,this.amountFlag,this.amount,this.dateFlag,this.bidderId).subscribe(response => {
          
          this.rFrom = this.from;
          this.rTo = this.to;
          this.rStatus = this.status;
          this.rbidderId = this.bidderId;
          this.rdateFlag=this.dateFlag;
          this.workArr = response;
          this.isLoading=false;
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();     
}, error => {
  bootbox.alert( 'Add Work Service Error! - HTTP Status ' + error.status );
  ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
  $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
}
);
}
}
downloadPDFReport(){
    $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
  this.isLoading = true;
  this.from = moment($('#df').val()).format('YYYY-MM-DD');
  this.to = moment($('#dt').val()).format('YYYY-MM-DD');
  if ((moment(this.from).diff(moment(this.to), 'days') <= 0)) {
      this.workMasterService.getWorkMasterReport(this.from,this.to,this.status,this.amountFlag,this.amount,this.dateFlag,this.bidderId).subscribe(response => {
          this.isLoading=false;
          const file = new Blob([response],{type:'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();     
}, error => {
  bootbox.alert( 'Add Work Service Error! - HTTP Status ' + error.status );
  ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
  $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
}
);
}
}

clickEdit( arg ) {
  this.editWork = Object.assign( {}, arg );
  if(this.editWork.projectDate != null && this.editWork.projectDate != undefined){
      this.editProjDate = { day: moment( this.editWork.projectDate ).date(), month: moment( this.editWork.projectDate ).month()+1, year: moment( this.editWork.projectDate ).year() };
       }
        if(this.editWork.entryDate != null && this.editWork.entryDate != undefined){
            this.editEntryDate = { day: moment( this.editWork.entryDate ).date(), month: moment( this.editWork.entryDate ).month()+1, year: moment( this.editWork.entryDate ).year() };
             }
       
       if(this.editWork.completionDateTime != null && this.editWork.completionDateTime != undefined){
        this.editendDate = { day: moment( this.editWork.completionDateTime ).date(), month: moment( this.editWork.completionDateTime ).month()+1, year: moment( this.editWork.completionDateTime ).year() };
         }
         else{ (this.editWork.completionDateTime == null)
            this.editendDate = { day: moment( this.editWork.completionDateTime ).date(), month: moment( this.editWork.completionDateTime ).month()+1, year: moment( this.editWork.completionDateTime ).year() };
        
         }
         if(this.editWork.extendDate1 != null && this.editWork.extendDate1 != undefined){
            this.editextendDate1 = { day: moment( this.editWork.extendDate1 ).date(), month: moment( this.editWork.extendDate1).month()+1, year: moment( this.editWork.extendDate1 ).year() };
             }
             else{ (this.editWork.extendDate1 == null)
                this.editextendDate1 = { day: moment( this.editWork.extendDate1 ).date(), month: moment( this.editWork.extendDate1 ).month()+1, year: moment( this.editWork.extendDate1 ).year() };
            
             }
             if(this.editWork.extendDate2 != null && this.editWork.extendDate2 != undefined){
                this.editextendDate2 = { day: moment( this.editWork.extendDate2 ).date(), month: moment( this.editWork.extendDate2).month()+1, year: moment( this.editWork.extendDate2 ).year() };
                 }
                 else{ (this.editWork.extendDate2 == null)
                    this.editextendDate2 = { day: moment( this.editWork.extendDate2 ).date(), month: moment( this.editWork.extendDate2 ).month()+1, year: moment( this.editWork.extendDate2 ).year() };
                   
                 }
                 this.timeLine.timeLineDate=moment().format('YYYY-MM-DD');   
                 this.getTimeLineDetailsById(this.editWork.workId);
   }
   getTimeLineDetailsById(id){
    this.workmastertimeline.getTimelineDetailsById(id).subscribe(res=>{
        this.timeLinearr=res;
        this.typeId=this.timeLine.timeLineTypeId;
        console.log(this.timeLinearr)
    })
   }
   addTimeLine(){
    this.timeLine.workId=this.editWork.workId;
    this.timeLine.timeLineTypeId=this.typeId;
    this.workmastertimeline.addTimeLineStatus( this.timeLine ).subscribe( response => {
           
            this.timeLine.timeLineTypeId=null;

            this.timeLine.timeLineDetails="";
            this.getTimeLineDetailsById(this.editWork.workId);
            $('#alret').show();
            setTimeout(()=> $('#alret').hide(),2500)
        
    }, error => {
        bootbox.alert( 'Add Work Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
    } );
}


editWorkMaster() {
  if(this.editendDate != null && this.editendDate != undefined){
      this.editWork.completionDateTime = moment( this.editendDate.year + '-' + this.editendDate.month + '-' + this.editendDate.day, 'YYYY-MM-DD' ).toDate();
      }
      if(this.editextendDate1 != null && this.editextendDate1 != undefined){
        this.editWork.extendDate1 = moment( this.editextendDate1.year + '-' + this.editextendDate1.month + '-' + this.editextendDate1.day, 'YYYY-MM-DD' ).toDate();
        }
        if(this.editextendDate2 != null && this.editextendDate2 != undefined){
            this.editWork.extendDate2 = moment( this.editextendDate2.year + '-' + this.editextendDate2.month + '-' + this.editextendDate2.day, 'YYYY-MM-DD' ).toDate();
            }
      if(this.editEntryDate != null && this.editEntryDate != undefined){
        this.editWork.entryDate = moment( this.editEntryDate.year + '-' + this.editEntryDate.month + '-' + this.editEntryDate.day, 'YYYY-MM-DD' ).toDate();
        }
    if(this.editProjDate != null && this.editProjDate != undefined){
        this.editWork.projectDate = moment( this.editProjDate.year + '-' + this.editProjDate.month + '-' + this.editProjDate.day, 'YYYY-MM-DD' ).toDate();
        }
        if(this.editWork.projectDate!= null && this.editWork.projectDate != undefined){
            this.editWork.completionDateTime = moment(this.editWork.projectDate).add(this.editWork.duration,'M').toDate();
            }
    this.editWork.userId = this.loggedUser.userId;
    ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
    $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
    this.workMasterService.updateWorkMaster( this.editWork ).subscribe( response => {
        if(response.code === 101)
        {
            bootbox.alert( 'Successfully Updated');
        $( '#editModal' ).modal( 'hide' );
        this.getWorkMasterAll();
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        }
        else if(response.code === 102)
        {
        bootbox.alert( response.response);
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        }
        
    }, error => {
        bootbox.alert( 'Update Work Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
    } );
}

delete( arg ) {
    this.id = arg;
}

deleteConfirm() {
    let no;
    const i = this.workArr.indexOf( this.workArr.find( response => response.workId  == this.id ) );
    $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
    this.workMasterService.deleteWorkMaster( this.id,this.loggedUser.userId ).subscribe( response => {
        no = response;
        if ( no == 0 ) {
            bootbox.alert( 'This row is already deleted' );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        }else  if ( no == 101 ) {
            bootbox.alert( 'You cannot Delete!This Work Master Linked to Some Other...' );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } else {
            this.workArr.splice( i, 1 );
            bootbox.alert( 'Successfully deleted' );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getWorkMasterAll();
      
        }
         }, error => {
        bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
    } );
}



downloadPDF() {
    this.tableBody = [];
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.content.push({
        text: StaticVariable.templeName + '\n' + StaticVariable.placeName, bold: true, fontSize: 17, alignment: 'center', margins: [0, 5, 0, 0]
    });
    this.content.push({
        text: 'FILES DETAILS REPORT', fontSize: 16, alignment: 'center', bold: true
    });

    this.content.push({
        text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
        fontSize: 10, bold: true, alignment: 'center'
    });

    if (this.rdateFlag == 0) {
        this.content.push({
            text: 'EXPECTED PARTICIPATED REPORT FROM : ' + moment(this.rFrom).format('DD-MM-YYYY') + ' TO : ' + moment(this.rTo).format('DD-MM-YYYY'), fontSize: 16, bold: true, alignment: 'center'
        });
    }

    if (this.rdateFlag == 1) {
        this.content.push({
            text: 'ACTUAL PARTICIPATED REPORT FROM : ' + moment(this.rFrom).format('DD-MM-YYYY') + ' TO : ' + moment(this.rTo).format('DD-MM-YYYY'), fontSize: 16, bold: true, alignment: 'center'
        });
    }

    if (this.rdateFlag == 2) {
        this.content.push({
            text: 'REGISTERED REPORT FROM : ' + moment(this.rFrom).format('DD-MM-YYYY') + ' TO : ' + moment(this.rTo).format('DD-MM-YYYY'), fontSize: 16, bold: true, alignment: 'center'
        });
    }
    this.content.push({
        text: 'REPORT FROM : ' + moment(this.from).format('DD-MM-YYYY') + ' TO : ' + moment(this.to).format('DD-MM-YYYY') + '\n', fontSize: 14, bold: true, alignment: 'center', font: 'Roboto'
      });

    if (this.rStatus == undefined) {
        this.content.push({
            text: 'SELECTED STATUS : ' + 'ALL' + '\n', fontSize: 14, alignment: 'center', bold: true
        });
    } else {
        this.content.push({
            text: 'SELECTED STATUS : ' + this.getSectionTypeById(this.rStatus) + '\n', fontSize: 14, alignment: 'center', bold: true
        });
    }
    if (this.rbidderId == undefined || this.rbidderId == 0 ) {
        this.content.push({
            text: 'SELECTED BIDDER NAME :' +'ALL'+ '\n', fontSize: 14, alignment: 'center', bold: true
        });
    } else {
        this.content.push({
            text: 'SELECTED BIDDER NAME : ' + this.getBidderALL(this.rbidderId) + '\n', fontSize: 14, alignment: 'center', bold: true
        });
    }

 





     this.content.push({
        text: '\n', fontSize: 8, alignment: 'center'
    });
    this.content.push({
        text: 'File Information\n', fontSize: 16, alignment: 'center', bold: true
    });

    this.body.push([
        { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'File Number', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Work Name', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Work Type', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Bidder Name', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Amount', fontSize: 10, alignment: 'center', style: 'taleHeader', bold: true },
        { text: 'Section', fontSize: 10, alignment: 'center', style: 'taleHeader', bold: true },
        { text: 'Agreement Date ', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Completion Date', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Status', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
        { text: 'Duration', fontSize: 10, alignment: 'center', style: 'tableHeader', bold: true },
    ]);
    for (let i = 0; i < this.workArr.length; i++) {
            this.body.push([
                { text: (i + 1), fontSize: 9, alignment: 'center' },
                { text: this.workArr[i].fileNo, fontSize: 9, alignment: 'center' },
                { text: this.workArr[i].workName, fontSize: 9, alignment: 'center' },
                { text: this.getWorkTypeById(this.workArr[i].workTypeId), fontSize: 9, alignment: 'center' },
                { text: this.getBidderALL(this.workArr[i].bidderId), fontSize: 9, alignment: 'center' },
                { text: this.workArr[i].amount, fontSize: 9, alignment: 'center' },
                { text: this.getSectionTypeById(this.workArr[i].sectionId), fontSize: 9, alignment: 'center' },
                { text: moment(this.workArr[i].projectDate).format('DD/MM/YYYY'), fontSize: 9, alignment: 'center' },
                { text: moment(this.workArr[i].completionDateTime).isValid()?moment(this.workArr[i].completionDateTime).format('DD/MM/YYYY'):"--", fontSize: 9, alignment: 'center' },
                { text: this.workArr[i].status, fontSize: 9, alignment: 'center' },
                { text: this.workArr[i].duration +' Months', fontSize: 9, alignment: 'center' },
            ]);
        }
    this.content.push({
        'table': {
            headerRows: 1,
            'body': this.body,
            widths: ['4%', '10%','11%', '15%',  '9%', '8%','10%', '9%', '8%', '8%', '9%']
        }
    });
    const dd = {
        'content': this.content,
        pageOrientation: 'landscape',
        pageSize: 'A4',
        footer: (currentPage, pageCount) => {
            return {
                margin: 10,
                columns: [
                    {
                        fontSize: 9,
                        text: [
                            {
                                text: '---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                    '\n',
                                margin: [0, 20]
                            },
                            {
                                text: StaticVariable.templeName + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                            }
                        ],
                        alignment: 'center'
                    }
                ]
            };
        }
    };
    bootbox.alert({ message: 'Successfully Downloaded PDF', className: 'h5 text-success text-center', closeButton: false, cancelButton: false, });
    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];
}
}