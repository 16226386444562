import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { SchemeTypeMasterPojo } from "src/app/Pojo/scheme-type-master-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class ShemeTypeMasterService {

    constructor( private httpclient: HttpClient ) { }
    
    getSchemeTypeAll(): Observable<SchemeTypeMasterPojo[]> {
        const url = StaticVariable.porturl + 'SchemeTypeMasterAll/';
        return this.httpclient.get<SchemeTypeMasterPojo[]>( url );
    }
     addSchemeType( arg: SchemeTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSchemeTypeMaster';
        return this.httpclient.post( url, arg );
    }

    updateReceiptTypeSchemeType( arg: SchemeTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSchemeTypeMaster';
        return this.httpclient.put( url, arg );
    }

    deleteSchemeType( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSchemeTypeMaster/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getSchemeTypeMasterById(id): Observable<SchemeTypeMasterPojo> {
        const url = StaticVariable.porturl + 'SchemeTypeMasterById/'+id;
        return this.httpclient.get<SchemeTypeMasterPojo>( url );
    }
}
