export class TenderBidderMasterPojo {

    bidderId:number;
    bidderName:string;
    ownerName:string;
    address:string;
    place:string;
    registerNo:string;
    panNo:string;
    gstInNo:string;
    mobileNo:string;
    phoneNo:string;
    email:string;
    userId:number;
    code:number;
    response:string;
}