export class TenderPojo {

    tenderId:number;
    fileDate:Date;
    fileTime:string;
    estimateId:number;
    tenderCloseDate:Date;
    tenderCloseTime:string;
    tenderDate:Date;
    tenderTime:string;
    tenderOpenDate:Date;
    tenderOpenTime:string;
    emdAmount:number;
    tenderFormCost:number;
    userId:number;
    tenderNo:string;
}