import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";

import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { PaymentProgressPojo } from "src/app/Pojo/Payment-progress.pojo";
import { PaymentReportPojo } from "src/app/Pojo/PaymentReportPojo";
import { WorkProgressReportPojo } from "src/app/Pojo/work-progress-report.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class PaymentProgressService {

    constructor( private httpclient: HttpClient ) { }
    
    getPaymentProgressAll(): Observable<PaymentProgressPojo[]> {
        const url = StaticVariable.porturl + 'PaymentProgressAll/';
        return this.httpclient.get<PaymentProgressPojo[]>( url );
    }
     addPaymentProgress( arg: PaymentProgressPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddPaymentProgress';
        return this.httpclient.post( url, arg );
    }

    updatePaymentProgress( arg: PaymentProgressPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdatePaymentProgress';
        return this.httpclient.put( url, arg );
    }

    deletePaymentProgress( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeletePaymentProgress/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
     
    getPaymentProgressById(id): Observable<PaymentProgressPojo> {
        const url = StaticVariable.porturl + 'PaymentProgressById/'+id;
        return this.httpclient.get<PaymentProgressPojo>( url );
    }
    autoGenerateEstNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGeneratePaymentNo/';
        return this.httpclient.get<any>(url);
    }
   uploadImage(file: File,id:number,no:string,desc:string): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+no+'/'+desc;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }
   deleteFtpImage( id: number): Observable<any> {
       const url = StaticVariable.porturl + 'FtpImageDelete/' + id;
       return this.httpclient.delete<any>( url );
   }
   getPaymentReportByOrderId(id): Observable<PaymentReportPojo> {
       const url = StaticVariable.porturl + 'PaymentReportByOrderId/'+id;
       return this.httpclient.get<PaymentReportPojo>( url );
   }
   
   getPaymentReportByWorkId(id): Observable<PaymentReportPojo> {
       const url = StaticVariable.porturl + 'PaymentReportByWorkId/'+id;
       return this.httpclient.get<PaymentReportPojo>( url );
   }
   getWorkProgressReportByWorkId(id): Observable<WorkProgressReportPojo> {
       const url = StaticVariable.porturl + 'GetWorkProgressReportByWorkId/'+id;
       return this.httpclient.get<WorkProgressReportPojo>( url );
   }
   
}
