import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MUserMasterPojo, ManagementRolePojo } from "src/app/pojo/management-user-pojo";

declare var bootbox: any;

@Injectable()

export class ManagementAuthenticationGuard implements CanActivate {

    loggedUser = new MUserMasterPojo();
    role = new ManagementRolePojo();

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
       // alert(JSON.stringify(this.loggedUser));
        this.role = JSON.parse( this.loggedUser.roleKey.roleKey );  

        switch ( route.data.roles ) {
             case 'rolemaster':
                if ( this.role.rolemaster ) return true;
                break;
            case 'usercreation':
                if ( this.role.usercreation ) return true;
                break;
                 case 'backup':
                if ( this.role.backup ) return true;
                break;
                 case 'receipttype':
                     if ( this.role.receipttype ) return true;
                     break;
                 case 'tenderbidder':
                     if ( this.role.tenderbidder ) return true;
                     break;
                      case 'workmaster':
                     if ( this.role.workmaster ) return true;
                     break;
                      case 'estimation':
                          if ( this.role.estimation ) return true;
                          break;
                      case 'quotation':
                          if ( this.role.quotation ) return true;
                          break;
                           case 'tender':
                          if ( this.role.tender ) return true;
                          break;
                           case 'workprogress':
                               if ( this.role.workprogress ) return true;
                               break;
                           case 'estimationlist':
                               if ( this.role.estimationlist ) return true;
                               break;
                           case 'quotationlist':
                               if ( this.role.quotationlist ) return true;
                               break;
                                case 'tenderlist':
                               if ( this.role.tenderlist ) return true;
                               break;
                                case 'entrylist':
                                    if ( this.role.entrylist ) return true;
                                    break;
                                case 'receipt':
                            if ( this.role.receipt ) return true;
                                    break;
                                case 'receiptlist':
                                    if ( this.role.receiptlist ) return true;
                                    break;
                                case 'action':
                                    if ( this.role.action ) return true;
                                    break;
                                     case 'quotationaction':
                                    if ( this.role.quotationaction ) return true;
                                    break;
                                     case 'tenderaction':
                                         if ( this.role.tenderaction ) return true;
                                         break;case 'tenderactionentry':
                                             if ( this.role.tenderactionentry ) return true;
                                             break;
                                             
                                       
                                         case 'tenderbidderdoc':
                                             if ( this.role.tenderbidderdoc ) return true;
                                             break;
                                         case 'tenderactionlist':
                                             if ( this.role.tenderactionlist ) return true;
                                             break;
                                              case 'tenderbidderdoclist':
                                             if ( this.role.tenderbidderdoclist ) return true;
                                             break;
                                              case 'work':
                                                  if ( this.role.work ) return true;
                                                  break;
                                              case 'workorder':
                                                  if ( this.role.workorder ) return true;
                                                  break;
                                              case 'agreement':
                                                  if ( this.role.agreement ) return true;
                                                  break;
                                                   case 'workProgress':
                                                  if ( this.role.workProgress ) return true;
                                                  break;
                                                   case 'paymentprogress':
                                                       if ( this.role.paymentprogress ) return true;
                                                       break;
                                                   case 'workcompletion':
                                                       if ( this.role.workcompletion ) return true;
                                                       break;
                                                   case 'progressreport':
                                                       if ( this.role.progressreport ) return true;
                                                       break;
                                                   case 'paymentreport':
                                                       if ( this.role.paymentreport ) return true;
                                                       break;
                                                   case 'settings':
                                                       if ( this.role.settings ) return true;
                                                       break;
                                                   case 'bidderreport':
                                                       if ( this.role.bidderreport ) return true;
                                                       break;
                                                   case 'tenderreport':
                                                       if ( this.role.tenderreport ) return true;
                                                       break;
                                                   case 'quotationreport':
                                                       if ( this.role.quotationreport ) return true;
                                                        break;
                                                       case 'sectionmaster':
                                                        if ( this.role.sectionmaster ) return true;
                                                       break;
                                                       
             }
           bootbox.alert( { message: 'ACCESS DENIED!', className: 'danger' } );
        return false;
    }
}