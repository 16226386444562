import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { WorkTypeMasterPojo } from 'src/app/Pojo/work-type-master-pojo';
import { WorktypeMasterService } from './worktype-master.service'

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-work-type-master',
  templateUrl: './work-type-master.component.html',
  styleUrls: ['./work-type-master.component.scss']
})

export class WorkTypeMasterComponent implements OnInit {
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';
  
  id: number;
  isLoading: boolean = true;
  
  loggedUser = new MUserMasterPojo();
  worktypeArr : WorkTypeMasterPojo[] = [];
  addworktype = new WorkTypeMasterPojo();
  editworktype = new WorkTypeMasterPojo();

  constructor( private worktypeMasterService: WorktypeMasterService) { }

  ngOnInit() {

    this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

    $( '#addAfterClick' ).hide();
    $( '#editAfterClick' ).hide();
    $( '#deleteAfterClick' ).hide();
    this.getworktypeMaster();
  }


  getworktypeMaster() {
    this.worktypeMasterService.getworktypeMasterAll().subscribe( response => {
        this.worktypeArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( 'Get Section All Error! - HTTP Status ' + error.status );
        this.isLoading = false;
    } );
}
add() {
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
    $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
     this.worktypeMasterService.addWorktypeMaster( this.addworktype ).subscribe( response => {
      $( '#addModal' ).modal( 'hide' );
      this.getworktypeMaster();
         bootbox.alert('Successfully Added');
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            
    }, error => {
        bootbox.alert( 'Add  Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
    } );
}

clickEdit( arg ) {
    this.editworktype = Object.assign( {}, arg );
     }

edit() {
     ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
    $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
    this.worktypeMasterService.updateWorktypeMaster( this.editworktype ).subscribe( response => {
      $( '#editModal' ).modal( 'hide' );
      this.getworktypeMaster();
          bootbox.alert('Successfully Updated');
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
         }, error => {
        bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
    } );
}

delete( arg ) {
    this.id = arg;
}

deleteConfirm() {
    let no;
    const i = this.worktypeArr.indexOf( this.worktypeArr.find( response => response. workTypeId  == this.id ) );
    $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
    this.worktypeMasterService.deleteWorktypeMaster( this.id,this.loggedUser.userId ).subscribe( response => {
        no = response;
        if ( no == 0 ) {
            bootbox.alert( 'This row is already deleted' );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        }else if ( no == 101 ) {
            bootbox.alert( 'You Cannot Delete!This Receipt Type linked to Some Other.. ');
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        }  else {
            this.worktypeArr.splice( i, 1 );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            bootbox.alert( 'Successfully deleted' );
            this.getworktypeMaster();
        }
        
    }, error => {
        bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
    } );
}

}
