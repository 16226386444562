import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { EstimateBidderPojo } from "src/app/Pojo/estimate-bidder-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class EstimateService {

    constructor( private httpclient: HttpClient ) { }
    
    getEstimateAll(): Observable<EstimatePojo[]> {
        const url = StaticVariable.porturl + 'EstimateAll/';
        return this.httpclient.get<EstimatePojo[]>( url );
    }
    getEstimateApprovedAll(): Observable<EstimatePojo[]> {
        const url = StaticVariable.porturl + 'EstimateApprovedAll/';
        return this.httpclient.get<EstimatePojo[]>( url );
    }
    getEstimateAllByTender(): Observable<EstimatePojo[]> {
        const url = StaticVariable.porturl + 'GetEstimateByEstimateTypeTender/';
        return this.httpclient.get<EstimatePojo[]>( url );
    }
    getEstimateAllByQuotation(): Observable<EstimatePojo[]> {
        const url = StaticVariable.porturl + 'GetEstimateByEstimateTypeQuotation/';
        return this.httpclient.get<EstimatePojo[]>( url );
    }
    getTenderActionByestId(id:number): Observable<TenderActionPojo[]> {
        const url = StaticVariable.porturl + 'GetTenderActionByEstimateId/'+id;
        return this.httpclient.get<TenderActionPojo[]>( url );
    }
    getQuotationActionByestId(id:number): Observable<QuotationPojo[]> {
        const url = StaticVariable.porturl + 'GetQuotationByEstimateId/'+id;
        return this.httpclient.get<QuotationPojo[]>( url );
    }
    getEstimateByBidderNum(no): Observable<EstimateBidderPojo> {
        const url = StaticVariable.porturl + 'BidderByEstimateNo/'+no;
        return this.httpclient.get<EstimateBidderPojo>( url );
    }
     addEstimate( arg: EstimatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddEstimate';
        return this.httpclient.post( url, arg );
    }

    updateEstimate( arg: EstimatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateEstimate';
        return this.httpclient.put( url, arg );
    }

    deleteEstimate( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteEstimate/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
     
    getEstimateById(id): Observable<EstimatePojo> {
        const url = StaticVariable.porturl + 'EstimateById/'+id;
        return this.httpclient.get<EstimatePojo>( url );
    }
    autoGenerateEstNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateEstimateNo/';
        return this.httpclient.get<any>(url);
    }
   uploadImage(file: File,id:number,no:string,desc:string): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+no+'/'+desc;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }
   deleteFtpImage( id: number): Observable<any> {
       const url = StaticVariable.porturl + 'FtpImageDelete/' + id;
       return this.httpclient.delete<any>( url );
   }
}
