export class StaticVariable {
      
// static porturl = 'http://192.168.0.3:8081/EngineeringSectionApi-v1/';
    
  //  static porturl = 'http://192.168.0.16:8081/EngineeringSectionApi-v1/';
   static porturl = 'http://148.66.133.79:8080/DemoEngineeringSectionApi-v1/';
  // static porturl = 'http://192.168.0.9:8081/EngineeringSectionApi-v1/';
    static loginTitle = 'ENGINEERING SECTION SOFTWARE';
    static loginPlace = 'THIRUCHENDUR - 614903';
    static templeName = 'ARULMIGU DHANDAYUTHAPANI SWAMI THIRUKKOIL';
    static placeName = 'PALANI - 624 601';


    static titleTamil1 = 'அருள்மிகு சுப்பிரமணியசுவாமி திருக்கோயில்';
    static titleTamil2 = 'திருச்செந்தூர்';
    static titleName = 'ARULMIGU DHANDAYUTHAPANI SWAMI THIRUKKOIL';  
    static footerName = 'Thiruchendur Murugan Temple';
    static pageWidth = 822;
    static pageHeight = 85;//50
    static marginLeft = 425;//5
    static marginRight = 5;//5
    static marginTop = 12;
    static marginBottom = 0;
    static prebookingDuration = 2;
    static preBookingTime = '05:00';
}