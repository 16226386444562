export class PaymentProgressPojo
{
      paymentId: number;
      orderId: number;
      voucherNo: string;
      voucherDate: Date;
      voucherTime: string;
      amount: number;
      userId: number;
      response:string;
      code:number;

}