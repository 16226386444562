import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { TimeLineStatusPojo } from 'src/app/Pojo/TimeLineStatusPojo.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class StatusService {
    constructor( private httpclient: HttpClient ) { }



    getStatusAll(): Observable<TimeLineStatusPojo[]> {
        const url = StaticVariable.porturl + 'GetTypeAll';
        return this.httpclient.get<TimeLineStatusPojo[]>( url );
    }
    //  addWorkType( arg: TimeLineStatusPojo ): Observable<any> {
    //     const url = StaticVariable.porturl + 'AddWorkTypeMaster';
    //     return this.httpclient.post( url, arg );
    // }

    // updateWorkTypeSchemeType( arg: TimeLineStatusPojo ): Observable<any> {
    //     const url = StaticVariable.porturl + 'UpdateWorkTypeMaster';
    //     return this.httpclient.put( url, arg );
    // }

    // deleteWorkType( id: number,uid ): Observable<any> {
    //     const url = StaticVariable.porturl + 'DeleteWorkTypeMaster/' + id+'/'+uid;
    //     return this.httpclient.delete<any>( url );
    // }
   
    // getWorkTypeMasterById(id): Observable<TimeLineStatusPojo> {
    //     const url = StaticVariable.porturl + 'WorkTypeMasterById/'+id;
    //     return this.httpclient.get<TimeLineStatusPojo>( url );
    // }
    getTimelineById(id:number): Observable<TimeLineStatusPojo[]> {
        const url = StaticVariable.porturl + 'GetTypesById/'+id;
        return this.httpclient.get<TimeLineStatusPojo[]>( url );
    }
}