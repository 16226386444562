import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

export class WorkOrderPojo{
    
      orderId:number;
      orderDate:Date;
      orderTime:string;
      bidderId:number;
      estimateId:number;
      depositAmount:number;
      tenderAmount:number;
      releaseAmount:number;
      balanceAmount:number;
      eeDate:Date;
      eeTime:string;
      orderNo:string;
      userId:number;
      imageList:FtpImagePojo[];
      code:number;
      response:string;
      lessAmount:number;
      finalAmount:number;
      withHeldPercentage:number;
      gstPercentage:number;
	gstAmount:number;
	itPercentage:number;
	itAmount:number;
	templeGstAmount:number;
      templeGstPercentage:number;
      finalTenderAmount:number;
      netAmount:number;
      taxableTenderAmount:number;
}