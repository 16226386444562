import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

export class TenderBidderDocumentPojo{
    
      tBDocId:number;
      tBDocDate:Date;
      tBDocTime:String;
      bidderId:number;
      estimateId:number;
      docDescription:String;
     tBDocNo:string;
      estimateTypeId:number;
      uniqueId:number;
      userId:number;
imageList:FtpImagePojo[];
}