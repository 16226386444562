import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import * as moment from 'moment';
import { ReceiptService } from "src/app/EngSection/WorkProgress/receipt/receipt.service";
import { ReceiptPojo } from "src/app/Pojo/receipt.pojo";
import { ReceiptTypeMasterService } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.service";
import { ReceiptTypePojo } from "src/app/Pojo/receipt-type-pojo";
declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
  })
export class ReceiptComponent implements OnInit {
    tempdate: any;
    mrdate: any;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    receiptArr : ReceiptPojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    addReceipt = new ReceiptPojo;
    editReceipt = new ReceiptPojo();
    selectedFiles : File[] =[];  
    estimateArr: EstimatePojo[]=[];
    receiptTypeArr : ReceiptTypePojo[] = [];
  constructor(private receiptTypeMasterService: ReceiptTypeMasterService,private receiptService: ReceiptService,private tenderBidderMasterService: TenderBidderMasterService,private estimateService:EstimateService) { }

  ngOnInit() {
      
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
     // this.getReceiptAll();
      this.getTenderBidderMasterAll();
      this.getEstimateAll();
      this.getReceiptTypeMasterAll();
  }
  
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getReceiptTypeMasterAll() {
      this.receiptTypeMasterService.getReceiptTypeAll().subscribe( response => {
          this.receiptTypeArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Receipt Type All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getEstimateAll() {
      this.isLoading = true;
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Estimate All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  
  getReceiptAll() {
      this.receiptService.getReceiptAll().subscribe( response => {
          this.receiptArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Quotation Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
 
  addreceipt() {
      
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      if( this.tempdate != null || this.tempdate != undefined){
          this.addReceipt.receiptDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
      }
      if( this.mrdate != null || this.mrdate != undefined){
          this.addReceipt.mrDate = moment( this.mrdate.year + '-' + this.mrdate.month + '-' + this.mrdate.day, 'YYYY-MM-DD' ).toDate();
      }
      this.addReceipt.userId = this.loggedUser.userId;
      this.receiptService.addReceipt( this.addReceipt ).subscribe( response => {
            bootbox.alert( 'Successfully Added');
           ( <HTMLFormElement>document.getElementById( 'addQuotationForm' ) ).reset();
           this.reset();
           $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
         
      }, error => {
          bootbox.alert( 'Add Quotation Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
 
  reset(){
      this.addReceipt= new ReceiptPojo();
      this.tempdate = null;
      this.mrdate = null;
      }
}
