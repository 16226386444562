import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { BidderReportPojo } from "src/app/Pojo/bidder-report.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class BidderReportService {

    constructor( private httpclient: HttpClient ) { }
    
   
    getBidderReportByBidderId(id:number): Observable<BidderReportPojo> {
        const url = StaticVariable.porturl + 'TenderBidderHistoryByBidderId/'+id;
        return this.httpclient.get<BidderReportPojo>( url );
    }
   
}
