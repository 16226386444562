import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { LoggedUserDetailsPojo } from "src/app/pojo/logged-user-details-pojo";

export class MUserMasterPojo {
    userId: number;
    roleId: number;
    name: string;
    userName: string;
    password: string;
    designation: string;
    mobileno: string;
    companyId: number;
    address: string;
    userStatus: string;
    loggedUserId: number;
   roleKey = new MRolePojo();
}

export class ManagementRolePojo {
    admin = false;
    rolemaster = false;
    usercreation = false;
    backup = false;
    master = false;
    receipttype = false;
    tenderbidder = false;
    workmaster = false;
    estimation = false;
    quotation = false;
    tender = false;
    workprogress = false;
    entrylist = false;
    quotationlist = false;
    tenderlist = false;
    estimationlist = false;
    receipt = false;
    receiptlist = false;
    action = false;
    quotationaction = false;
    tenderaction = false;
    tenderactionentry = false;
    tenderbidderdoc = false;
    tenderactionlist = false;
    tenderbidderdoclist = false;
    work = false;
    workorder = false;
    agreement = false;
    workProgress = false;
    paymentprogress = false;
    workcompletion = false;
    report = false;
    progressreport = false;
    paymentreport = false;
    settings = false;
    bidderreport = false;
    quotationreport = false;
    tenderreport = false;
    sectionmaster = false;
}