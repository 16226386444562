import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

@Injectable( {
    providedIn: 'root'
} )

export class WorkOrderService {

    constructor( private httpclient: HttpClient ) { }
    
    getWorkOrderAll(): Observable<WorkOrderPojo[]> {
        const url = StaticVariable.porturl + 'WorkOrderAll/';
        return this.httpclient.get<WorkOrderPojo[]>( url );
    }
     addWorkOrder( arg: WorkOrderPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddWorkOrder';
        return this.httpclient.post( url, arg );
    }

    updateWorkOrder( arg: WorkOrderPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateWorkOrder';
        return this.httpclient.put( url, arg );
    }

    deleteWorkOrder( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteWorkOrder/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
     
    getWorkOrderById(id): Observable<WorkOrderPojo> {
        const url = StaticVariable.porturl + 'WorkOrderById/'+id;
        return this.httpclient.get<WorkOrderPojo>( url );
    }
    autoGenerateEstNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateOrderNo/';
        return this.httpclient.get<any>(url);
    }
   uploadImage(file: File,id:number,no:string,desc:string): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+no+'/'+desc;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }
   deleteFtpImage( id: number): Observable<any> {
       const url = StaticVariable.porturl + 'FtpImageDelete/' + id;
       return this.httpclient.delete<any>( url );
   }
}
