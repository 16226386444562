import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagementAuthenticationGuard } from "src/app/EngSection/Authentication/management-authentication-guard";
import { LoginComponent } from "src/app/EngSection/login/login.component";
import { MenubarComponent } from "src/app/EngSection/menubar/menubar.component";
import { RoleMasterComponent } from "src/app/EngSection/Admin/role-master/role-master.component";
import { ChangePasswordComponent } from "src/app/EngSection/Admin/change-password/change-password.component";
import { BackupComponent } from "src/app/EngSection/Admin/backup/backup.component";
import { UserCreationComponent } from "src/app/EngSection/Admin/user-creation/user-creation.component";
import { ReceiptTypeMasterComponent } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.component";
import { TenderBidderMasterComponent } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.component";
import { WorkMasterComponent } from "src/app/EngSection/Master/work-master/work-master.component";
import { EstimateComponent } from "src/app/EngSection/WorkProgress/estimate/estimate.component";
import { QuotationComponent } from "src/app/EngSection/WorkProgress/quotation/quotation.component";
import { TenderComponent } from "src/app/EngSection/WorkProgress/tender/tender.component";
import { EstimationListComponent } from "src/app/EngSection/Entry-List/estimation-list/estimation-list.component";
import { QuotationListComponent } from "src/app/EngSection/Entry-List/quotation-list/quotation-list.component";
import { TenderListComponent } from "src/app/EngSection/Entry-List/tender-list/tender-list.component";
import { ReceiptComponent } from "src/app/EngSection/WorkProgress/receipt/receipt.component";
import { ReceiptListComponent } from "src/app/EngSection/Entry-List/receipt-list/receipt-list.component";
import { QuotationActionComponent } from "src/app/EngSection/Action/quotation-action/quotation-action.component";
import { TenderActionComponent } from "src/app/EngSection/Action/tender-action/tender-action.component";
import { TenderActionEntryComponent } from "src/app/EngSection/WorkProgress/tender-action/tender-action.component";
import { TenderBidderDocComponent } from "src/app/EngSection/WorkProgress/tender-bidder-doc/tender-bidder-doc.component";
import { TenderActionListComponent } from "src/app/EngSection/Entry-List/tender-action-list/tender-action-list.component";
import { TenderBidderDocListComponent } from "src/app/EngSection/Entry-List/tender-bidder-doc-list/tender-bidder-doc-list.component";
import { WorkOrderComponent } from "src/app/EngSection/Work/work-order/work-order.component";
import { AgreementComponent } from "src/app/EngSection/Work/agreement/agreement.component";
import { WorkProgressComponent } from "src/app/EngSection/Work/work-progress/work-progress.component";
import { PaymentProgressComponent } from "src/app/EngSection/Work/payment-progress/payment-progress.component";
import { WorkCompletionComponent } from "src/app/EngSection/Work/work-completion/work-completion.component";
import { ProgressReportComponent } from "src/app/EngSection/Report/progress-report/progress-report.component";
import { PaymentReportComponent } from "src/app/EngSection/Report/payment-report/payment-report.component";
import { SettingsComponent } from "src/app/EngSection/Admin/settings/settings.component";
import { BidderReportComponent } from "src/app/EngSection/Report/bidder-report/bidder-report.component";
import { QuotatinReportComponent } from "src/app/EngSection/Report/quotatin-report/quotatin-report.component";
import { TenderReportComponent } from "src/app/EngSection/Report/tender-report/tender-report.component";
import { SectionMasterComponent } from './EngSection/Master/section-master/section-master.component';
import { UserComponent } from './EngSection/Work-master-report/user.component';
import { WorkTypeMasterComponent } from './EngSection/Master/work-type-master/work-type-master.component';


const routes: Routes = [
                        {
                            path: '', component: LoginComponent
                        },
                        {
                            path: 'login', component: LoginComponent
                        },
                        {
                            path: 'console', component: MenubarComponent,
                            children: [
                                { path: 'user', component:UserComponent},
                                { path: 'workType', component:WorkTypeMasterComponent},
                                { path: 'rolemaster', component: RoleMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'rolemaster' } },
                                { path: 'changePassword', component: ChangePasswordComponent },
                                { path: 'usercreation', component: UserCreationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'usercreation' } },
                                { path: 'backup', component: BackupComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'backup' } },
                                { path: 'receipttype', component: ReceiptTypeMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'receipttype' } },
                                { path: 'tenderbidder', component: TenderBidderMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderbidder' } },
                                { path: 'workmaster', component: WorkMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'workmaster' } },
                                { path: 'estimation', component: EstimateComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'estimation' } },
                                { path: 'quotation', component: QuotationComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'quotation' } },
                                { path: 'tender', component: TenderComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tender' } },
                                { path: 'estimationlist', component: EstimationListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'estimationlist' } },
                                { path: 'quotationlist', component: QuotationListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'quotationlist' } },
                                { path: 'tenderlist', component: TenderListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderlist' } },
                                { path: 'receipt', component: ReceiptComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'receipt' } },
                                { path: 'receiptlist', component: ReceiptListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'receiptlist' } },
                                { path: 'quotationaction', component: QuotationActionComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'quotationaction' } },
                                { path: 'tenderaction', component: TenderActionComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderaction' } },
                                { path: 'tenderactionentry', component: TenderActionEntryComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderactionentry' } },
                                { path: 'tenderbidderdoc', component: TenderBidderDocComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderbidderdoc' } },
                                { path: 'tenderactionlist', component: TenderActionListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderactionlist' } },
                                { path: 'tenderbidderdoclist', component: TenderBidderDocListComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderbidderdoclist' } },
                                { path: 'workorder', component: WorkOrderComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'workorder' } },
                                { path: 'agreement', component: AgreementComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'agreement' } },
                                { path: 'workProgress', component: WorkProgressComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'workProgress' } },
                                { path: 'paymentprogress', component: PaymentProgressComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'paymentprogress' } },
                                { path: 'workcompletion', component: WorkCompletionComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'workcompletion' } },
                                { path: 'progressreport', component: ProgressReportComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'progressreport' } },
                                { path: 'paymentreport', component: PaymentReportComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'paymentreport' } },
                                { path: 'settings', component: SettingsComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'settings' } },
                                { path: 'bidderreport', component: BidderReportComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'bidderreport' } },
                                { path: 'quotationreport', component: QuotatinReportComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'quotationreport' } },
                                { path: 'tenderreport', component: TenderReportComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'tenderreport' } },
                                { path: 'sectionmaster', component: SectionMasterComponent, canActivate: [ManagementAuthenticationGuard], data: { roles: 'sectionmaster' } },
                                                   
                                  ]    
                        } 
                        
                    ];

@NgModule( {
    imports: [
        RouterModule.forRoot( routes, { useHash: true } ),
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ManagementAuthenticationGuard
    ]
} )

export class AppRoutingModule { }
