import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
declare var $: any;
declare var bootbox: any;
import * as moment from 'moment';
import { PaymentProgressPojo } from "src/app/Pojo/payment-progress.pojo";
import { PaymentProgressService } from "src/app/EngSection/Work/payment-progress/payment-progress.service";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { WorkOrderService } from "src/app/EngSection/Work/work-order/work-order.service";

@Component({
  selector: 'app-payment-progress',
  templateUrl: './payment-progress.component.html',
  styleUrls: ['./payment-progress.component.scss']
})
export class PaymentProgressComponent implements OnInit {

    model: NgbDateStruct;
date: { year: number, month: number };
autoClose:true;
addVoucherDate: NgbDateStruct;
editVoucherDate: NgbDateStruct;
   
public data;
public sortBy = 'i';
public rowsOnPage = 10;
public filterQuery = '';
public sortOrder = 'asc';

id: number;
isLoading: boolean = true;

loggedUser = new MUserMasterPojo();
paymentArr : PaymentProgressPojo[] = [];
addPayment = new PaymentProgressPojo();
editPayment = new PaymentProgressPojo();
orderArr : WorkOrderPojo[] = [];

  constructor(private PaymentProgressService: PaymentProgressService,private workOrderService:WorkOrderService,
          private calendar: NgbCalendar) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
       this.getWorkOrderAll();
      this.getPaymentProgressAll();
  }
  
  getPaymentProgressAll() {
      this.PaymentProgressService.getPaymentProgressAll().subscribe( response => {
          this.paymentArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Payment Progress All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
 
  getWorkOrderAll() {
      this.workOrderService.getWorkOrderAll().subscribe( response => {
          this.orderArr = response;
      }, error => {
          bootbox.alert( 'Get WorkType All Error! - HTTP Status ' + error.status );
      } );
  }
 
  getOrderNumById( id ) {
      if ( this.orderArr.find( r => r.orderId == id ) ) {
          return this.orderArr.find( r => r.orderId == id ).orderNo;
      } else {
          return id;
      }
  }
  getOrderBalanceAmtById( id ) {
      if ( this.orderArr.find( r => r.orderId == id ) ) {
          return this.orderArr.find( r => r.orderId == id ).balanceAmount;
      } else {
          return id;
      }
  }
  clickAdd(){
      this.getWorkOrderAll();
  }
  addPaymentMaster() {
      if(this.addPayment.amount != null && this.addPayment.amount >0 && this.addPayment.amount != 0){
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      if(this.addVoucherDate != null && this.addVoucherDate != undefined){
          this.addPayment.voucherDate = moment( this.addVoucherDate.year + '-' + this.addVoucherDate.month + '-' + this.addVoucherDate.day, 'YYYY-MM-DD' ).toDate();
          }
      this.addPayment.userId = this.loggedUser.userId;
      this.PaymentProgressService.addPaymentProgress( this.addPayment ).subscribe( response => {
          let d = new PaymentProgressPojo();
          d = response;
          if(d.code == 101){
              bootbox.alert(response.response);
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLFormElement>document.getElementById( 'addPaymentForm' ) ).reset();
              $( '#addModal' ).modal( 'hide' );
               this.getPaymentProgressAll();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          
          }
          if(d.code == 102){
              bootbox.alert(response.response);
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
             
          }
           
          
      }, error => {
          bootbox.alert( 'Add Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
  else{
      bootbox.alert('Please Enter the Valid Amount');
  }
  }
  
  clickEdit( arg ) {
      this.getWorkOrderAll();
      this.editPayment = Object.assign( {}, arg );
      if(this.editPayment.voucherDate != null && this.editPayment.voucherDate != undefined){
          this.editVoucherDate = { day: moment( this.editPayment.voucherDate ).date(), month: moment( this.editPayment.voucherDate ).month()+1, year: moment( this.editPayment.voucherDate ).year() };
           }
       }

  editPaymentMaster() {
      if(this.editPayment.amount != null && this.editPayment.amount >0 && this.editPayment.amount != 0){
          
      if(this.editVoucherDate != null && this.editVoucherDate != undefined){
          this.editPayment.voucherDate = moment( this.editVoucherDate.year + '-' + this.editVoucherDate.month + '-' + this.editVoucherDate.day, 'YYYY-MM-DD' ).toDate();
          }
      this.editPayment.userId = this.loggedUser.userId;
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.PaymentProgressService.updatePaymentProgress( this.editPayment ).subscribe( response => {
          let d= new PaymentProgressPojo();
          d= response;
          if(d.code == 101){
              ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
              $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
         
              $( '#editModal' ).modal( 'hide' );
              bootbox.alert(response.response);
              this.getPaymentProgressAll();
             
          } if(d.code == 102){
              bootbox.alert(response.response);
               ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
              $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
         
          }
             }, error => {
          bootbox.alert( 'Update Work Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
      }
      else{
          bootbox.alert('Please Enter the Valid Amount');
      }
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.paymentArr.indexOf( this.paymentArr.find( response => response.paymentId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.PaymentProgressService.deletePaymentProgress( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }else if ( no == 101 ) {
              bootbox.alert( 'You Cannot Delete!This Payment Progress Linked to SomeOther...' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              
          } else {
              this.paymentArr.splice( i, 1 );
              bootbox.alert( 'Successfully deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              this.getPaymentProgressAll();
          }
          
      }, error => {
          bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }

}
