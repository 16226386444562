import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import * as moment from 'moment';
import { ReceiptPojo } from "src/app/Pojo/receipt.pojo";
import { ReceiptService } from "src/app/EngSection/WorkProgress/receipt/receipt.service";
import { ReceiptTypeMasterService } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.service";
import { ReceiptTypePojo } from "src/app/Pojo/receipt-type-pojo";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-receipt-list',
    templateUrl: './receipt-list.component.html',
    styleUrls: ['./receipt-list.component.scss']
  })
export class ReceiptListComponent implements OnInit {
    tempdate:any;
    mrdate: any;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    receiptArr : ReceiptPojo[] = [];
    addReceipt = new ReceiptPojo();
    estimateArr:EstimatePojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    receiptTypeArr : ReceiptTypePojo[] = [];
  constructor(private receiptTypeMasterService: ReceiptTypeMasterService,
          private receiptService: ReceiptService,private estimateService:EstimateService,
          private tenderBidderMasterService: TenderBidderMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getReceiptAll();
      this.getEstimateAll();
      this.getTenderMasterMasterAll();
      this.getReceiptTypeMasterAll();
  }
  getReceiptAll() {
      this.receiptService.getReceiptAll().subscribe( response => {
          this.receiptArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Receipte  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getReceiptTypeMasterAll() {
      this.receiptTypeMasterService.getReceiptTypeAll().subscribe( response => {
          this.receiptTypeArr = response;
      }, error => {
          bootbox.alert( 'Get Receipt Type All Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderMasterMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimatteNumberById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
      getBidderNameById( id ) {
          if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
              return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
          } else {
              return id;
          }
      }
      getReceiptById( id ) {
          if ( this.receiptTypeArr.find( r => r.receiptTypeId == id ) ) {
              return this.receiptTypeArr.find( r => r.receiptTypeId == id ).receiptType;
          } else {
              return id;
          }
      }
      clickEdit(item){
          this.addReceipt = item;
          if(this.addReceipt.receiptDate != null && this.addReceipt.receiptDate != undefined){
              this.tempdate = { day: moment( this.addReceipt.receiptDate ).date(), month: moment( this.addReceipt.receiptDate ).month()+1, year: moment( this.addReceipt.receiptDate ).year() };
               }
          if(this.addReceipt.mrDate != null && this.addReceipt.mrDate != undefined){
              this.mrdate = { day: moment( this.addReceipt.mrDate ).date(), month: moment( this.addReceipt.mrDate ).month()+1, year: moment( this.addReceipt.receiptDate ).year() };
               }
      }
    update() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        if( this.tempdate != null || this.tempdate != undefined){
            this.addReceipt.receiptDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
        }
        if( this.mrdate != null || this.mrdate != undefined){
            this.addReceipt.mrDate = moment( this.mrdate.year + '-' + this.mrdate.month + '-' + this.mrdate.day, 'YYYY-MM-DD' ).toDate();
        }
        this.addReceipt.userId = this.loggedUser.userId;
         this.receiptService.updateReceipt( this.addReceipt ).subscribe( response => {
              bootbox.alert('Successfully Updated');
              $( '#editModal' ).modal( 'hide' );
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            
          }, error => {
              bootbox.alert( 'Update Quotation Service Error! - HTTP Status ' + error.status );
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
               } );
      }

      delete( arg ) {
          this.id = arg;
      }

      deleteConfirm() {
          let no;
          const i = this.receiptArr.indexOf( this.receiptArr.find( response => response.receiptId  == this.id ) );
          $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
           this.receiptService.deleteReceipt( this.id,this.loggedUser.userId ).subscribe( response => {
              no = response;
              if ( no == 0 ) {
                  bootbox.alert( 'This row is already deleted' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                    }else if ( no == 101 ) {
                        bootbox.alert( 'You Cannot Delete!This Receipt LLinked to SomeOther...' );
                        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                          } else {
                  this.receiptArr.splice( i, 1 );
                  bootbox.alert( 'Successfully deleted' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                  $( '#deleteModal' ).modal( 'hide' );
                  this.getReceiptAll();
                    }
                
          }, error => {
              bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                 } );
      }
      
      reset(){
          this.addReceipt= new ReceiptPojo();
          this.tempdate = null;
          }
}

