export class WorkMasterPojo {

    workId:number;
    fileNo:string;
    workName:string;
    workTypeId:number;
    schemeTypeId:number;
    projectDate:Date;
    projectTime:string;
    status:string;
    duration:number;
    userId:number;
    code:number;
    response:string;
    sectionId:number;

      workDetails: string;
	  progressPercentage: number;
	  completionDateTime: Date;
	  amount: number;
	  bidderId: number;
      entryDate:Date;
      extendDate1:Date;
      extendDate2:Date;
      agreementAmount:number;
      paidAmount:number;
}