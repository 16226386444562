import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-tender-bidder-master',
    templateUrl: './tender-bidder-master.component.html',
    styleUrls: ['./tender-bidder-master.component.scss']
  })
export class TenderBidderMasterComponent implements OnInit {

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    addTenderBidder = new TenderBidderMasterPojo();
    editTenderBidder = new TenderBidderMasterPojo();
    
  constructor(private tenderBidderMasterService: TenderBidderMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getTenderMasterMasterAll();
  }
  getTenderMasterMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  addphoneNumValidation( num ) {
      const contactNum = /^[6-9]\d{9}$/;
      if ( num.match( contactNum ) || num == '' ) {
      } else {
          ( <HTMLInputElement>document.getElementById( 'addmobileno' ) ).value = '';
          alert( 'Please check the mobile number. This number is not valid.' );
         
      }
  }  
  editphoneNumValidate( num ) {
      const contactNum = /^[6-9]\d{9}$/;
      if ( num.match( contactNum ) || num == '' ) {
      } else {
          ( <HTMLInputElement>document.getElementById( 'editmobileno' ) ).value = '';
          alert( 'Not a valid Number' );
      }
  }
  validateEmail( emailField ) {
      const mailid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( emailField.match( mailid ) || emailField == '' ) {
      } else {
          ( <HTMLInputElement>document.getElementById( 'addEmailId' ) ).value = '';
          alert( 'Not a valid Mail Id' );
          $( '#addEmailId' ).focus();
      }
  }
  editvalidateEmail( emailField ) {
      const mailid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( emailField.match( mailid ) || emailField == '' ) {
      } else {
          ( <HTMLInputElement>document.getElementById( 'editEmailId' ) ).value = '';
          alert( 'Not a valid Mail Id' );
          $( '#editEmailId' ).focus();
      }
  }
  clickAdd(){
      this.tenderBidderMasterService.autoGenerateRegNum().subscribe( response => {
          this.addTenderBidder = response;
             }, error => {
          bootbox.alert( 'Get Register Number Error! - HTTP Status ' + error.status );
      } );
  }
  addTenderbidder() {
      
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      this.addTenderBidder.userId = this.loggedUser.userId;
      this.tenderBidderMasterService.addTenderBidder( this.addTenderBidder ).subscribe( response => {
          if(response.code === 101)
          {
          bootbox.alert( response.response);
           ( <HTMLFormElement>document.getElementById( 'addTenderBidderForm' ) ).reset();
           $( '#addModal' ).modal( 'hide' );
          this.getTenderMasterMasterAll();
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }
          else if(response.code === 102)
          {
          bootbox.alert( response.response);
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }
          
      }, error => {
          bootbox.alert( 'Add TenderBidder Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
  
  clickEdit( arg ) {
      this.editTenderBidder = Object.assign( {}, arg );
       }

  editTenderbidder() {
      this.editTenderBidder.userId = this.loggedUser.userId;
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.tenderBidderMasterService.updateTenderBidder( this.editTenderBidder ).subscribe( response => {
          if(response.code === 101)
          {
          bootbox.alert( response.response);
          $( '#editModal' ).modal( 'hide' );
          this.getTenderMasterMasterAll();
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          }
          else if(response.code === 102)
          {
          bootbox.alert( response.response);
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          }
      }, error => {
          bootbox.alert( 'Update TenderBidder Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.tenderbidderArr.indexOf( this.tenderbidderArr.find( response => response.bidderId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.tenderBidderMasterService.deleteTenderBidder( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else if ( no == 101 ) {
              bootbox.alert( 'You cannot Delete!This TenderBidder Linked to SomeOther...' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.tenderbidderArr.splice( i, 1 );
              bootbox.alert( 'Successfully deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              this.getTenderMasterMasterAll();
        
          }
           }, error => {
          bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
}
