import { Component, OnInit } from '@angular/core';
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { WorkMasterService } from "src/app/EngSection/Master/work-master/work-master.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { SanctionlevelService } from "src/Services/sanction-level.service";
import { EstimateTypeMasterPojo } from "src/app/Pojo/estimate-type-pojo";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-estimation-list',
  templateUrl: './estimation-list.component.html',
  styleUrls: ['./estimation-list.component.scss']
})
export class EstimationListComponent implements OnInit {
    model: NgbDateStruct;
   date: { year: number, month: number };
   tempdate: NgbDateStruct;
   approveddate:NgbDateStruct;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    addEst = new EstimatePojo();
    estimateArr:EstimatePojo[] = [];
    loggedUser = new MUserMasterPojo();
    workArr : WorkMasterPojo[] = [];
    estimateTypeArr :EstimateTypeMasterPojo[] = [];
    sanctionLevelArr: SanctionLevelMasterPojo[] = [];
    
    docCount = 0;
    imagePojo = new FtpImagePojo();
    selectedFiles: File[] = [];
    newFile : boolean = false;
    fileincreament = 0;
    tempFtpArr:FtpImagePojo[]=[];;
    deleteFileIncreament = 0;
    fileNameArr=[];
    addedFileCount:number =0;
    constructor(private calendar: NgbCalendar,private estimateService:EstimateService,
          private estimateTypeservice:EstimateTypeMasterPojoService,
          private sanctionLevelService:SanctionlevelService,
          private workMasterService: WorkMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getEstimateAll();
      this.getWorkMasterAll();
      this.getEstimateTypeAll();
      this.getSanctionLevelAll();
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getWorkMasterAll() {
      this.workMasterService.getWorkMasterAll().subscribe( response => {
          this.workArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Work Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getWorkNameById( id ) {
      if ( this.workArr.find( r => r.workId == id ) ) {
          return this.workArr.find( r => r.workId == id ).workName;
      } else {
          return id;
      }
  }
  getFileNoById( id ) {
    if ( this.workArr.find( r => r.workId == id ) ) {
        return this.workArr.find( r => r.workId == id ).fileNo;
    } else {
        return id;
    }
}
  getEstimateTypeAll() {
      this.isLoading = true;
      this.estimateTypeservice.getEstimateTypeAll().subscribe( response => {
          this.estimateTypeArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getSanctionLevelAll() {
      this.isLoading = true;
      this.sanctionLevelService.getSanctionLevelAll().subscribe( response => {
          this.sanctionLevelArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'SanctionLevel All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  modalShow(){
      $( '#myModal1' ).modal( 'show' );
  }
  removeFile(images,i) {
        this.selectedFiles.splice(i,1);
        this.addedFileCount = this.selectedFiles.length;
  }
  removeFileForEst(item,i){
      this.tempFtpArr.push(item);
      this.addEst.imageList.splice(i,1);
      this.fileNameArr.splice(i,1);
      this.docCount = this.addEst.imageList.length;
  }
  clickEdit(item){
      this.tempdate = null;
      this.approveddate = null;
      this.addEst = item;
      if(this.addEst.estimateDate != null && this.addEst.estimateDate != undefined){
          this.tempdate = { day: moment( this.addEst.estimateDate ).date(), month: moment( this.addEst.estimateDate ).month()+1, year: moment( this.addEst.estimateDate ).year() };
           }
      if(this.addEst.approvedDate != null && this.addEst.approvedDate != undefined){
          this.approveddate = { day: moment( this.addEst.approvedDate ).date(), month: moment( this.addEst.approvedDate ).month()+1, year: moment( this.addEst.approvedDate ).year() };
           }
      this.docCount = this.addEst.imageList.length;
     
  }
  setFiles(images) {
      ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value ="";
      $( '#reasonModal' ).modal( 'show' );
       for(let i=0;i<images.files.length;i++) { 
       this.selectedFiles.push(images.files[i]);
       this.addedFileCount = this.selectedFiles.length;
   } 
       this.newFile = true;
      // this.status = "Documents Still Not Uploading";
   } 
       saveFileName(){
       if(( <HTMLInputElement>document.getElementById( 'fileName' ) ).value!='')
       {
           let fileName = ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value; 
       this.fileNameArr.push(fileName);
       $( '#reasonModal' ).modal( 'hide' );
       }
  else{
      bootbox.alert('Please Enter the FileName');
  }
}  uploadFiles()
       {
      if(this.selectedFiles.length !== this.fileincreament)
       {
      this.id = 1;
      this.estimateService.uploadImage( this.selectedFiles[this.fileincreament] , this.id, this.addEst.estimateNo,this.fileNameArr[this.fileincreament] ).subscribe(( res ) => {
          this.imagePojo = res;
          this.fileincreament++;
          this.uploadFiles();
          if ( this.imagePojo.imageId != 0 ) {
              this.addEst.fileName = this.imagePojo.imageName;
              this.addEst.fileAddressPath = this.imagePojo.imagePath;
              this.addEst.imageId = this.imagePojo.imageId;
          } else {
              alert( "Failed Image Uploading....." );
              $('#addBeforeClick').show();$('#addAfterClick').hide();
          }
      }, er => { alert( "Failed image uploading..." + er ); 
      $('#addBeforeClick').show();$('#addAfterClick').hide();
      } );
  }else{
      this.estimateService.updateEstimate(this.addEst).subscribe( response => {
          $( '#editModal' ).modal( 'hide' );
          bootbox.alert('Successfully Updated');
          $('#addBeforeClick').show();$('#addAfterClick').hide();
           this.getEstimateAll();
           this.newFile = false;
           this.reset();
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
          $('#addBeforeClick').show();$('#addAfterClick').hide();
          this.isLoading = false;
      } );
  }
     }
    update(){
       
      $('#addBeforeClick').hide();$('#addAfterClick').show();
      if( this.tempdate != null || this.tempdate != undefined){
          this.addEst.estimateDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
      }
      if( this.approveddate != null || this.approveddate != undefined){
          this.addEst.approvedDate = moment( this.approveddate.year + '-' + this.approveddate.month + '-' + this.approveddate.day, 'YYYY-MM-DD' ).toDate();
          
      }
      if(this.addEst.approvedBy != null && this.addEst.approvedBy != undefined && this.addEst.approvedBy != '')
          {
          this.addEst.status = "APPROVED";
          }
      else{
          this.addEst.status = "REJECTED";
      }
        if(this.selectedFiles.length != 0 && this.tempFtpArr.length != 0){
            for(let j=0;j<this.tempFtpArr.length;j++)
            {
                this.estimateService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe( response => {
                this.deleteFileIncreament++;
                if(this.tempFtpArr.length==this.deleteFileIncreament)
                    {
                    this.uploadFiles();
                    }
                }, error => {
                    bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                     } );
            }
      } else if(this.selectedFiles.length != 0){
          this.uploadFiles();
      } else if(this.tempFtpArr.length != 0){
          for(let j=0;j<this.tempFtpArr.length;j++)
          {
              this.estimateService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe( response => {
              this.deleteFileIncreament++;
              if(this.tempFtpArr.length==this.deleteFileIncreament)
                  {
                  this.estimateService.updateEstimate(this.addEst).subscribe( response => {
                      $( '#editModal' ).modal( 'hide' );
                      bootbox.alert('Successfully Updated');
                      $('#addBeforeClick').show();$('#addAfterClick').hide();
                       this.getEstimateAll();
                       this.newFile = false;
                       this.reset();
                      this.isLoading = false;
                  }, error => {
                      bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
                      $('#addBeforeClick').show();$('#addAfterClick').hide();
                      this.isLoading = false;
                  } );
                  }
              }, error => {
                  bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                   } );
          }
      } else{
          this.estimateService.updateEstimate(this.addEst).subscribe( response => {
              $( '#editModal' ).modal( 'hide' );
              bootbox.alert('Successfully Updated');
              $('#addBeforeClick').show();$('#addAfterClick').hide();
               this.getEstimateAll();
               this.newFile = false;
               this.reset();
              this.isLoading = false;
          }, error => {
              bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
              $('#addBeforeClick').show();$('#addAfterClick').hide();
              this.isLoading = false;
          } );
      }
     
      }
 
  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.estimateArr.indexOf( this.estimateArr.find( response => response.estimateId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this. estimateService.deleteEstimate( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }else if ( no == 101 ) {
              bootbox.alert( 'You cannot Delete!This Estimation Linked to SomeOther...' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.estimateArr.splice( i, 1 );
              bootbox.alert( 'Successfully deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              this.getEstimateAll();
       
          }
            }, error => {
          bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
  reset(){
      this.selectedFiles = [];
      this.tempFtpArr =[];
      this.deleteFileIncreament =0;
      this.fileincreament = 0;
      this.addedFileCount =0;
      this.tempdate = null;
      this.approveddate = null;
  }
  
}
