import { Injectable } from '@angular/core';
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SettingPojo } from "src/app/Pojo/settings.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class SettingService {

    constructor( private httpclient: HttpClient ) { }
    
    getSettingsById(id): Observable<SettingPojo> {
        const url = StaticVariable.porturl + 'SettingById/'+id;
        return this.httpclient.get<SettingPojo>( url );
    }
   updateMRole( arg: SettingPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'SettingUpdate';
        return this.httpclient.put( url, arg );
    }

}
