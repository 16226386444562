import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { ReceiptTypePojo } from "src/app/Pojo/receipt-type-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class ReceiptTypeMasterService {

    constructor( private httpclient: HttpClient ) { }
    
    getReceiptTypeAll(): Observable<ReceiptTypePojo[]> {
        const url = StaticVariable.porturl + 'ReceiptTypeAll/';
        return this.httpclient.get<ReceiptTypePojo[]>( url );
    }
     addReceiptType( arg: ReceiptTypePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddReceiptType';
        return this.httpclient.post( url, arg );
    }

    updateReceiptType( arg: ReceiptTypePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateReceiptType';
        return this.httpclient.put( url, arg );
    }

    deleteReceiptType( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteReceiptType/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getReceiptTypeMasterById(id): Observable<ReceiptTypePojo> {
        const url = StaticVariable.porturl + 'ReceiptTypeById/'+id;
        return this.httpclient.get<ReceiptTypePojo>( url );
    }
}
