import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'UserCreationPipe'
})
export class UserCreationPipe implements PipeTransform {
    transform( array: any[], query: string, query1: string ): any {
        if ( query ) {
            if ( _.filter( array, row => row.roleId == query ) != '' ) {
                return _.filter( array, row => row.roleId == query );
            } else if ( _.filter( array, row => row.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } 
            else if ( _.filter( array, row => row.designation.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.designation.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }
   
}
