import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { UserCreationService } from "src/app/EngSection/Admin/user-creation/user-creation.service";
import { RoleMasterService } from "src/app/EngSection/Admin/role-master/role-master.service";
import { LoginService } from "src/app/EngSection/login/login.service";

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [UserCreationService,RoleMasterService,LoginService]
} )

export class ChangePasswordComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    userDetail = new MUserMasterPojo();
    roles: MRolePojo[] = [];
    flag: string;

    isLoading: boolean = true;
    checkPWLoading: boolean = true;
    confirmPassword: boolean = true;
    updatePasswordLoading: boolean = true;

    constructor( private userCreationService: UserCreationService, private roleMasterService: RoleMasterService, private loginService: LoginService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

        this.getUserDetail();
        this.getRoleAll();
    }

    getUserDetail() {
        this.isLoading = true;
        this.userCreationService.getMUserMasterById( this.loggedUser.userId ).subscribe( response => {
            this.userDetail = response;
            this.isLoading = false;
            this.checkPWLoading = false;
            this.confirmPassword = true;
        }, error => {
            bootbox.alert( { message: 'M User Master By Id Service Error! HTTP Status ' + error.status } );
            this.isLoading = false;
            this.checkPWLoading = false;
            this.confirmPassword = true;
        } );
    }

    getRoleAll() {
        this.isLoading = true;
        this.roleMasterService.getMRoleAll().subscribe( response => {
            this.roles = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get M Role All Service Error! HTTP Status ' + error.status } );
            this.isLoading = false;
        } );
    }

    getRoleNameByRoleId( roleId ) {
        if ( this.roles.find( response => response.roleId == roleId ) ) {
            return this.roles.find( response => response.roleId == roleId ).userRole;
        } else {
            return roleId;
        }
    }

    viewPassword() {
        var x = ( <HTMLInputElement>document.getElementById( "currentPassword" ) );
        if ( x.type === "password" ) {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    checkPassword( userId, password ) {
        this.checkPWLoading = true;
        this.confirmPassword = true;
        this.updatePasswordLoading = false;
        this.loginService.checkUserpassword( userId, password ).subscribe( response => {
            this.flag = JSON.stringify( response );
            if ( this.flag == 'false' ) {
                bootbox.alert( { message: 'Please enter the correct password!', className: 'text-danger h5', closeButton: false, } );
                this.checkPWLoading = false;
                this.confirmPassword = true;
            } else if ( this.flag == 'true' ) {
                this.checkPWLoading = false;
                this.confirmPassword = false;
            }
        }, error => {
            bootbox.alert( { message: 'Check M User Password Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.checkPWLoading = false;
            this.confirmPassword = true;
        } );
    }

    updateUserPassword() {
        this.updatePasswordLoading = true;
        let newPassword = ( <HTMLInputElement>document.getElementById( 'newPassword' ) ).value;
        let confirmPassword = ( <HTMLInputElement>document.getElementById( 'confirmPassword' ) ).value;
        if ( newPassword == confirmPassword ) {
            this.loginService.updateUserpassword( this.userDetail.userId, newPassword ).subscribe( response => {
                bootbox.alert( { message: 'New password has been successfully updated.', className: 'text-success h5', closeButton: false } );
                this.updatePasswordLoading = false;
                this.confirmPassword = true;
                $( '#editModal' ).modal( 'hide' );
            }, error => {
                bootbox.alert( { message: 'Update M User Password Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                this.updatePasswordLoading = false;
                this.confirmPassword = true;
            } );
        } else {
            bootbox.alert( { message: 'Confirm is not matching', className: 'text-danger h5', closeButton: false } );
            this.updatePasswordLoading = false;
        }
    }

    cancelClick() {
        ( <HTMLFormElement>document.getElementById( 'editForm' ) ).reset();
        this.confirmPassword = true;
        $( '#editModal' ).modal( 'hide' );
    }


    updateUserDetail() {
        this.isLoading = true;
        let userDetail = new MUserMasterPojo();
        userDetail = this.userDetail;
        this.userCreationService.updateManagementUser( userDetail ).subscribe( response => {
            bootbox.alert( { message: 'User profile has been successfully updated.', className: 'text-success h5', closeButton: false } );
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Update M User Master Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
}
