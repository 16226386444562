import { Component, OnInit } from '@angular/core';
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { TenderService } from "src/app/EngSection/WorkProgress/tender/tender.service";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
import * as moment from 'moment';
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { TenderBidderDocumentService } from "src/app/EngSection/WorkProgress/tender-bidder-doc/tender-bidder-doc.service";
import { TenderBidderDocumentPojo } from "src/app/Pojo/tender-bidder-document.pojo";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { TenderActionService } from "src/app/EngSection/WorkProgress/tender-action/tender-action.service";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-tender-bidder-doc',
  templateUrl: './tender-bidder-doc.component.html',
  styleUrls: ['./tender-bidder-doc.component.scss']
})
export class TenderBidderDocComponent implements OnInit {
    model: NgbDateStruct;
   date: { year: number, month: number };
   filedate: any;
   tenderdate:any;
   startingdate:any;
   closingdate:any;
   tempdate:any;
    tenderFlag:boolean = false;
    quotationFlag:boolean = false;
    estimateType;
    addQuotation = new QuotationPojo();
    quotationArr:QuotationPojo[]=[];
    tenderArr:TenderActionPojo[]=[];
    addTender = new TenderActionPojo();
    estimateArr :EstimatePojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
     addTenderDoc = new TenderBidderDocumentPojo();
     tenderPojo = new TenderPojo();
    selectedFiles: File[] = [];
    progressStatus = 0;
    fileincreament = 0;
    docCount = 0;
    imagePojo = new FtpImagePojo();
    status;
    fileNameArr=[];
    
    imageUploadStatus = false;
    imageEdit = true;
    fileObj: any;
    imageUploadLoading: boolean = false;
    uniqueId;
    loggedUser = new MUserMasterPojo();
    id:number;
    documentFlag:boolean = false;
  constructor(private tenderactionservice:TenderActionService,private tenderbidderDocumentService:TenderBidderDocumentService,private tenderBidderMasterService: TenderBidderMasterService,private estimateService:EstimateService,private tenderService:TenderService,private quotationService:QuotationService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

        this.getEstimateAll();
      this.getTenderMasterMasterAll();
      this.tenderbidderDocumentService.autoGenerateTBDocNo().subscribe(( res ) => {
          let d = new TenderBidderDocumentPojo();
          d=res;
          this.addTenderDoc.tBDocNo = d.tBDocNo;
      }, er => { alert( "Fialed To Generate Document Number" + er ); 
      } );
  }
  
  selectTypes(estType){
      if(estType == 1){
          this.addTenderDoc.estimateTypeId =2;
      this.getTenderAll(); 
      this.tenderFlag=true;
      this.quotationFlag=false;
      }
      if(estType == 2){
          this.addTenderDoc.estimateTypeId = 1;
          this.getQuotationAll();
          this.quotationFlag=true;
          this.tenderFlag=false;
      }
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderById(id) {
      this.tenderService.getTenderById(id).subscribe( response => {
          this.tenderPojo = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderMasterMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderAll(){
      this.tenderactionservice.getUniqueTenderActionListAll().subscribe( response => {
          this.tenderArr = response;
      }, error => {
          bootbox.alert( 'Get Tender All Error! - HTTP Status ' + error.status );
      } );
  }
  showTender(){
      let id = ( <HTMLInputElement>document.getElementById( 'tenderId' ) ).value ;
      this.uniqueId = id;
      this.tenderactionservice.getTenderActionById(id).subscribe( response => {
          this.addTender = response;
         
          this.getTenderById(this.addTender.tenderId);
          $( '#editModal' ).modal( 'show' );
             }, error => {
          bootbox.alert( 'Get TenderAction By ID Error! - HTTP Status ' + error.status );
      } );
  }
 tenderClose(){
     
     $( '#editModal' ).modal( 'hide' );
     this.documentFlag = true;
 }
 quotationClose(){
     $( '#quotationModal' ).modal( 'hide' );
     this.documentFlag = true;
 }
  getQuotationAll(){
      this.quotationService.getUniqueQuotationAll().subscribe( response => {
          this.quotationArr = response;
      }, error => {
          bootbox.alert( 'Get Quotation Master All Error! - HTTP Status ' + error.status );
      } );
}
  showQuotation(){
      let id = ( <HTMLInputElement>document.getElementById( 'quotationId' ) ).value ;
      this.uniqueId = id;
          this.quotationService.getQuotationById(id).subscribe( response => {
              this.addQuotation = response;
              this.documentFlag = true;
              if(this.addQuotation.quotationDate != null && this.addQuotation.quotationDate != undefined){
                  this.tempdate = { day: moment( this.addQuotation.quotationDate ).date(), month: moment( this.addQuotation.quotationDate ).month(), year: moment( this.addQuotation.quotationDate ).year() };
                   }
              $( '#quotationModal' ).modal( 'show' );
              
          }, error => {
              bootbox.alert( 'Get Quotation By ID Error! - HTTP Status ' + error.status );
          } );
  }
  
  setFiles(images) {
      this.docCount = 0;
      $( '#reasonModal' ).modal( 'show' );
       for(let i=0;i<images.files.length;i++) {   
       this.selectedFiles.push(images.files[i]);
   }   this.docCount = this.selectedFiles.length;
      // this.status = "Documents Still Not Uploading";
   }
       saveFileName(){
       if(( <HTMLInputElement>document.getElementById( 'fileName' ) ).value!='')
       {
           let fileName = ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value; 
       this.fileNameArr.push(fileName);
       $( '#reasonModal' ).modal( 'hide' );
       ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value ='';
       }
      
  else{
      bootbox.alert('Please Enter the FileName');
  }
 }  
  modalShow(){
      $( '#myModal1' ).modal( 'show' );
  }
  saveDocumentName(){
      let docName = ( <HTMLInputElement>document.getElementById( 'DocumentName' ) ).value; 
      if(docName != null && docName!= undefined && docName != ''){
          this.addTenderDoc.docDescription = docName;
          $( '#documentModal' ).modal( 'hide' );
          
      }else{
          bootbox.alert('Please Enter the Document Name');
      }
  }
  update() {
      let docName = ( <HTMLInputElement>document.getElementById( 'DocumentName' ) ).value; 
      if(this.selectedFiles.length!=0 && docName == null || docName == undefined || docName == ''){
          $( '#documentModal' ).modal( 'show' );
      }else{
      $('#addLoading').addClass('fa fa-spinner fa-spin');
      this.imageUploadLoading = true;
            if(this.selectedFiles.length !== this.fileincreament) {
     this.id = 2;
     this.tenderbidderDocumentService.uploadImage( this.selectedFiles[this.fileincreament] , this.id, this.addTenderDoc.tBDocNo,this.fileNameArr[this.fileincreament] ).subscribe(( res ) => {
         this.imagePojo = res;
         this.fileincreament++;
         this.update();
         this.imageUploadLoading = false;
         
     }, er => { alert( "Fialed image uploading..." + er ); 
     $('#addLoading').removeClass('fa fa-spinner fa-spin');
     this.imageUploadLoading = false; } );
     }
     else{
         if(this.addTenderDoc.estimateTypeId == 2){
         this.addTenderDoc.tBDocDate = moment().toDate();
         this.addTenderDoc.tBDocTime = moment().format('hh:mm a');
         this.addTenderDoc.bidderId = this.addTender.bidderId;
         this.addTenderDoc.estimateId = this.tenderPojo.estimateId;
         this.addTenderDoc.estimateTypeId = 2;
         this.addTenderDoc.uniqueId = this.uniqueId;
         this.addTenderDoc.userId = this.loggedUser.userId;
         this.tenderbidderDocumentService.addTenderBidderDocument(this.addTenderDoc).subscribe(( res ) => {
             //$( '#editModal' ).modal( 'hide' );
             this.reset();
             bootbox.alert('Successfully Updated'); 
             $('#addLoading').removeClass('fa fa-spinner fa-spin');
         }, er => { alert( "Error " + er ); 
         this.imageUploadLoading = false;
         $('#addLoading').removeClass('fa fa-spinner fa-spin');} );
     }
    
      else if(this.addTenderDoc.estimateTypeId == 1){
         this.addTenderDoc.tBDocDate = moment().toDate();
         this.addTenderDoc.tBDocTime = moment().format('hh:mm a');
         this.addTenderDoc.bidderId = this.addQuotation.bidderId;
         this.addTenderDoc.estimateId = this.addQuotation.estimateId;
         this.addTenderDoc.estimateTypeId = 1;
         this.addTenderDoc.docDescription = "Just";
         this.addTenderDoc.uniqueId = this.uniqueId;
         this.addTenderDoc.userId = this.loggedUser.userId;
         this.tenderbidderDocumentService.addTenderBidderDocument(this.addTenderDoc).subscribe(( res ) => {
             //$( '#editModal' ).modal( 'hide' );
             this.reset();
             bootbox.alert('Successfully Updated');
             $('#addLoading').removeClass('fa fa-spinner fa-spin');
             ( <HTMLInputElement>document.getElementById( 'DocumentName' ) ).value = '';
         }, er => { alert( "Error " + er ); 
         this.imageUploadLoading = false;
         $('#addLoading').removeClass('fa fa-spinner fa-spin');} );
     }
     }
      }
  }
 removeFile(images,i) {
     this.selectedFiles.splice(i,1);
     this.fileNameArr.splice(i,1);
     this.docCount--;
 }
 reset(){
     this.selectedFiles = [];
     this.fileNameArr =[];
     this.docCount =0;
     this.addTenderDoc = new TenderBidderDocumentPojo();
     this.uniqueId = 0;
     this.tenderFlag = false;
     this.quotationFlag = false;
     this.documentFlag = false;
 }

}
