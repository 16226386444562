import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import * as moment from 'moment';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
    tempdate: any;

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    quotationArr : QuotationPojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    addQuotation = new QuotationPojo();
    editQuotation = new QuotationPojo();
    selectedFiles : File[] =[];  
    estimateArr: EstimatePojo[]=[];
    
  constructor(private quotationService: QuotationService,private tenderBidderMasterService: TenderBidderMasterService,private estimateService:EstimateService) { }

  ngOnInit() {
      
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
      this.getTenderBidderMasterAll();
      this.getEstimateAll();
  }
  
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }

  getEstimateAll() {
      this.isLoading = true;
      this.estimateService.getEstimateAllByQuotation().subscribe( response => {
          this.estimateArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Estimate All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  
  /*getQuotationAll() {
      this.estimateService.getEstimateAllByQuotation().subscribe( response => {
          this.estimateArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Quotation Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
 */
  addquotation() {
      
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      if( this.tempdate != null || this.tempdate != undefined){
          this.addQuotation.quotationDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
      }
      this.addQuotation.userId = this.loggedUser.userId;
      this.addQuotation.selstatus = false;
      this.quotationService.addQuotation( this.addQuotation ).subscribe( response => {
          if(response.code === 101)
          {
          bootbox.alert( response.response);
           ( <HTMLFormElement>document.getElementById( 'addQuotationForm' ) ).reset();
        this.reset();
           $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }
          else if(response.code === 102)
          {
          bootbox.alert( response.response);
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }
          
      }, error => {
          bootbox.alert( 'Add Quotation Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
 
  reset(){
      this.addQuotation= new QuotationPojo();
      this.tempdate = null;
      }
}
