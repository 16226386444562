import { PaymentProgressPojo } from "src/app/Pojo/payment-progress.pojo";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";

export class PaymentReportPojo{
    orderPojo = new WorkOrderPojo();
    paymentList:PaymentProgressPojo[];
    workPojo = new WorkMasterPojo();
    estimatePojo = new EstimatePojo();
    actionPojo = new TenderActionPojo();
    qPojo = new QuotationPojo();
    code:number;
    response:string;
    
}

