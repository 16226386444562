import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { EstimateTypeMasterPojo } from "src/app/Pojo/estimate-type-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class EstimateTypeMasterPojoService {

    constructor( private httpclient: HttpClient ) { }
    
    getEstimateTypeAll(): Observable<EstimateTypeMasterPojo[]> {
        const url = StaticVariable.porturl + 'EstimateTypeMasterAll/';
        return this.httpclient.get<EstimateTypeMasterPojo[]>( url );
    }
     addEstimateType( arg: EstimateTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddEstimateTypeMaster';
        return this.httpclient.post( url, arg );
    }

    updateEstimateType( arg: EstimateTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateEstimateTypeMaster';
        return this.httpclient.put( url, arg );
    }

    deleteEstimateType( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteEstimateTypeMaster/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getEstimateTypeById(id): Observable<EstimateTypeMasterPojo> {
        const url = StaticVariable.porturl + 'EstimateTypeMasterById/'+id;
        return this.httpclient.get<EstimateTypeMasterPojo>( url );
    }
}
