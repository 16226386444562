export class TenderActionPojo{
    
      tActionId:number;
      tActionDate:Date;
      tActionTime:String;
      tenderId:number;
      bidderId:number;
      actionAmount:number;
      selStatus:boolean;
      userId:number;
tenderActionNo:string;
}