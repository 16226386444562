import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

export class WorkProgressPojo{
    
      progressId: number;
      progressDate: Date;
      progressTime: string;
      orderId: number;
      progressPercentage: number;
      progress: string;
      progressNo: string;
      userId: number;
      imageList:FtpImagePojo[];

}