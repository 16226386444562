import { Component, OnInit } from '@angular/core';
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { SanctionlevelService } from "src/Services/sanction-level.service";
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { EstimateTypeMasterPojo } from "src/app/Pojo/estimate-type-pojo";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";
import { WorkMasterService } from "src/app/EngSection/Master/work-master/work-master.service";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
import { TenderService } from "src/app/EngSection/WorkProgress/tender/tender.service";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-tender',
    templateUrl: './tender.component.html',
    styleUrls: ['./tender.component.scss']
  })
export class TenderComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    filedate: NgbDateStruct;
    tenderdate:NgbDateStruct;
    startingdate:NgbDateStruct;
    closingdate:NgbDateStruct;

    imageUploadStatus = false;
    imageEdit = true;
    fileObj: any;
    imageUploadLoading: boolean = false;
    
   addTender =new TenderPojo();
   estimateArr :EstimatePojo[] = [];
   sanctionLevelArr: SanctionLevelMasterPojo[] = [];
   workArr : WorkMasterPojo[] = [];
   loggedUser = new MUserMasterPojo();
   
   id: number;
   isLoading: boolean;
    
   selectedFiles: File[] = [];
   progressStatus = 0;
   fileincreament = 0;
   docCount = 0;
  constructor(private calendar: NgbCalendar,private estimateService:EstimateService,
          private tenderService:TenderService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      this.getEstimateAll();
       }
   getEstimateAll() {
      this.isLoading = true;
      this.estimateService.getEstimateAllByTender().subscribe( response => {
          this.estimateArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }

  noEnter( e ) {
      return !( window.event && e.keyCode == 13 );
  }
  addTenderEntry(){
      $('#addBeforeClick').hide();$('#addAfterClick').show();
      if( this.filedate != null || this.filedate != undefined){
          this.addTender.fileDate = moment( this.filedate.year + '-' + this.filedate.month + '-' + this.filedate.day, 'YYYY-MM-DD' ).toDate();
      }
      if( this.tenderdate != null || this.tenderdate != undefined){
          this.addTender.tenderDate = moment( this.tenderdate.year + '-' + this.tenderdate.month + '-' + this.tenderdate.day, 'YYYY-MM-DD' ).toDate();
      }
      if( this.startingdate != null || this.startingdate != undefined){
          this.addTender.tenderOpenDate = moment( this.startingdate.year + '-' + this.startingdate.month + '-' + this.startingdate.day, 'YYYY-MM-DD' ).toDate();
      }
      if( this.closingdate != null || this.closingdate != undefined){
          this.addTender.tenderCloseDate = moment( this.closingdate.year + '-' + this.closingdate.month + '-' + this.closingdate.day, 'YYYY-MM-DD' ).toDate();
      }
      this.addTender.userId = this.loggedUser.userId;
        this.tenderService.addTender( this.addTender ).subscribe( result => {
        bootbox.alert('Successfully Added');
            $('#addBeforeClick').show();$('#addAfterClick').hide();
          this.reset();
           }, error => {
          bootbox.alert( error + 'Error' );
          $('#addBeforeClick').show();$('#addAfterClick').hide();
           } );
  }
  reset(){
      this.addTender = new TenderPojo();
      this.filedate = null;
      this.tenderdate = null;
      this.startingdate = null;
      this.closingdate = null;
     }
}
