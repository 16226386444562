import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { StaticVariable } from "src/app/globle.class";
import { ResponsePojo } from "src/app/pojo/response.pojo";
declare var bootbox: any;

const httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
};

@Injectable()

export class BackupService {

    constructor( private httpclient: HttpClient) { }
    
     backUpData(): Observable<any> {
        const url = StaticVariable.porturl + 'BackUpDB';
        return this.httpclient.get( url );
    }
   }