import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'EstimationListPipe'
} )

export class EstimationListPipe implements PipeTransform {
  
    transform( array: any[], query: string, query1: string ): any {
        if ( query ) {
            if ( _.filter( array, row => row.estimateNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.estimateNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.estimateVaue == query ) != '' ) {
                return _.filter( array, row => row.estimateVaue == query );
            } else {
                return false;
            }
        }
        return array;
    }

}
