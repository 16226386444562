import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class SanctionlevelService {

    constructor( private httpclient: HttpClient ) { }
    
    getSanctionLevelAll(): Observable<SanctionLevelMasterPojo[]> {
        const url = StaticVariable.porturl + 'SanctionLevelMasterAll/';
        return this.httpclient.get<SanctionLevelMasterPojo[]>( url );
    }
     addSanctionLevel( arg: SanctionLevelMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSanctionLevelMaster';
        return this.httpclient.post( url, arg );
    }

    updateSanctionLevel( arg: SanctionLevelMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSanctionLevelMaster';
        return this.httpclient.put( url, arg );
    }

    deleteSanctionLevel( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSanctionLevelMaster/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getSanctionLevelById(id): Observable<SanctionLevelMasterPojo> {
        const url = StaticVariable.porturl + 'SanctionLevelMasterById/'+id;
        return this.httpclient.get<SanctionLevelMasterPojo>( url );
    }
}
