import { Component, OnInit } from '@angular/core';
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { SanctionlevelService } from "src/Services/sanction-level.service";
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { EstimateTypeMasterPojo } from "src/app/Pojo/estimate-type-pojo";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";
import { WorkMasterService } from "src/app/EngSection/Master/work-master/work-master.service";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    tempdate: NgbDateStruct;
    approveddate:NgbDateStruct;

    imageUploadStatus = false;
    imageEdit = true;
    fileObj: any;
    imageUploadLoading: boolean = false;
    
   addEst =new EstimatePojo();
   estimateTypeArr :EstimateTypeMasterPojo[] = [];
   sanctionLevelArr: SanctionLevelMasterPojo[] = [];
   workArr : WorkMasterPojo[] = [];
   loggedUser = new MUserMasterPojo();
   
   id: number;
   isLoading: boolean;
    
   selectedFiles: File[] = [];
   progressStatus = 0;
   fileincreament = 0;
   docCount = 0;
   imagePojo = new FtpImagePojo();
   status;
   fileNameArr=[];
   
  constructor(private calendar: NgbCalendar,private estimateService:EstimateService,private estimateTypeservice:EstimateTypeMasterPojoService,
          private workMasterService: WorkMasterService,private sanctionLevelService:SanctionlevelService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#uploadAfterClick' ).hide();
      this.getAutoEstimateNum();
      this.getEstimateTypeAll();
      this.getSanctionLevelAll();
      this.getWorkMasterAll();
      
  }
  setFiles(images) {
     this.docCount = 0;
     $( '#reasonModal' ).modal( 'show' );
      for(let i=0;i<images.files.length;i++) {   
      this.selectedFiles.push(images.files[i]);
  }   this.docCount = this.selectedFiles.length;
     // this.status = "Documents Still Not Uploading";
  }
      saveFileName(){
      if(( <HTMLInputElement>document.getElementById( 'fileName' ) ).value!='')
      {
          let fileName = ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value; 
      this.fileNameArr.push(fileName);
      $( '#reasonModal' ).modal( 'hide' );
      ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value ='';
      }
     
 else{
     bootbox.alert('Please Enter the FileName');
 }
}  
  getEstimateTypeAll() {
      this.isLoading = true;
      this.estimateTypeservice.getEstimateTypeAll().subscribe( response => {
          this.estimateTypeArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getAutoEstimateNum() {
      this.estimateService.autoGenerateEstNo().subscribe( response => {
          this.addEst = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  
  getSanctionLevelAll() {
      this.isLoading = true;
      this.sanctionLevelService.getSanctionLevelAll().subscribe( response => {
          this.sanctionLevelArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'SanctionLevel All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getWorkMasterAll() {
      this.workMasterService.getWorkMasterAll().subscribe( response => {
          this.workArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Work Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  modalShow(){
      $( '#myModal1' ).modal( 'show' );
  }
  changeImage() {
      this.imageEdit = true;
  }

  /*uploadClick() {
      this.uploadFile( this.fileObj );
  }*/
  uploadFiles() {
       this.imageUploadLoading = true;
      if(this.selectedFiles.length !== this.fileincreament) {
      this.id = 1;
      this.estimateService.uploadImage( this.selectedFiles[this.fileincreament] , this.id, this.addEst.estimateNo,this.fileNameArr[this.fileincreament] ).subscribe(( res ) => {
          this.imagePojo = res;
          this.fileincreament++;
          this.uploadFiles();
          this.imageUploadLoading = false;
          if ( this.imagePojo.imageId != 0 ) {
              this.imageUploadStatus = true;
              this.addEst.fileName = this.imagePojo.imageName;
              this.addEst.fileAddressPath = this.imagePojo.imagePath;
              this.addEst.imageId = this.imagePojo.imageId;
              
          } else {
              alert( "Fialed Image Uploading....." );
              $('#addBeforeClick').show();$('#addAfterClick').hide();
              
          }
      }, er => { alert( "Fialed image uploading..." + er ); 
      $('#addBeforeClick').show();$('#addAfterClick').hide();
      this.imageUploadLoading = false; } );
      }else{
          this.estimateService.addEstimate( this.addEst ).subscribe( result => {
              bootbox.alert('Successfully Added');
                  $('#addBeforeClick').show();$('#addAfterClick').hide();
                this.reset();
                this.getAutoEstimateNum();
                 }, error => {
                bootbox.alert( error + 'Error' );
                $('#addBeforeClick').show();$('#addAfterClick').hide();
                this.imageUploadLoading = false; 
                 } );
      }
      }
  removeFile(images,i) {
      this.selectedFiles.splice(i,1);
      this.fileNameArr.splice(i,1);
      this.docCount--;
  }
 
  noEnter( e ) {
      return !( window.event && e.keyCode == 13 );
  }
  addEstimation(){
      $('#addBeforeClick').hide();$('#addAfterClick').show();
      this.addEst.documentTypeId = 1;
      if( this.tempdate != null || this.tempdate != undefined){
          this.addEst.estimateDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
      }
      if( this.approveddate != null || this.approveddate != undefined){
          this.addEst.approvedDate = moment( this.approveddate.year + '-' + this.approveddate.month + '-' + this.approveddate.day, 'YYYY-MM-DD' ).toDate();
      }
      if(this.addEst.approvedBy != null && this.addEst.approvedBy != undefined && this.addEst.approvedBy != '')
          {
          this.addEst.status = "APPROVED";
          }
      else{
          this.addEst.status = "REJECTED";
      }
      if(this.selectedFiles.length != 0 ){
          this.uploadFiles();
      }else{
       this.estimateService.addEstimate( this.addEst ).subscribe( result => {
        bootbox.alert('Successfully Added');
            $('#addBeforeClick').show();$('#addAfterClick').hide();
          this.reset();
          this.getAutoEstimateNum();
           }, error => {
          bootbox.alert( error + 'Error' );
           } );
      }
  }
  reset(){
      this.addEst = new EstimatePojo();
      this.imageEdit = true;
      this.tempdate = null;
      this.approveddate = null;
      this.imageUploadLoading = false;
      this.selectedFiles = [];
      this.docCount =0;
      this.status = "";
        }
}
