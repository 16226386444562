import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { WorkMasterTimeLinePojo } from 'src/app/Pojo/WorkMasterTimeLine.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class WorkMasterTimeLine {
    constructor( private httpclient: HttpClient ) { }

     addTimeLineStatus( arg: WorkMasterTimeLinePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddWorkMasterTimeLine';
        return this.httpclient.post( url, arg );
    }
    getTimelineDetailsById(id:number): Observable<WorkMasterTimeLinePojo[]> {
        const url = StaticVariable.porturl + 'GetWorkMasterTimeLineById/'+id;
        return this.httpclient.get<WorkMasterTimeLinePojo[]>( url );
    }
}