import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { StaticVariable } from 'src/app/globle.class';
import { WorkTypeMasterPojo } from 'src/app/Pojo/work-type-master-pojo';

@Injectable({
  providedIn: 'root'
})
export class WorktypeMasterService {

  constructor(private httpclient: HttpClient ) { }

getworktypeMasterAll(): Observable<WorkTypeMasterPojo[]> {
    const url = StaticVariable.porturl + 'WorkTypeMasterAll/';
    return this.httpclient.get<WorkTypeMasterPojo[]>( url );
}
 addWorktypeMaster( arg: WorkTypeMasterPojo ): Observable<any> {
    const url = StaticVariable.porturl + 'AddWorkTypeMaster';
    return this.httpclient.post( url, arg );
}

updateWorktypeMaster( arg:WorkTypeMasterPojo ): Observable<any> {
    const url = StaticVariable.porturl + 'UpdateWorkTypeMaster';
    return this.httpclient.put( url, arg );
}

deleteWorktypeMaster( id: number,uid ): Observable<any> {
    const url = StaticVariable.porturl + 'DeleteWorkTypeMaster/' + id+'/'+uid;
    return this.httpclient.delete<any>( url );
}

}
