import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient, HttpParams } from "@angular/common/http";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class WorkMasterService {
    getWorkMasterByamount(amt: any, amount: any) {
        throw new Error('Method not implemented.');
    }

    constructor( private httpclient: HttpClient ) { }
    
    getWorkMasterAll(): Observable<WorkMasterPojo[]> {
        const url = StaticVariable.porturl + 'WorkMasterAll/';
        return this.httpclient.get<WorkMasterPojo[]>( url );
    }
     addWorkMaster( arg: WorkMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddWorkMaster';
        return this.httpclient.post( url, arg );
    }

    updateWorkMaster( arg: WorkMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateWorkMaster';
        return this.httpclient.put( url, arg );
    }

    deleteWorkMaster( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteWorkMaster/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getWorkMasterById(id): Observable<WorkMasterPojo> {
        const url = StaticVariable.porturl + 'WorkMasterById/'+id;
        return this.httpclient.get<WorkMasterPojo>( url );
    }
    getWorkMasterByDateAndStatus(from,to,status): Observable<WorkMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetWorkMasterByDateAndStatus/'+from+'/'+to+'/'+status;
        return this.httpclient.get<WorkMasterPojo[]>( url );
    }
    getWorkMasterByProjectDate(from,to,projectDate): Observable<WorkMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetWorkMasterByProjectDate/'+from+'/'+to+'/'+projectDate;
        return this.httpclient.get<WorkMasterPojo[]>( url );
    }
    getWorkMaster(from: string,to: string,status: string,amountFlag:string,amount,dateFlag:string,bidderId): Observable<WorkMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetWorkMaster';
        let param = new HttpParams().append('status',status).append('dateFrom',from).append('dateTo',to).append('amountFlag',amountFlag).append('amount',amount).append('dateFlag',dateFlag).append('bidderId',bidderId);
        return this.httpclient.get<WorkMasterPojo[]>( url,{params:param} );
    }
    getWorkMasterReport(from: string,to: string,status: string,amountFlag:string,amount,dateFlag:string,bidderId): Observable<any> {
        const url = StaticVariable.porturl + 'GetWorkMasterReport';
      
        let param = new HttpParams().append('status',status).append('dateFrom',from).append('dateTo',to).append('amountFlag',amountFlag).append('amount',amount).append('dateFlag',dateFlag).append('bidderId',bidderId);
        const httpOption = {responseType: 'arraybuffer' as 'json', params:param};
        return this.httpclient.get<any>( url,httpOption );
    }
}
