import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'TenderBidderMasterPipe'
} )

export class TenderBidderMasterPipe implements PipeTransform {

    transform( array: any[], query: string, query1: string ): any {
        if ( query ) {
            if ( _.filter( array, row => row.bidderName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.bidderName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.ownerName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.ownerName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}
