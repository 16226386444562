import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { ReceiptPojo } from "src/app/Pojo/receipt.pojo";


@Injectable( {
    providedIn: 'root'
} )

export class ReceiptService {

    constructor( private httpclient: HttpClient ) { }
    
    getReceiptAll(): Observable<ReceiptPojo[]> {
        const url = StaticVariable.porturl + 'ReceiptAll/';
        return this.httpclient.get<ReceiptPojo[]>( url );
    }
     addReceipt( arg: ReceiptPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddReceipt';
        return this.httpclient.post( url, arg );
    }

    updateReceipt( arg: ReceiptPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateReceipt';
        return this.httpclient.put( url, arg );
    }

    deleteReceipt( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteReceipt/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getReceiptById(id): Observable<ReceiptPojo> {
        const url = StaticVariable.porturl + 'ReceiptById/'+id;
        return this.httpclient.get<ReceiptPojo>( url );
    }
}
