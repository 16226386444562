import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTableModule } from "angular-6-datatable-cc";
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './EngSection/login/login.component';
import { RoleMasterComponent } from './EngSection/Admin/role-master/role-master.component';
import { UserCreationComponent } from './EngSection/Admin/user-creation/user-creation.component';
import { ChangePasswordComponent } from './EngSection/Admin/change-password/change-password.component';
import { BackupComponent } from './EngSection/Admin/backup/backup.component';
import { MenubarComponent } from './EngSection/menubar/menubar.component';
import { RoleMasterPipe } from "src/app/EngSection/Admin/role-master/role-master.pipe";
import { UserCreationPipe } from "src/app/EngSection/Admin/user-creation/user-creation.pipe";
import { TenderBidderMasterComponent } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.component";
import { WorkMasterComponent } from "src/app/EngSection/Master/work-master/work-master.component";
import { ReceiptTypeMasterComponent } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.component";
import { ReceiptTypeMasterPipe } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.pipe";
import { TenderBidderMasterPipe } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.pipe";
import { WorkMasterPipe } from "src/app/EngSection/Master/work-master/work-master.pipe";
import { EstimateComponent } from "src/app/EngSection/WorkProgress/estimate/estimate.component";
import { QuotationComponent } from "src/app/EngSection/WorkProgress/quotation/quotation.component";
import { TenderComponent } from "src/app/EngSection/WorkProgress/tender/tender.component";
import { EstimationListComponent } from "src/app/EngSection/Entry-List/estimation-list/estimation-list.component";
import { QuotationListComponent } from "src/app/EngSection/Entry-List/quotation-list/quotation-list.component";
import { TenderListComponent } from "src/app/EngSection/Entry-List/tender-list/tender-list.component";
import { TenderListPipe } from "src/app/EngSection/Entry-List/tender-list/tender-list.pipe";
import { QuotationListPipe } from "src/app/EngSection/Entry-List/quotation-list/quotation-list.pipe";
import { EstimationListPipe } from "src/app/EngSection/Entry-List/estimation-list/estimation-list.pipe";
import { ReceiptComponent } from "src/app/EngSection/WorkProgress/receipt/receipt.component";
import { ReceiptListComponent } from "src/app/EngSection/Entry-List/receipt-list/receipt-list.component";
import { QuotationActionComponent } from "src/app/EngSection/Action/quotation-action/quotation-action.component";
import { TenderActionComponent } from "src/app/EngSection/Action/tender-action/tender-action.component";
import { ReceiptListPipe } from "src/app/EngSection/Entry-List/receipt-list/receipt-list.pipe";
import { TenderActionEntryComponent } from "src/app/EngSection/WorkProgress/tender-action/tender-action.component";
import { TenderBidderDocComponent } from "src/app/EngSection/WorkProgress/tender-bidder-doc/tender-bidder-doc.component";
import { TenderActionListComponent } from "src/app/EngSection/Entry-List/tender-action-list/tender-action-list.component";
import { TenderBidderDocListComponent } from "src/app/EngSection/Entry-List/tender-bidder-doc-list/tender-bidder-doc-list.component";
import { WorkOrderComponent } from "src/app/EngSection/Work/work-order/work-order.component";
import { AgreementComponent } from "src/app/EngSection/Work/agreement/agreement.component";
import { WorkProgressComponent } from "src/app/EngSection/Work/work-progress/work-progress.component";
import { PaymentProgressComponent } from "src/app/EngSection/Work/payment-progress/payment-progress.component";
import { WorkCompletionComponent } from "src/app/EngSection/Work/work-completion/work-completion.component";
import { TenderActionListPipe } from "src/app/EngSection/Entry-List/tender-action-list/tender-action-list.pipe";
import { WorkorderPipe } from "src/app/EngSection/Work/work-order/work-order.pipe";
import { AgreementPipe } from "src/app/EngSection/Work/agreement/agreement.pipe";
import { WorkProgressPipe } from "src/app/EngSection/Work/work-progress/work-progress.pipe";
import { WorkCompletionPipe } from "src/app/EngSection/Work/work-completion/work-completion.pipe";
import { ProgressReportComponent } from "src/app/EngSection/Report/progress-report/progress-report.component";
import { PaymentProgressPipe } from "src/app/EngSection/Work/payment-progress/payment-progress.pipe";
import { PaymentReportComponent } from "src/app/EngSection/Report/payment-report/payment-report.component";
import { SettingsComponent } from "src/app/EngSection/Admin/settings/settings.component";
import { BidderReportComponent } from "src/app/EngSection/Report/bidder-report/bidder-report.component";
import { QuotatinReportComponent } from "src/app/EngSection/Report/quotatin-report/quotatin-report.component";
import { TenderReportComponent } from "src/app/EngSection/Report/tender-report/tender-report.component";
import { SectionMasterComponent } from './EngSection/Master/section-master/section-master.component';
import { UserComponent } from './EngSection/Work-master-report/user.component';
import { WorkTypeMasterComponent } from './EngSection/Master/work-type-master/work-type-master.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RoleMasterComponent,
    UserCreationComponent,
    ChangePasswordComponent,
    BackupComponent,
    MenubarComponent,
    RoleMasterPipe,
    UserCreationPipe,
    WorkMasterComponent,
    TenderBidderMasterComponent,
    ReceiptTypeMasterComponent,
    ReceiptTypeMasterPipe,
    TenderBidderMasterPipe,
    WorkMasterPipe,
    EstimateComponent,
    QuotationComponent,
    TenderComponent,
    EstimationListComponent,
    QuotationListComponent,
    TenderListComponent,
    TenderListPipe,
    QuotationListPipe,
    EstimationListPipe,
    ReceiptComponent,
    ReceiptListComponent,
    QuotationActionComponent,
    TenderActionComponent,
    ReceiptListPipe,
    TenderActionEntryComponent,
   TenderBidderDocComponent,
   TenderActionListComponent,
   TenderBidderDocListComponent,
   WorkOrderComponent,
   AgreementComponent,
   WorkProgressComponent,
   PaymentProgressComponent,
   WorkCompletionComponent,
   TenderActionListPipe,
   WorkorderPipe,
   AgreementPipe,
   WorkProgressPipe,
   WorkCompletionPipe,
   ProgressReportComponent,
   PaymentProgressPipe,
   PaymentReportComponent,
   SettingsComponent,
   BidderReportComponent,
   QuotatinReportComponent,
   TenderReportComponent,
   SectionMasterComponent,
   UserComponent,
   WorkTypeMasterComponent,
   ],
  imports: [
            BrowserModule,
            FormsModule,
            HttpClientModule,
            AppRoutingModule,
            NgbModule,
            NgbPaginationModule,
            NgbAlertModule,
            DataTableModule,
            AngularFontAwesomeModule,
      ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
