import { TenderPojo } from "src/app/Pojo/Tender-pojo";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";

export class TenderReportPojo {

    tpojo = new TenderPojo();
    actionList:TenderActionPojo[];
    status:string;
    code:number;
    response:string;
    
}