import { Component, OnInit } from '@angular/core';
import { ReceiptTypePojo } from "src/app/Pojo/receipt-type-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { ReceiptTypeMasterService } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.service";
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-receipt-type-master',
  templateUrl: './receipt-type-master.component.html',
  styleUrls: ['./receipt-type-master.component.scss']
})
export class ReceiptTypeMasterComponent implements OnInit {

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    receiptTypeArr : ReceiptTypePojo[] = [];
    addReceipttype = new ReceiptTypePojo();
    editReceipttype = new ReceiptTypePojo();
    
  constructor(private receiptTypeMasterService: ReceiptTypeMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getReceiptTypeMasterAll();
  }
  getReceiptTypeMasterAll() {
      this.receiptTypeMasterService.getReceiptTypeAll().subscribe( response => {
          this.receiptTypeArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Receipt Type All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  addReceiptType() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      this.addReceipttype.userId = this.loggedUser.userId;
      this.receiptTypeMasterService.addReceiptType( this.addReceipttype ).subscribe( response => {
          if(response.code === 101)
              {
          bootbox.alert( response.response);
           ( <HTMLFormElement>document.getElementById( 'addReceiptTypeForm' ) ).reset();
           $( '#addModal' ).modal( 'hide' );
          this.getReceiptTypeMasterAll();
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              }  
          else if(response.code === 102)
              {
              bootbox.alert( response.response);
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              }
      }, error => {
          bootbox.alert( 'Add Devotee Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
  
  clickEdit( arg ) {
      this.editReceipttype = Object.assign( {}, arg );
       }

  editReceiptType() {
      this.editReceipttype.userId = this.loggedUser.userId;
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.receiptTypeMasterService.updateReceiptType( this.editReceipttype ).subscribe( response => {
          if(response.code === 101)
          {
              bootbox.alert( response.response);
          $( '#editModal' ).modal( 'hide' );
          this.getReceiptTypeMasterAll();
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          }
          else if(response.code === 102)
          {
          bootbox.alert( response.response);
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          }
         
      }, error => {
          bootbox.alert( 'Update Receipt Type Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.receiptTypeArr.indexOf( this.receiptTypeArr.find( response => response.receiptTypeId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.receiptTypeMasterService.deleteReceiptType( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }else if ( no == 101 ) {
              bootbox.alert( 'You Cannot Delete!This Receipt Type linked to Some Other.. ');
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }  else {
              this.receiptTypeArr.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              bootbox.alert( 'Successfully deleted' );
              this.getReceiptTypeMasterAll();
          }
          
      }, error => {
          bootbox.alert( 'Delete Receipt Type Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
}
