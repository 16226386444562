import { Component, OnInit } from '@angular/core';
import { WorkOrderService } from "src/app/EngSection/Work/work-order/work-order.service";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
declare var $: any;
declare var bootbox: any;
import * as moment from 'moment';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { PaymentProgressService } from "src/app/EngSection/Work/payment-progress/payment-progress.service";
import { PaymentReportPojo } from "src/app/Pojo/PaymentReportPojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { StaticVariable } from "src/app/globle.class";
import { WorkMasterService } from "src/app/EngSection/Master/work-master/work-master.service";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { ShemeTypeMasterService } from "src/Services/shemme-type.service";
import { WorkTypeMasterService } from "src/Services/work-type-master.service";
import { SchemeTypeMasterPojo } from "src/app/Pojo/scheme-type-master-pojo";
import { WorkTypeMasterPojo } from "src/app/Pojo/work-type-master-pojo";
import { SanctionlevelService } from "src/Services/sanction-level.service";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";
import { WorkProgressReportPojo } from "src/app/Pojo/work-progress-report.pojo";
import { SettingService } from "src/app/EngSection/Admin/settings/settings.service";
import { SettingPojo } from "src/app/Pojo/settings.pojo";
import { BidderReportService } from "src/app/EngSection/Report/bidder-report/bidder-report.service";
import { BidderReportPojo } from "src/app/Pojo/bidder-report.pojo";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
    selector: 'app-bidder-report',
    templateUrl: './bidder-report.component.html',
    styleUrls: ['./bidder-report.component.scss']
  })
export class BidderReportComponent implements OnInit {
orderArr : WorkOrderPojo[] = [];

id: number;
isLoading: boolean = true;
orderId;
loggedUser = new MUserMasterPojo();
payReport = new BidderReportPojo();
item = new BidderReportPojo();
tableFlag:boolean = false;
estimateArr:EstimatePojo[] = [];
tenderbidderArr : TenderBidderMasterPojo[] = [];
workArr : WorkMasterPojo[] = [];
shemeArr : SchemeTypeMasterPojo[] = [];
workTypeArr : WorkTypeMasterPojo[] = [];
sanctionLevelArr: SanctionLevelMasterPojo[] = [];
setting = new SettingPojo();
body = [];
tenderbody = [];
quotationbody = [];
content = [];
resultOrderId;
documentTime: string;
fileNum;
workNames;
search;
workFlag:boolean = false;
fileFlag:boolean = false;

showWork:boolean = false;
showWorkOrder:boolean = false;
showEstimation:boolean = false;
showTAction:boolean = false;
showQAction:boolean = false;
showProgress:boolean = false;
showAgreement:boolean = false;
showCompletion:boolean = false;
totalGiven:number=0;

tenderFlag:boolean = false;
bidderId;
viewFlag:boolean = false;
quotationFlag:boolean = false;
viewQnFlag:boolean = false;
  constructor(private workMasterService: WorkMasterService,private PaymentProgressService: PaymentProgressService,private workOrderService:WorkOrderService,private estimateService:EstimateService,
          private bidderReportService:BidderReportService,private tenderBidderMasterService: TenderBidderMasterService,private settingService:SettingService,private sanctionLevelService:SanctionlevelService,private schemeTypeService:ShemeTypeMasterService,private workTypeMasterservice:WorkTypeMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      
      $( '#showAfterClick' ).hide();
      this.getWorkMasterAll();
      this.getWorkOrderAll();
      this.getTenderBidderMasterAll();
      this.getEstimateAll();
      this.getShemeTypeAll();
      this.getWorkTypeAll();
      this.getSanctionLevelAll();
      this.getPdfSettings();
  }
  view(arg){
      this.item = arg;
      this.viewFlag = true;
      $( '#viewModal' ).modal( 'show' );
  }
  viewQuotation(arg){
      this.item = arg;
      this.viewQnFlag = true;
      $( '#quotationModal' ).modal( 'show' );
  }
  closeTender(){
      this.viewFlag = false;
      $( '#viewModal' ).modal( 'hide' );
  }
  closeQuotation(){
      this.viewQnFlag = false;
      $( '#quotationModal' ).modal( 'hide' );
  }
  getPdfSettings(){
      this.settingService.getSettingsById(1).subscribe( response => {
          this.setting = response;
      }, error => {
          bootbox.alert( { message: 'Get Settings Service Error! HTTP Status ' + error.status } );
      } );
  }
  getWorkOrderAll() {
      this.workOrderService.getWorkOrderAll().subscribe( response => {
          this.orderArr = response;
      }, error => {
          bootbox.alert( 'Get WorkOrder All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkMasterAll() {
      this.workMasterService.getWorkMasterAll().subscribe( response => {
          this.workArr = response;
      }, error => {
          bootbox.alert( 'Get Work Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getShemeTypeAll() {
      this.schemeTypeService.getSchemeTypeAll().subscribe( response => {
          this.shemeArr = response;
      }, error => {
          bootbox.alert( 'Get ShemeType All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkTypeAll() {
      this.workTypeMasterservice.getWorkTypeAll().subscribe( response => {
          this.workTypeArr = response;
      }, error => {
          bootbox.alert( 'Get WorkType All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkTypeById( id ) {
      if ( this.workTypeArr.find( r => r.workTypeId == id ) ) {
          return this.workTypeArr.find( r => r.workTypeId == id ).workType;
      } else {
          return id;
      }
  }
  getWorkNameById( id ) {
      if ( this.workArr.find( r => r.workId == id ) ) {
          return this.workArr.find( r => r.workId == id ).workName;
      } else {
          return id;
      }
  }
  getFileNumById( id ) {
      if ( this.workArr.find( r => r.workId == id ) ) {
          return this.workArr.find( r => r.workId == id ).fileNo;
      } else {
          return id;
      }
  }
  getSanctionLevelAll() {
      this.sanctionLevelService.getSanctionLevelAll().subscribe( response => {
          this.sanctionLevelArr = response;
      }, error => {
          bootbox.alert( 'SanctionLevel All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getSchemeTypeById( id ) {
      if ( this.shemeArr.find( r => r.schemeTypeId == id ) ) {
          return this.shemeArr.find( r => r.schemeTypeId == id ).schemeType;
      } else {
          return id;
      }
  }
  getSanctioById( id ) {
      if ( this.sanctionLevelArr.find( r => r.sanctionLevelId == id ) ) {
          return this.sanctionLevelArr.find( r => r.sanctionLevelId == id ).sanctionLevel;
      } else {
          return id;
      }
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getEstimateNumById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
  getOrderNumById( id ) {
      if ( this.orderArr.find( r => r.orderId == id ) ) {
          return this.orderArr.find( r => r.orderId == id ).orderNo;
      } else {
          return id;
      }
  }
  getBidderNameById( id ) {
      if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
          return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
      } else {
          return id;
      }
  }
  searchType(){  
      if(this.search == "FILE"){
          this.workFlag = false;
          this.fileFlag = true;
      }
 if(this.search == "WORK"){
     this.workFlag = true;
     this.fileFlag = false;
      }
  }
  show(){
      this.tenderFlag = false;
      this.quotationFlag = false;
      this.payReport = new BidderReportPojo();
      $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
     
      this.bidderReportService.getBidderReportByBidderId(this.bidderId).subscribe( response => {
          this.resultOrderId = this.bidderId;
         $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          this.payReport = response;
          if(this.payReport.actionList.length >0){
              this.tenderFlag = true;
          }
          if(this.payReport.quotationList.length >0){
              this.quotationFlag = true;
              $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          }
          
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getOrderBalanceAmtById( id ) {
      if ( this.orderArr.find( r => r.orderId == id ) ) {
          return this.orderArr.find( r => r.orderId == id ).balanceAmount;
      } else {
          return id;
      }
  }
downloadPDF() {
      this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      this.content.push( {
          text: this.setting.pdfHeading, fontSize: 20, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: this.setting.pdfPlace + '\n', fontSize: 18, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
      } );
      this.content.push( {
          text: 'BIDDER REPORT', fontSize: 13, alignment: 'center', bold: true
      } );

      
      
         this.content.push( {
             text: 'SELECTED BIDDER : ' + this.getBidderNameById(this.resultOrderId) + '\n', fontSize: 12, alignment: 'center',  bold: true
         } );  
    
       this.content.push( {
          text: '\n', fontSize: 12, alignment: 'center'
      } );
       
       if(this.tenderFlag == true){
           
      
         for(let i=0;i< this.payReport.actionList.length;i++){
             this.content.push( {
                 text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                 fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
             } );
             this.content.push( {
                 text: 'TENDER ACTION DETAILS FOR' + ' ' +'[ '+this.payReport.actionList[i].actionPojo.tenderActionNo+' ]' + '\n', fontSize: 12, alignment: 'center',  bold: true
             } ); 
             
             
             if(this.payReport.actionList[i].tenderPojo.tenderId !=0){
             this.content.push( {
                 text: '\nTENDER DETAILS' + '\n', fontSize: 12,   bold: true
             } );
             
         this.content.push(
                 {
                     columns: [
                         {
                             width: '25%',
                             text: 'Tender Number', fontSize: 10
                         },
                         {
                             width: '25%',
                             text: ':'+this.payReport.actionList[i].tenderPojo.tenderNo, fontSize: 10,
                         },
                         {
                             width: '25%',
                             text: 'Tender Date', fontSize: 10
                         },
                         {
                             width: '25%',
                             text: ':'+moment(this.payReport.actionList[i].tenderPojo.tenderDate).format('DD/MM/YYYY'), fontSize: 10,
                         },
                     ]
                 } );
         this.content.push(
                 {
                     columns: [
                         {
                             width: '25%',
                             text: 'EMD Amount', fontSize: 10
                         },
                         {
                             width: '25%',
                             text: ':' + this.payReport.actionList[i].tenderPojo.emdAmount, fontSize: 10,
                         },
                         {
                             width: '25%',
                             text: 'Tender Open Date', fontSize: 10
                         },
                         {
                             width: '25%',
                             text: ':' +moment(this.payReport.actionList[i].tenderPojo.tenderOpenDate).format('DD/MM/YYYY'), fontSize: 10, 
                         },
                     ]
                 } );
         this.content.push(
                 {
                     columns: [
                         {
                             width: '25%',
                             text: 'Tender Close Date', fontSize: 10
                         },
                         {
                             width: '25%',
                             text: ':' +moment(this.payReport.actionList[i].tenderPojo.tenderCloseDate).format('DD/MM/YYYY'), fontSize: 10,
                         },
                         {
                             width: '25%',
                             text: 'File Date', fontSize: 10
                         },
                         {
                             width: '25%',
                             text: ':'+moment(this.payReport.actionList[i].tenderPojo.fileDate).format('DD/MM/YYYY'), fontSize: 10,
                         },
                     ]
                 } );
         }
         
             if(this.payReport.actionList[i].workPojo.workId !=0){
                 this.content.push( {
                     text: '\n WORK DETAILS' + '\n', fontSize: 12,   bold: true
                 } );
                 
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'File Name', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.payReport.actionList[i].workPojo.workName, fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'File Number', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.payReport.actionList[i].workPojo.fileNo, fontSize: 10,
                             },
                         ]
                     } );
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Work Type', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +this.getWorkTypeById(this.payReport.actionList[i].workPojo.workTypeId), fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Project Date', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +moment(this.payReport.actionList[i].workPojo.projectDate).format('DD/MM/YYYY'), fontSize: 10, 
                             },
                         ]
                     } );
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Scheme Type', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' + this.getSchemeTypeById(this.payReport.actionList[i].workPojo.schemeTypeId), fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Project Status', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.payReport.actionList[i].workPojo.status, fontSize: 10,
                             },
                         ]
                     } );
             }
             
             if(this.payReport.actionList[i].orderPojo.orderId !=0){
                 this.content.push( {
                     text: '\nWORK ORDER DETAILS' + '\n', fontSize: 12, bold: true
                 } );
                
                
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Estimation Number', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.getEstimateNumById(this.payReport.actionList[i].orderPojo.estimateId), fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Release Amount', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.payReport.actionList[i].orderPojo.releaseAmount, fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Bidder Name', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' +this.getBidderNameById(this.payReport.actionList[i].orderPojo.bidderId), fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Balance Amount', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' +this.payReport.actionList[i].orderPojo.balanceAmount, fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Deposit Amount', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' + this.payReport.actionList[i].orderPojo.depositAmount, fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Order Date', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+moment(this.payReport.actionList[i].orderPojo.orderDate).format('DD-MM-YYYY'), fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Tender Amount', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' + this.payReport.actionList[i].orderPojo.tenderAmount, fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'EE Date', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+moment(this.payReport.actionList[i].orderPojo.eeDate).format('DD-MM-YYYY'), fontSize: 10
                                 },
                             ]
                         } );
             
             }
             if(this.payReport.actionList[i].estimatePojo.estimateId !=0){
                 this.content.push( {
                     text: '\nESTIMATION DETAILS' + '\n', fontSize: 12, bold: true
                 } );
                
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Estimation Number', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.getEstimateNumById(this.payReport.actionList[i].estimatePojo.estimateId), fontSize:10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Estimated Amount', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.payReport.actionList[i].estimatePojo.estimateVaue, fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'File Name', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' +this.getWorkNameById(this.payReport.actionList[i].estimatePojo.workId), fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Estimation Date', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' +moment(this.payReport.actionList[i].estimatePojo.estimateDate).format('DD/MM/YYYY'), fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Company Name', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' + this.payReport.actionList[i].estimatePojo.estimateQuotationCompany, fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Approved By', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.payReport.actionList[i].estimatePojo.approvedBy, fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Sanction Level', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' + this.getSanctioById(this.payReport.actionList[i].estimatePojo.sanctionLevelId), fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Approved Date', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+moment(this.payReport.actionList[i].estimatePojo.approvedDate).format('DD-MM-YYYY'), fontSize: 10
                                 },
                             ]
                         } );
             
             }
             if(this.payReport.actionList[i].actionPojo.tActionId !=0){
                 this.content.push( {
                     text: '\nTENDER ACTION DETAILS' + '\n', fontSize: 12,bold: true
                 } );
                
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Tender Action Number', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.payReport.actionList[i].actionPojo.tenderActionNo, fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Bidder Name', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':'+this.getBidderNameById(this.payReport.actionList[i].actionPojo.bidderId), fontSize: 10
                                 },
                             ]
                         } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '25%',
                                     text: 'Tender Action Date ', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' +moment(this.payReport.actionList[i].actionPojo.tActionDate).format('DD-MM-YYYY'), fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: 'Action Amount', fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: ':' +this.payReport.actionList[i].actionPojo.actionAmount, fontSize: 10
                                 },
                             ]
                         } );
                 
             }
             if(this.payReport.actionList[i].agreementPojo.agreementId !=0){
                 this.content.push( {
                     text: '\nWORK AGREEMENT DETAILS' + '\n', fontSize: 12,  bold: true
                 } );
                 
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Order Number', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.getOrderNumById(this.payReport.actionList[i].agreementPojo.orderId), fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Order Date', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+moment(this.payReport.actionList[i].agreementPojo.agreementDate).format('DD-MM-YYYY'), fontSize: 10,
                             },
                         ]
                     } );
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Agreement Number ', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +this.payReport.actionList[i].agreementPojo.agreementNo, fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: '', fontSize: 12, bold: true
                             },
                             {
                                 width: '25%',
                                 text: '', fontSize: 12, 
                             },
                         ]
                     } );
             
             }
             if(this.payReport.actionList[i].progressPojo.progressId !=0){
                 this.content.push( {
                     text: '\nWORK PROGRESS DETAILS' + '\n', fontSize: 12,   bold: true
                 } );
                
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Order Number', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.getOrderNumById(this.payReport.actionList[i].progressPojo.orderId), fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Progress Number', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.payReport.actionList[i].progressPojo.progressNo, fontSize: 10,
                             },
                         ]
                     } );
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Progress Date', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +moment(this.payReport.actionList[i].progressPojo.progressDate).format('DD-MM-YYYY'), fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Progress Status', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +this.payReport.actionList[i].progressPojo.progress, fontSize: 10, 
                             },
                         ]
                     } );
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Progress Percentage', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' + this.payReport.actionList[i].progressPojo.progressPercentage +'%', fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: '', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: '', fontSize: 10
                             },
                         ]
                     } );
             }
             if(this.payReport.actionList[i].completionPojo.workCompId !=0){
                 this.content.push( {
                     text: '\nWORK COMPLETION DETAILS' + '\n', fontSize: 12, bold: true
                 } );
                
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Order Number', fontSize: 10 
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.getOrderNumById(this.payReport.actionList[i].completionPojo.orderId), fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Completion Number', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':'+this.payReport.actionList[i].completionPojo.completionNo, fontSize: 10,
                             },
                         ]
                     } );
             this.content.push(
                     {
                         columns: [
                             {
                                 width: '25%',
                                 text: 'Completion Status', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +this.payReport.actionList[i].completionPojo.status, fontSize: 10,
                             },
                             {
                                 width: '25%',
                                 text: 'Completion Date', fontSize: 10
                             },
                             {
                                 width: '25%',
                                 text: ':' +moment(this.payReport.actionList[i].completionPojo.workCompDate ).format('DD/MM/YYYY'), fontSize: 10, 
                             },
                         ]
                     } );

             }
             if(this.payReport.actionList[i].paymentList.length !=0)
             {
                 
                 this.content.push( {
                     text: '\nPAYMENT DETAILS' + '\n', fontSize: 12, bold: true
                 } );
                 this.content.push( {
                     text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                     fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
                 } );
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '10%',
                                     text: 'S.No', fontSize: 10, bold: true
                                 },
                                 {
                                     width: '40%',
                                     text: 'MBook Number', fontSize: 10, bold: true
                                 },
                                 {
                                     width: '25%',
                                     text: 'Date', fontSize: 10, bold: true
                                 },
                                 {
                                     width: '25%',
                                     text: 'Amount', fontSize: 10, bold: true
                                 },
                             ]
                         } );
                 this.content.push( {
                     text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                     fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
                 } );
                 for ( let j = 0; j < this.payReport.actionList[i].paymentList.length; j++ ) {
                 this.content.push(
                         {
                             columns: [
                                 {
                                     width: '10%',
                                     text: ( j + 1 ), fontSize: 10
                                 },
                                 {
                                     width: '40%',
                                     text:  this.payReport.actionList[i].paymentList[j].voucherNo, fontSize:10
                                 },
                                 {
                                     width: '25%',
                                     text: this.payReport.actionList[i].paymentList[j].amount, fontSize: 10
                                 },
                                 {
                                     width: '25%',
                                     text: this.payReport.actionList[i].paymentList[j].amount, fontSize: 10 
                                 },
                             ]
                         } );
                 }
                 this.content.push( {
                     text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                     fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
                 } );
             }
              
        /* For Loop Close*/
         }  
       
       /*Tendelist Close*/
       }
       
      
       
       /*QUOTATION DETAILS*/
       
       if(this.quotationFlag == true){
         
           
           for(let i=0;i< this.payReport.quotationList.length;i++){
               this.content.push( {
                   text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                   fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
               } );
               this.content.push( {
                   text: 'QUOTATION ACTION DETAILS FOR' + this.payReport.quotationList[i].qpojo.quotationNo + '\n', fontSize: 12, alignment: 'center',  bold: true
               } ); 
               
               if(this.payReport.quotationList[i].qpojo.quotationId !=0){
                   this.content.push( {
                       text: '\nQUOTATION DETAILS' + '\n', fontSize: 12,   bold: true
                   } );
                   
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Quotation Number', fontSize: 10 
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.payReport.quotationList[i].qpojo.quotationNo, fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Quotation Date', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+moment(this.payReport.quotationList[i].qpojo.quotationDate).format('DD/MM/YYYY'), fontSize: 10,
                               },
                           ]
                       } );
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Quotation Amount', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' + this.payReport.quotationList[i].qpojo.quotationAmount, fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Selection Status', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +this.payReport.quotationList[i].qpojo.selstatus, fontSize: 10, 
                               },
                           ]
                       } );
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Estimate Number', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +this.getEstimateNumById(this.payReport.quotationList[i].qpojo.estimateId), fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Bidder Name', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.getBidderNameById(this.payReport.quotationList[i].qpojo.bidderId), fontSize: 10,
                               },
                           ]
                       } );
               }
               
               if(this.payReport.quotationList[i].workPojo.workId !=0){
               this.content.push( {
                   text: '\nWORK DETAILS' + '\n', fontSize: 12,   bold: true
               } );
              
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'File Name', fontSize: 10
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.quotationList[i].workPojo.workName, fontSize: 10,
                           },
                           {
                               width: '25%',
                               text: 'File Number', fontSize: 10
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.quotationList[i].workPojo.fileNo, fontSize: 10,
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Work Type', fontSize: 10
                           },
                           {
                               width: '25%',
                               text: ':' +this.getWorkTypeById(this.payReport.quotationList[i].workPojo.workTypeId), fontSize: 10,
                           },
                           {
                               width: '25%',
                               text: 'Project Date', fontSize: 10
                           },
                           {
                               width: '25%',
                               text: ':' +moment(this.payReport.quotationList[i].workPojo.projectDate).format('DD/MM/YYYY'), fontSize: 10, 
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Scheme Type', fontSize: 10
                           },
                           {
                               width: '25%',
                               text: ':' + this.getSchemeTypeById(this.payReport.quotationList[i].workPojo.schemeTypeId), fontSize: 10,
                           },
                           {
                               width: '25%',
                               text: 'Project Status', fontSize: 10
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.quotationList[i].workPojo.status, fontSize: 10,
                           },
                       ]
                   } );
           }
           
               if(this.payReport.quotationList[i].orderPojo.orderId !=0){
                   this.content.push( {
                       text: '\nWORK ORDER DETAILS' + '\n', fontSize: 12, bold: true
                   } );
                  
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Estimation Number', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+this.getEstimateNumById(this.payReport.quotationList[i].orderPojo.estimateId), fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Release Amount', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+this.payReport.quotationList[i].orderPojo.releaseAmount, fontSize: 10
                                   },
                               ]
                           } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Bidder Name', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' +this.getBidderNameById(this.payReport.quotationList[i].orderPojo.bidderId), fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Balance Amount', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' +this.payReport.quotationList[i].orderPojo.balanceAmount, fontSize: 10
                                   },
                               ]
                           } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Deposit Amount', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' + this.payReport.quotationList[i].orderPojo.depositAmount, fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Order Date', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+moment(this.payReport.quotationList[i].orderPojo.orderDate).format('DD-MM-YYYY'), fontSize: 10
                                   },
                               ]
                           } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Tender Amount', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' + this.payReport.quotationList[i].orderPojo.tenderAmount, fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'EE Date', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+moment(this.payReport.quotationList[i].orderPojo.eeDate).format('DD-MM-YYYY'), fontSize: 10
                                   },
                               ]
                           } );
               
               }
               if(this.payReport.quotationList[i].estimatePojo.estimateId !=0){
                   this.content.push( {
                       text: '\nESTIMATION DETAILS' + '\n', fontSize: 12,   bold: true
                   } );
                   
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Estimation Number', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+this.getEstimateNumById(this.payReport.quotationList[i].estimatePojo.estimateId), fontSize:10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Estimated Amount', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+this.payReport.quotationList[i].estimatePojo.estimateVaue, fontSize: 10
                                   },
                               ]
                           } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'File Name', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' +this.getWorkNameById(this.payReport.quotationList[i].estimatePojo.workId), fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Estimation Date', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' +moment(this.payReport.quotationList[i].estimatePojo.estimateDate).format('DD/MM/YYYY'), fontSize: 10
                                   },
                               ]
                           } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Company Name', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' + this.payReport.quotationList[i].estimatePojo.estimateQuotationCompany, fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Approved By', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+this.payReport.quotationList[i].estimatePojo.approvedBy, fontSize: 10
                                   },
                               ]
                           } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '25%',
                                       text: 'Sanction Level', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':' + this.getSanctioById(this.payReport.quotationList[i].estimatePojo.sanctionLevelId), fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: 'Approved Date', fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: ':'+moment(this.payReport.quotationList[i].estimatePojo.approvedDate).format('DD-MM-YYYY'), fontSize: 10
                                   },
                               ]
                           } );
               
               }
              
               if(this.payReport.quotationList[i].agreementPojo.agreementId !=0){
                   this.content.push( {
                       text: '\nWORK AGREEMENT DETAILS' + '\n', fontSize: 12,  bold: true
                   } );
                      this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Order Number', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.getOrderNumById(this.payReport.quotationList[i].agreementPojo.orderId), fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Order Date', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+moment(this.payReport.quotationList[i].agreementPojo.agreementDate).format('DD-MM-YYYY'), fontSize: 10,
                               },
                           ]
                       } );
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Agreement Number ', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +this.payReport.quotationList[i].agreementPojo.agreementNo, fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: '', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: '', fontSize: 10, 
                               },
                           ]
                       } );
               
               }
               if(this.payReport.quotationList[i].progressPojo.progressId !=0){
                   this.content.push( {
                       text: '\nWORK PROGRESS DETAILS' + '\n', fontSize: 12,  bold: true
                   } );
                  
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Order Number', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.getOrderNumById(this.payReport.quotationList[i].progressPojo.orderId), fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Progress Number', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.payReport.quotationList[i].progressPojo.progressNo, fontSize: 10,
                               },
                           ]
                       } );
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Progress Date', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +moment(this.payReport.quotationList[i].progressPojo.progressDate).format('DD-MM-YYYY'), fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Progress Status', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +this.payReport.quotationList[i].progressPojo.progress, fontSize: 10, 
                               },
                           ]
                       } );
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Progress Percentage', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' + this.payReport.quotationList[i].progressPojo.progressPercentage +'%', fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: '', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: '', fontSize: 10,
                               },
                           ]
                       } );
               }
               if(this.payReport.quotationList[i].completionPojo.workCompId !=0){
                   this.content.push( {
                       text: '\nWORK COMPLETION DETAILS' + '\n', fontSize: 12, bold: true
                   } );
                     this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Order Number', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.getOrderNumById(this.payReport.quotationList[i].completionPojo.orderId), fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Completion Number', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':'+this.payReport.quotationList[i].completionPojo.completionNo, fontSize: 10,
                               },
                           ]
                       } );
               this.content.push(
                       {
                           columns: [
                               {
                                   width: '25%',
                                   text: 'Completion Status', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +this.payReport.quotationList[i].completionPojo.status, fontSize: 10,
                               },
                               {
                                   width: '25%',
                                   text: 'Completion Date', fontSize: 10
                               },
                               {
                                   width: '25%',
                                   text: ':' +moment(this.payReport.quotationList[i].completionPojo.workCompDate ).format('DD/MM/YYYY'), fontSize: 10, 
                               },
                           ]
                       } );

               }
               if(this.payReport.quotationList[i].paymentList.length !=0){
                   
                   this.content.push( {
                       text: '\nPAYMENT DETAILS' + '\n', fontSize: 12, bold: true
                   } );
                   this.content.push( {
                       text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                       fontSize: 10, bold: true, fontcolor: 'black'
                   } );
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '10%',
                                       text: 'S.No', fontSize: 10, bold: true
                                   },
                                   {
                                       width: '40%',
                                       text: 'MBook Number', fontSize: 10, bold: true
                                   },
                                   {
                                       width: '25%',
                                       text: 'Date', fontSize: 10, bold: true
                                   },
                                   {
                                       width: '25%',
                                       text: 'Amount', fontSize: 10, bold: true
                                   },
                               ]
                           } );
                   this.content.push( {
                       text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                       fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
                   } );
                   for ( let j = 0; j < this.payReport.quotationList[i].paymentList.length; j++ ) {
                   this.content.push(
                           {
                               columns: [
                                   {
                                       width: '10%',
                                       text: ( j + 1 ), fontSize: 10
                                   },
                                   {
                                       width: '40%',
                                       text:  this.payReport.quotationList[i].paymentList[j].voucherNo, fontSize:10
                                   },
                                   {
                                       width: '25%',
                                       text: this.payReport.quotationList[i].paymentList[j].amount, fontSize: 10
                                   },
                                   {
                                       width: '25%',
                                       text: this.payReport.quotationList[i].paymentList[j].amount, fontSize: 10 
                                   },
                               ]
                           } );
                   }
                   this.content.push( {
                       text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                       fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
                   } );
               }
          /* For Loop Close*/
           }  
         
         /*Tendelist Close*/
         }
         

      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
      } );


      const dd = {
          'content': this.content,
            
          pageOrientation: 'landscape',
          pageSize: 'A4',
          footer: ( currentPage, pageCount ) => {
              return {
                  margin: 10,
                  columns: [
                      {
                          fontSize: 9,
                          text: [
                              {
                                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                  '\n',
                                  margin: [0, 20]
                              },
                              {
                                  text: this.setting.pdfHeading + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                              }
                          ],
                          alignment: 'center'
                      }
                  ]
              };
          }
      };

      pdfMake.createPdf( dd ).open();
      this.body = [];
      this.content = [];
    }
}

