import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { TenderService } from "src/app/EngSection/WorkProgress/tender/tender.service";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-tender-list',
  templateUrl: './tender-list.component.html',
  styleUrls: ['./tender-list.component.scss']
})
export class TenderListComponent implements OnInit {
    model: NgbDateStruct;
date: { year: number, month: number };
filedate: any;
tenderdate:any;
startingdate:any;
closingdate:any;

public data;
public sortBy = 'i';
public rowsOnPage = 10;
public filterQuery = '';
public sortOrder = 'asc';

addTender =new TenderPojo();
tenderArr:TenderPojo[] = [];
estimateArr :EstimatePojo[] = [];
sanctionLevelArr: SanctionLevelMasterPojo[] = [];
workArr : WorkMasterPojo[] = [];
loggedUser = new MUserMasterPojo();

id: number;
isLoading: boolean;
  constructor(private tenderService:TenderService,private calendar: NgbCalendar,private estimateService:EstimateService,) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getEstimateAll();
      this.getTenderAll();
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderAll() {
      this.isLoading = true;
      this.tenderService.getTenderAll().subscribe( response => {
          this.tenderArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getEstimatteNumberById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
  clickEdit(item){
      this.addTender = item;   
        this.filedate = { day: moment( this.addTender.fileDate ).date(), month: moment( this.addTender.fileDate ).month()+1, year: moment( this.addTender.fileDate ).year() };
        this.tenderdate = { day: moment( this.addTender.tenderDate ).date(), month: moment( this.addTender.tenderDate ).month()+1, year: moment( this.addTender.tenderDate ).year() };
        this.startingdate = { day: moment( this.addTender.tenderOpenDate ).date(), month: moment( this.addTender.tenderOpenDate ).month()+1, year: moment( this.addTender.tenderOpenDate ).year() };
        this.closingdate = { day: moment( this.addTender.tenderCloseDate ).date(), month: moment( this.addTender.tenderCloseDate ).month()+1, year: moment( this.addTender.tenderCloseDate ).year() };
        
  }
update() {
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
    $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
    if( this.filedate != null || this.filedate != undefined){
        this.addTender.fileDate = moment( this.filedate.year + '-' + this.filedate.month + '-' + this.filedate.day, 'YYYY-MM-DD' ).toDate();
    }
    if( this.tenderdate != null || this.tenderdate != undefined){
        this.addTender.tenderDate = moment( this.tenderdate.year + '-' + this.tenderdate.month + '-' + this.tenderdate.day, 'YYYY-MM-DD' ).toDate();
    }
    if( this.startingdate != null || this.startingdate != undefined){
        this.addTender.tenderOpenDate = moment( this.startingdate.year + '-' + this.startingdate.month + '-' + this.startingdate.day, 'YYYY-MM-DD' ).toDate();
    }
    if( this.closingdate != null || this.closingdate != undefined){
        this.addTender.tenderCloseDate = moment( this.closingdate.year + '-' + this.closingdate.month + '-' + this.closingdate.day, 'YYYY-MM-DD' ).toDate();
    }
    this.addTender.userId = this.loggedUser.userId;
      this.tenderService.updateTender( this.addTender ).subscribe( response => {
          bootbox.alert('Successfully Updated');
          this.filedate =null;this.tenderdate = null;
          this.startingdate=null;this.closingdate=null;
          $( '#editModal' ).modal( 'hide' );
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        
      }, error => {
          bootbox.alert( 'Update Tender Service Error! - HTTP Status ' + error.status );
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
           } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.tenderArr.indexOf( this.tenderArr.find( response => response.tenderId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
       this.tenderService.deleteTender( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                }else if ( no == 101 ) {
                    bootbox.alert( 'You cannot Delete!This Tender Linked to SomeOther...' );
                    $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                      } else {
              this.tenderArr.splice( i, 1 );
              bootbox.alert( 'Successfully deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              this.getTenderAll();
                }
            
      }, error => {
          bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
             } );
  }
  
  reset(){
      this.addTender= new TenderPojo();
      this.filedate=null;
      this.tenderdate=null;
      this.startingdate=null;
      this.closingdate=null;
      }

}
