import { Component, OnInit } from '@angular/core';
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import * as moment from 'moment';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { TenderActionService } from "src/app/EngSection/WorkProgress/tender-action/tender-action.service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-quotation-action',
    templateUrl: './quotation-action.component.html',
    styleUrls: ['./quotation-action.component.scss']
  })
export class QuotationActionComponent implements OnInit {
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true; 
    estimateArr:EstimatePojo[]=[];
loggedUser = new MUserMasterPojo();
addQuotation = new QuotationPojo();
tenderbidderArr : TenderBidderMasterPojo[] = [];
quotationActionArr:QuotationPojo[]=[];
tempdate:any;
estimateId;
tableFlag:boolean=false;
  constructor(private estimateService:EstimateService,private quotationService: QuotationService,
          private tenderBidderMasterService: TenderBidderMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#showAfterClick' ).hide();
      this.getEstimateForTender();
      this.getTenderMasterMasterAll();
  }
  getEstimateForTender() {
      this.estimateService.getEstimateAllByQuotation().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderMasterMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  show(){
      $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
      this.estimateService.getQuotationActionByestId(this.estimateId).subscribe( response => {
          this.quotationActionArr = response;
          this.isLoading = false;
          this.tableFlag = true;
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
      }, error => {
          bootbox.alert( 'TenderAction  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
      } );
      
  } 
  getBidderNameById( id ) {
      if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
          return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
      } else {
          return id;
      }
  }
  clickEdit(item){
      this.addQuotation = item;
      if(this.addQuotation.quotationDate != null && this.addQuotation.quotationDate != undefined){
          this.tempdate = { day: moment( this.addQuotation.quotationDate ).date(), month: moment( this.addQuotation.quotationDate ).month()+1, year: moment( this.addQuotation.quotationDate ).year() };
           }
     
  }
  update() {
      if( this.tempdate != null || this.tempdate != undefined){
          this.addQuotation.quotationDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
      }
      this.addQuotation.userId = this.loggedUser.userId;
      for(let i = 0;i <this.quotationActionArr.length;i++){
      if(this.quotationActionArr[i].quotationId == this.addQuotation.quotationId){
      this.quotationActionArr[i].selstatus = true;
  }else{
      this.quotationActionArr[i].selstatus = false;
      }
  }
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.quotationService.updateQuotationList(this.quotationActionArr).subscribe( response => {
            
            $( '#editModal' ).modal( 'hide' );
            bootbox.alert('Successfully Updated');
            this.tableFlag = false;
            this.quotationActionArr =[];
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          
        }, error => {
            bootbox.alert( 'Update Tender Action Service Error! - HTTP Status ' + error.status );
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
             } );
    }

}
