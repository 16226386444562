import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
import { TenderReportPojo } from "src/app/Pojo/tender-report.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class TenderService {

    constructor( private httpclient: HttpClient ) { }
    
    getTenderAll(): Observable<TenderPojo[]> {
        const url = StaticVariable.porturl + 'TenderAll/';
        return this.httpclient.get<TenderPojo[]>( url );
    }
     addTender( arg: TenderPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddTender';
        return this.httpclient.post( url, arg );
    }

    updateTender( arg: TenderPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateTender';
        return this.httpclient.put( url, arg );
    }

    deleteTender( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteTender/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getTenderById(id): Observable<TenderPojo> {
        const url = StaticVariable.porturl + 'TenderById/'+id;
        return this.httpclient.get<TenderPojo>( url );
    }
    getTenderReportById(id): Observable<TenderReportPojo> {
        const url = StaticVariable.porturl + 'GetTenderReport/'+id;
        return this.httpclient.get<TenderReportPojo>( url );
    }
   
}
