import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import * as moment from 'moment';
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { TenderActionService } from "src/app/EngSection/WorkProgress/tender-action/tender-action.service";
import { TenderService } from "src/app/EngSection/WorkProgress/tender/tender.service";
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-tender-action-list',
    templateUrl: './tender-action-list.component.html',
    styleUrls: ['./tender-action-list.component.scss']
  })
export class TenderActionListComponent implements OnInit {
    tempdate:any;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    tenderActionArr : TenderActionPojo[] = [];
    addTender = new TenderActionPojo();
    estimateArr:EstimatePojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    tenderArr : TenderPojo[] = [];
  constructor(private tenderService:TenderService,private tenderactionService: TenderActionService,private estimateService:EstimateService,
          private tenderBidderMasterService: TenderBidderMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getTenderActionAll();
      this.getEstimateAll();
      this.getTenderMasterMasterAll();
      this.getTenderAll();
  }
  getTenderAll() {
      this.tenderService.getTenderAll().subscribe( response => {
          this.tenderArr = response;
      }, error => {
          bootbox.alert( 'Get Tender All Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderActionAll() {
      this.tenderactionService.getTenderActionAll().subscribe( response => {
          this.tenderActionArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Tender Action  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getTenderMasterMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimatteNumberById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
  getTenderNumberById( id ) {
      if ( this.tenderArr.find( r => r.tenderId == id ) ) {
          return this.tenderArr.find( r => r.tenderId == id ).tenderNo;
      } else {
          return id;
      }
  }
      getBidderNameById( id ) {
          if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
              return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
          } else {
              return id;
          }
      }
      clickEdit(item){
          this.addTender = item;
          if(this.addTender.tActionDate != null && this.addTender.tActionDate != undefined){
              this.tempdate = { day: moment( this.addTender.tActionDate ).date(), month: moment( this.addTender.tActionDate ).month()+1, year: moment( this.addTender.tActionDate ).year() };
               }
         
      }
    update() {
        if( this.tempdate != null || this.tempdate != undefined){
            this.addTender.tActionDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
        }
        this.addTender.userId = this.loggedUser.userId;
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
          $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
          this.tenderactionService.updateTenderAction( this.addTender ).subscribe( response => {
              bootbox.alert('Successfully Updated');
              $( '#editModal' ).modal( 'hide' );
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            
          }, error => {
              bootbox.alert( 'Update Tender Action Service Error! - HTTP Status ' + error.status );
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
               } );
      }

      delete( arg ) {
          this.id = arg;
      }

      deleteConfirm() {
          let no;
          const i = this.tenderActionArr.indexOf( this.tenderActionArr.find( response => response.tActionId  == this.id ) );
          $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
           this.tenderactionService.deleteTenderAction( this.id,this.loggedUser.userId ).subscribe( response => {
              no = response;
              if ( no == 0 ) {
                  bootbox.alert( 'This row is already deleted' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                    }else if ( no == 101 ) {
                        bootbox.alert( 'You cannot Delete!This TenderAction Linked to SomeOther...' );
                        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                          } else {
                  this.tenderActionArr.splice( i, 1 );
                  bootbox.alert( 'Successfully deleted' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                  $( '#deleteModal' ).modal( 'hide' );
                  this.getTenderActionAll();
                    }
                 
          }, error => {
              bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                 } );
      }
      
      reset(){
          this.addTender= new TenderActionPojo();
          this.tempdate = null;
          }
}

