import { Component, OnInit } from '@angular/core';
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import * as moment from 'moment';
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { SettingService } from "src/app/EngSection/Admin/settings/settings.service";
import { SettingPojo } from "src/app/Pojo/settings.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-quotatin-report',
  templateUrl: './quotatin-report.component.html',
  styleUrls: ['./quotatin-report.component.scss']
})
export class QuotatinReportComponent implements OnInit {
    estimateArr:EstimatePojo[] = [];
    setting = new SettingPojo();
    quotation:QuotationPojo[];
    estimateId;
    quotationFlag:boolean = false;
    resultEstimmateid;
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    body = [];
    content = [];
   documentTime: string;
    status;
  constructor(private estimateService:EstimateService,private tenderBidderMasterService: TenderBidderMasterService,private settingService:SettingService,private quotationService:QuotationService,) { }

  ngOnInit() {
      $( '#showAfterClick' ).hide();
       this.getEstimateAll();
      this.getPdfSettings();
      this.getTenderBidderMasterAll();
  }
  getPdfSettings(){
      this.settingService.getSettingsById(1).subscribe( response => {
          this.setting = response;
      }, error => {
          bootbox.alert( { message: 'Get Settings Service Error! HTTP Status ' + error.status } );
      } );
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } ); 
  }
  getEstimateNumById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getBidderNameById( id ) {
      if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
          return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
      } else {
          return id;
      }
  }
  show(){
      this.quotationFlag = false;
      this.quotation = [];
      $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
      this.quotationService.getQuotationReportById(this.estimateId).subscribe( response => {
          this.resultEstimmateid = this.estimateId;
         $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          this.quotation = response;
         
           if(this.quotation != null){
              this.quotationFlag = true;
          }
          
      }, error => {
          bootbox.alert( 'Get Report Service Error! - HTTP Status ' + error.status );
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
      } );
  }
  downloadPDF() {
      this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      this.content.push( {
          text: this.setting.pdfHeading, fontSize: 20, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: this.setting.pdfPlace + '\n', fontSize: 18, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
      } );
      this.content.push( {
          text: 'QUOTATION REPORT', fontSize: 13, alignment: 'center', bold: true
      } );

      
         this.content.push( {
             text: 'SELECTED ESTIMATION NUMBER : ' + this.getEstimateNumById(this.resultEstimmateid) + '\n', fontSize: 12, alignment: 'center',  bold: true
         } );  
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
         } );
         if(this.quotation.length !=0){
         this.content.push( {
             text: '\nQUOTATION DETAILS' + '\n', fontSize: 12,  alignment: 'center',   bold: true
         } );
         this.body.push( [
                          { text: 'S.No', fontSize: 12, alignment: 'center', style: 'tableHeader', bold: true },
                          { text: 'Quotation Number', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
                          { text: 'Quotation Date', fontSize: 12, alignment: 'center', style: 'tableHeader', bold: true },
                          { text: 'Bidder Name', fontSize: 12, alignment: 'center', style: 'tableHeader',bold: true },
                          { text: 'Quotation Amount', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
                          { text: 'Status', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
                          
                      ] );

                      for ( let i = 0; i < this.quotation.length; i++ ) {
                          if(this.quotation[i].selstatus == true){
                              this.status = 'SELECTED';
                          }if(this.quotation[i].selstatus == false){
                              this.status = 'NOT SELECTED';
                          }
                            this.body.push( [
                              { text: ( i + 1 ), fontSize: 10, alignment: 'center' },
                              { text: this.quotation[i].quotationNo, fontSize: 10, alignment: 'center' },
                              { text: moment(this.quotation[i].quotationDate).format( 'DD/MM/YYYY' ), fontSize: 10, alignment: 'center' },
                              { text: this.getBidderNameById(this.quotation[i].bidderId), fontSize: 10, alignment: 'center' },
                              { text: this.quotation[i].quotationAmount, fontSize: 10,  alignment: 'center' },
                              { text: this.status, fontSize: 10,  alignment: 'center' },
                                ] );
                      }

                      this.content.push( {
                          'table': {
                              headerRows: 1,
                              'body': this.body,
                              widths: ['5%', '25%', '15%', '30%', '15%', '10%']
                          }
                      } );
         }
                      const dd = {
                              'content': this.content,
                               /* 'content': [
                                          {qr:'1'},
                                          ],*/
                              pageOrientation: 'landscape',
                              pageSize: 'A4',
                              footer: ( currentPage, pageCount ) => {
                                  return {
                                      margin: 10,
                                      columns: [
                                          {
                                              fontSize: 9,
                                              text: [
                                                  {
                                                      text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                                      '\n',
                                                      margin: [0, 20]
                                                  },
                                                  {
                                                      text: this.setting.pdfHeading + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                                  }
                                              ],
                                              alignment: 'center'
                                          }
                                      ]
                                  };
                              }
                          };

                          pdfMake.createPdf( dd ).open();
                          this.body = [];
                          this.content = [];
                        }
}
