import { Component, OnInit } from '@angular/core';
import { StaticVariable } from "src/app/globle.class";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { Router } from '@angular/router';
import { LoginService } from "src/app/EngSection/login/login.service";

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [LoginService]
} )

export class LoginComponent implements OnInit {

    loginName: string;
    loginPlace: string;
    loggedUser = new MUserMasterPojo();
    invaliUserName = false;
    invaliPassword = false;
    constructor( private loginService: LoginService, private router: Router, ) { }

    ngOnInit() {
        this.loginName = StaticVariable.loginTitle;
        this.loginPlace = StaticVariable.loginPlace;

        ( function() {
            'use strict';
            window.addEventListener( 'load', function() {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName( 'needs-validation' );
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call( forms, function( form ) {
                    form.addEventListener( 'submit', function( event ) {
                        if ( form.checkValidity() === false ) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        form.classList.add( 'was-validated' );
                    }, false );
                } );
            }, false );
        } )();

        $( '#submitAfterClick' ).hide();
    }

    signIn( username, password ) {

        let login = new MUserMasterPojo();
        login.userName = username;
        login.password = password;
        if ( login.userName != "" && login.password != "" ) {
            ( <HTMLInputElement>document.getElementById( 'signin' ) ).disabled = true;
            $( '#submitBeforeClick' ).hide(); $( '#submitAfterClick' ).show();
            this.loginService.checkLogin( login ).subscribe( response => {
                this.loggedUser = response;
               // this.loggedUser.roleKeyJson = JSON.stringify( this.loggedUser.rolekey.roleKey );
                ( <HTMLInputElement>document.getElementById( 'signin' ) ).disabled = false;
                $( '#submitBeforeClick' ).show(); $( '#submitAfterClick' ).hide();
                if ( this.loggedUser.userName == login.userName && this.loggedUser.password == login.password ) {
                    sessionStorage.setItem( 'currentBookingSession', 'true' );
                    sessionStorage.setItem( 'loggedUser', JSON.stringify( this.loggedUser ) );

                    this.router.navigate( [`/console/changePassword`] );
                }
                 if ( this.loggedUser.password == null && this.loggedUser.userName == null) {
                     ( <HTMLInputElement>document.getElementById( 'passWord' ) ).value = '';
                     ( <HTMLInputElement>document.getElementById( 'userName' ) ).value = '';
                     bootbox.alert( { message: 'Invalid UserName and Password!', className: 'text-danger text-center', centerVertical: true, closeButton: false } );
                     sessionStorage.setItem( 'currentBookingSession', 'false' );
                 }
                 else if ( this.loggedUser.userName == null ) {
                     ( <HTMLInputElement>document.getElementById( 'userName' ) ).value = '';
                     bootbox.alert( { message: 'Invalid UserName!', className: 'text-danger text-center', centerVertical: true, closeButton: false } );
                     
                     sessionStorage.setItem( 'currentBookingSession', 'false' );
                  }else if ( this.loggedUser.password == null ) {
                     
                    
                     ( <HTMLInputElement>document.getElementById( 'passWord' ) ).value = '';
                     bootbox.alert( { message: 'Invalid Password!', className: 'text-danger text-center', centerVertical: true, closeButton: false } );
                     sessionStorage.setItem( 'currentBookingSession', 'false' );
                 }
            }, error => {
                bootbox.alert( 'Login Service Error! - HTTP Status ' + error.status );
                ( <HTMLInputElement>document.getElementById( 'signin' ) ).disabled = false;
                $( '#submitBeforeClick' ).show(); $( '#submitAfterClick' ).hide();
            } );
        }
    }

}
