import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { WorkMasterService } from "src/app/EngSection/Master/work-master/work-master.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { ShemeTypeMasterService } from "src/Services/shemme-type.service";
import { SchemeTypeMasterPojo } from "src/app/Pojo/scheme-type-master-pojo";
import { WorkTypeMasterService } from "src/Services/work-type-master.service";
import { WorkTypeMasterPojo } from "src/app/Pojo/work-type-master-pojo";
import { TimeLineStatusPojo } from 'src/app/Pojo/TimeLineStatusPojo.pojo'; 
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { SectionMasterService } from '../section-master/section-master.service';
import { SectionMasterPojo } from 'src/app/Pojo/section-master.pojo';
import { TenderBidderMasterService } from '../tender-bidder-master/tender-bidder-master.service';
import { TenderBidderMasterPojo } from 'src/app/Pojo/tender-bidder-pojo';
import { throwIfEmpty } from 'rxjs/operators';
import { Observable} from 'rxjs/Rx';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { WorktypeMasterService } from '../work-type-master/worktype-master.service';
import { StatusService} from '../../../../Services/Status.service';
import { WorkMasterTimeLinePojo } from 'src/app/Pojo/WorkMasterTimeLine.pojo';
import { WorkMasterTimeLine} from '../../../../Services/WorkMasterTimeline.Service';
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-work-master',
    templateUrl: './work-master.component.html',
    styleUrls: ['./work-master.component.scss']
  })
export class WorkMasterComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    autoClose:true;
    addProjDate: NgbDateStruct;
    entryProjDate:NgbDateStruct;
    editProjDate: NgbDateStruct;
    editEntryDate:NgbDateStruct
    editendDate:NgbDateStruct;
    editextendDate1:NgbDateStruct;
    editextendDate2:NgbDateStruct;
    addcomDate:NgbDateStruct;
    
    from;
    to;
    status;
    lablealret="";
    workamount;
    amt;
    bidderId;
    amount;
    dfrom;
    dto;
    projectDate;
    entryDate;
    errorMessage = '';
    timeline;
    
    public data;
    public sortBy = 'i';
    public rowsOnPage = 5;
    public filterQuery = '';
    public sortOrder = 'asc';
   
    
    id: number;
    isLoading: boolean = true;
    loggedUser = new MUserMasterPojo();
    workArr : WorkMasterPojo[] = [];
    addWork = new WorkMasterPojo();
    
    editWork = new WorkMasterPojo();
    timeLine= new WorkMasterTimeLinePojo();

    shemeArr : SchemeTypeMasterPojo[] = [];
    workTypeArr : WorkTypeMasterPojo[] = [];
    sectionArr : SectionMasterPojo[] = [];
    tenderbidderArr:TenderBidderMasterPojo[]=[];
    timeLinearr:WorkMasterTimeLinePojo[]=[];
    typearr:TimeLineStatusPojo[]=[];
    fromDate:string;
    ran:number=0;
    workdat = new WorkMasterPojo();
    addworktype = new WorkTypeMasterPojo();
    worktypeArr : WorkTypeMasterPojo[] = [];
    statusarr: TimeLineStatusPojo[]=[];
    timearr:WorkMasterTimeLinePojo[]=[];
    rFrom: any;
    rTo: any;
    rStatus: any;
    rbidderId: any;
    resultSlotId: any;
    dateFlag:any;
    amountFlag:string;
    bid;
    rdateFlag;
    tDate: Date;
    fDate: Date;
    typeId: number;
   
    
  constructor(private workMasterService: WorkMasterService,private schemeTypeService:ShemeTypeMasterService,
          private calendar: NgbCalendar,private workTypeMasterservice:WorkTypeMasterService,private sectionMasterService: SectionMasterService,private tenderBidderMasterService: TenderBidderMasterService,
          private worktypeMasterService: WorktypeMasterService, private statusservice: StatusService, private workmastertimeline: WorkMasterTimeLine) { }

  ngOnInit() {
   
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      this.addWork.projectDate = moment().toDate();
      
      this.addWork.entryDate=moment().toDate();
    //   this.addWork.completionDateTime=moment().toDate();
      this.fDate = moment().toDate();
      this.tDate = moment().toDate();
    //   this.editWork.completionDateTime = moment( this.editendDate.year + '-' + this.editendDate.month + '-' + this.editendDate, 'YYYY-MM-DD' ).toDate();
      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      $( '#showAfterClick' ).hide();
      $('#alret').hide();
      this.getShemeTypeAll();
      this.getWorkTypeAll();
      this.getWorkMasterAll();
      this.getSectionMasterAll();
      this.getTenderMasterMasterAll();
      this.getworktypeMaster();
      this.getStatus();

      this.status="ALL";
      this.dateFlag="ALL";
      this.bidderId=0;
      
      this.amountFlag="ALL";
      this.amount=0;
      this.dateTypeChange();
      
      this.dateType();
      this.addWork.status ="INITIAL";
      this.addWork.progressPercentage=0;
      let r =  moment().format('HH:mm');
      console.log(r);
      this.addWork.projectTime = r;
      
  }
 

 dateTypeChange(){
    if(this.dateFlag=='ALL'){
        $('#df').hide();
        $('#dt').hide();
        $('#dfe').show();
        $('#dte').show();
    }else if(this.dateFlag=='STARTDATE' || this.dateFlag=='ENDDATE'){
        $('#df').show();
        $('#dt').show();
        $('#dfe').hide();
        $('#dte').hide();
    }
 }
 dateType(){
    if(this.amountFlag=='ALL'){
        $('#am').hide();
        $('#ame').show();
    }else if(this.amountFlag=='MIN' || this.amountFlag=='MAX'|| this.amountFlag=='MIN AGREEMENT' || this.amountFlag=='MAX AGREEMENT'){
        $('#am').show();
        $('#ame').hide();
    }
 }





  getTenderMasterMasterAll() {
    this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
        this.tenderbidderArr = response;
       this.addWork.bidderId=0;
        this.isLoading = false;
    }, error => {
        bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
        this.isLoading = false;
    } );
}
getStatus(){
    this.statusservice.getStatusAll().subscribe( response => {
        this.statusarr = response;
        console.log(this.statusarr);
    }, error => {
        bootbox.alert( 'Get WorkType All Error- HTTP Status ' + error.status );
    });
}

  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll().subscribe( response => {
        this.sectionArr = response;
        this.isLoading = false;
        if(this.sectionArr.length>0)
          this.addWork.sectionId = this.sectionArr[0].sectionId;
    }, error => {
        bootbox.alert( 'Get Section All Error! - HTTP Status ' + error.status );
        this.isLoading = false;
    } );
}
  getWorkMasterAll() {
      this.workMasterService.getWorkMasterAll().subscribe( response => {
          this.workArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Work Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getShemeTypeAll() {
      this.schemeTypeService.getSchemeTypeAll().subscribe( response => {
          this.shemeArr = response;
          if(this.shemeArr.length>0)
          this.addWork.schemeTypeId = this.shemeArr[0].schemeTypeId;
      }, error => {
          bootbox.alert( 'Get ShemeType All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkTypeAll() {
      this.workTypeMasterservice.getWorkTypeAll().subscribe( response => {
          this.workTypeArr = response;
          if(this.workTypeArr.length>0)
          this.addWork.workTypeId = this.workTypeArr[0].workTypeId;
      }, error => {
          bootbox.alert( 'Get WorkType All Error! - HTTP Status ' + error.status );
      });
  }
  getWorkTypeById( id ) {
      if ( this.workTypeArr.find( r => r.workTypeId == id ) ) {
          return this.workTypeArr.find( r => r.workTypeId == id ).workType;
      } else {
          return id;
      }
      
  }
  getSchemeTypeById( id ) {
      if ( this.shemeArr.find( r => r.schemeTypeId == id ) ) {
          return this.shemeArr.find( r => r.schemeTypeId == id ).schemeType;
      } else {
          return id;
      }
  }

 
 
          //NEWWW

        //   minmax()
          
        //   {
        //     if(this.from != '' && this.from != undefined && this.to != ''&& this.to != undefined){
        //         if(this.workamount != null && this.workamount!= undefined && this.workamount != ''){
                    
               
        //     $( '#showBeforesClick' ).hide(); $( '#showAftersClick' ).show();
        //     this.workArr = [];
        //     this.isLoading = true;
        //     let amt = this.amt > this.workamount;
        //     // let to = moment( this.to.year + '-' + this.to.month + '-' + this.to.day).format('YYYY-MM-DD');
        //     this.workMasterService.getWorkMasterByamount(amt, this.workamount).subscribe( response => {
        //         this.workArr = response;
        //           this.isLoading = false;
        //         $( '#showBeforesClick' ).show(); $( '#showAftersClick' ).hide();
                
        //     }, error => {
        //         bootbox.alert( 'Get Progress Report Error! - HTTP Status ' + error.status );
        //         $( '#showBeforesClick' ).show(); $( '#showAftersClick' ).hide();
        //         this.isLoading = false;
                
        //     } );
        //     }
        //         else{
        //         bootbox.alert('Please select the Progress Status');
        //     }
        //     }else{
        //         bootbox.alert('Please Select the From and To Date');
        //     }
        // }
// dpro(){
//       if(this.dfrom != '' && this.dfrom != undefined && this.dto != ''&& this.dto != undefined){
//           if(this.projectDate != null && this.projectDate!= undefined && this.projectDate != '') {
              
         
//       $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
//       this.workdat = [];
//       this.isLoading = true;
//       let from = moment( this.dfrom.year + '-' + this.dfrom.month + '-' + this.dfrom.day).format('YYYY-MM-DD');
//       let to = moment( this.dto.year + '-' + this.dto.month + '-' + this.dto.day).format('YYYY-MM-DD');
//       this.workMasterService.getWorkMaster(from, to, this.projectDate).subscribe( response => {
//           this.workdat = response;
//             this.isLoading = false;
//           $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          
//       }, error => {
//           bootbox.alert( 'Get Progress Report Error! - HTTP Status ' + error.projectDate );
//           $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
//           this.isLoading = false;
          
//       } );
//       }
//           else{
//           bootbox.alert('Please select the Progress Status');
//       }
//       }else{
//           bootbox.alert('Please Select the From and To Date');
//       }
//   }

getFetch() {
    $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
    this.isLoading = true;
    this.from = moment($('#df').val()).format('YYYY-MM-DD');
    this.to = moment($('#dt').val()).format('YYYY-MM-DD');
    if ((moment(this.from).diff(moment(this.to), 'days') <= 0)) {
        this.workMasterService.getWorkMaster(this.from,this.to,this.status,this.amountFlag,this.amount,this.dateFlag,this.bidderId).subscribe(response => {
            
            this.rFrom = this.from;
            this.rTo = this.to;
            this.rStatus = this.status;
            this.rbidderId = this.bidderId;
            this.rdateFlag=this.dateFlag;
            this.workArr = response;
            this.isLoading=false;
            $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();     
}, error => {
    bootbox.alert( 'Add Work Service Error! - HTTP Status ' + error.status );
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
    $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
}
 );
}
}



        // end
//   show(){
//       if(this.dfrom != '' && this.dfrom != undefined && this.dto != ''&& this.dto != undefined){
//           if(this.status != null && this.status!= undefined && this.status != ''){
              
         
//       $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
//       this.workArr = [];
//       this.isLoading = true;
//       let from = moment( this.dfrom.year + '-' + this.dfrom.month + '-' + this.dfrom.day).format('YYYY-MM-DD');
//       let to = moment( this.dto.year + '-' + this.dto.month + '-' + this.dto.day).format('YYYY-MM-DD');
//       this.workMasterService.getWorkMasterByDateAndStatus(from, to, this.status).subscribe( response => {
//           this.workArr = response;
//             this.isLoading = false;
//           $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          
//       }, error => {
//           bootbox.alert( 'Get Progress Report Error! - HTTP Status ' + error.status );
//           $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
//           this.isLoading = false;
          
//       } );
//       }
//           else{
//           bootbox.alert('Please select the Progress Status');
//       }
//       }else{
//           bootbox.alert('Please Select the From and To Date');
//       }
//   }
  reset(){
              this.addWork.fileNo="";
              this.addWork.workName="";
              this.addWork.workDetails="";
              this.addWork.amount=0;
              this.addWork.progressPercentage=0;
              this.addWork.duration=0;
  }
  addWorkMaster() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      if(this.addProjDate != null && this.addProjDate != undefined){
          this.addWork.projectDate = moment( this.addProjDate.year + '-' + this.addProjDate.month + '-' + this.addProjDate.day, 'YYYY-MM-DD' ).toDate();
          }
          if(this.entryProjDate != null && this.entryProjDate != undefined){
            this.addWork.entryDate = moment( this.entryProjDate.year + '-' + this.entryProjDate.month + '-' + this.entryProjDate, 'YYYY-MM-DD' ).toDate();
            }
            if(this.addWork.projectDate!= null && this.addWork.projectDate != undefined){
                this.addWork.completionDateTime = moment(this.addWork.projectDate).add(this.addWork.duration,'M').toDate();
                
                }
      this.addWork.userId = this.loggedUser.userId;
      this.workMasterService.addWorkMaster( this.addWork ).subscribe( response => {
          if(response.code === 101)
          {
              bootbox.alert( 'Successfully Added');
              this.addWork.fileNo="";
              this.addWork.workName="";
              this.addWork.workDetails="";
              this.addWork.amount=0;
              this.addWork.progressPercentage=0;
              this.addWork.duration=0;
              this.addWork.agreementAmount=0;
              this.addWork.paidAmount=0;
              this.addWork.bidderId=0;
              
              
             
           
        //    ( <HTMLFormElement>document.getElementById( 'addWorkForm' ) ).reset();
           $('#addModal').modal('hide');
          this.getWorkMasterAll();
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }
          else if(response.code === 102)
          {
          bootbox.alert( response.response);
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }
         
          
      }, error => {
          bootbox.alert( 'Add Work Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
  
  clickEdit( arg ) {
      this.editWork = Object.assign( {}, arg );
      if(this.editWork.projectDate != null && this.editWork.projectDate != undefined){
          this.editProjDate = { day: moment( this.editWork.projectDate ).date(), month: moment( this.editWork.projectDate ).month()+1, year: moment( this.editWork.projectDate ).year() };
           }
           if(this.editWork.entryDate != null && this.editWork.entryDate != undefined){
            this.editEntryDate = { day: moment( this.editWork.entryDate ).date(), month: moment( this.editWork.entryDate ).month()+1, year: moment( this.editWork.entryDate ).year() };
             }
           
           if(this.editWork.completionDateTime != null && this.editWork.completionDateTime != undefined){
            this.editendDate = { day: moment( this.editWork.completionDateTime ).date(), month: moment( this.editWork.completionDateTime ).month()+1, year: moment( this.editWork.completionDateTime ).year() };
             }
             else{ (this.editWork.completionDateTime == null)
                this.editendDate = { day: moment( this.editWork.completionDateTime ).date(), month: moment( this.editWork.completionDateTime ).month()+1, year: moment( this.editWork.completionDateTime ).year() };
            
             }
             if(this.editWork.extendDate1 != null && this.editWork.extendDate1 != undefined){
                this.editextendDate1 = { day: moment( this.editWork.extendDate1 ).date(), month: moment( this.editWork.extendDate1).month()+1, year: moment( this.editWork.extendDate1 ).year() };
                 }
                 else{ (this.editWork.extendDate1 == null)
                    this.editextendDate1 = { day: moment( this.editWork.extendDate1 ).date(), month: moment( this.editWork.extendDate1 ).month()+1, year: moment( this.editWork.extendDate1 ).year() };
                
                 }
                 if(this.editWork.extendDate2 != null && this.editWork.extendDate2 != undefined){
                    this.editextendDate2 = { day: moment( this.editWork.extendDate2 ).date(), month: moment( this.editWork.extendDate2).month()+1, year: moment( this.editWork.extendDate2 ).year() };
                     }
                     else{ (this.editWork.extendDate2 == null)
                        this.editextendDate2 = { day: moment( this.editWork.extendDate2 ).date(), month: moment( this.editWork.extendDate2 ).month()+1, year: moment( this.editWork.extendDate2 ).year() };
                    
                     }  
                     this.timeLine.timeLineDate=moment().format('YYYY-MM-DD');   
                     this.getTimeLineDetailsById(this.editWork.workId);
       }

       getTimeLineDetailsById(id){
        this.workmastertimeline.getTimelineDetailsById(id).subscribe(res=>{
            this.timeLinearr=res;
            this.typeId=this.timeLine.timeLineTypeId;
            console.log(this.timeLinearr)
        })
       }




       addTimeLine(){
        this.timeLine.workId=this.editWork.workId;
        this.timeLine.timeLineTypeId=this.typeId;
        this.workmastertimeline.addTimeLineStatus( this.timeLine ).subscribe( response => {
               
                this.timeLine.timeLineTypeId=null;

                this.timeLine.timeLineDetails="";
                this.getTimeLineDetailsById(this.editWork.workId);
                $('#alret').show();
                setTimeout(()=> $('#alret').hide(),2500)
            
        }, error => {
            bootbox.alert( 'Add Work Service Error! - HTTP Status ' + error.status );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

  editWorkMaster() {
    if(this.editendDate != null && this.editendDate != undefined){
        this.editWork.completionDateTime = moment( this.editendDate.year + '-' + this.editendDate.month + '-' + this.editendDate.day, 'YYYY-MM-DD' ).toDate();
        }
        if(this.editextendDate1 != null && this.editextendDate1 != undefined){
            this.editWork.extendDate1 = moment( this.editextendDate1.year + '-' + this.editextendDate1.month + '-' + this.editextendDate1.day, 'YYYY-MM-DD' ).toDate();
            }
            if(this.editextendDate2 != null && this.editextendDate2 != undefined){
                this.editWork.extendDate2 = moment( this.editextendDate2.year + '-' + this.editextendDate2.month + '-' + this.editextendDate2.day, 'YYYY-MM-DD' ).toDate();
                }
      if(this.editProjDate != null && this.editProjDate != undefined){
          this.editWork.projectDate = moment( this.editProjDate.year + '-' + this.editProjDate.month + '-' + this.editProjDate.day, 'YYYY-MM-DD' ).toDate();
          }
          if(this.editEntryDate != null && this.editEntryDate != undefined){
            this.editWork.entryDate = moment( this.editEntryDate.year + '-' + this.editEntryDate.month + '-' + this.editEntryDate.day, 'YYYY-MM-DD' ).toDate();
            }
            if(this.editWork.projectDate!= null && this.editWork.projectDate != undefined){
                this.editWork.completionDateTime = moment(this.editWork.projectDate).add(this.editWork.duration,'M').toDate();
                }
          
      this.editWork.userId = this.loggedUser.userId;
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.workMasterService.updateWorkMaster( this.editWork ).subscribe( response => {
          if(response.code === 101)
          {
              bootbox.alert( 'Successfully Updated');
          $( '#editModal' ).modal( 'hide' );
          this.getWorkMasterAll();
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          }
          else if(response.code === 102)
          {
          bootbox.alert( response.response);
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          }
          
      }, error => {
          bootbox.alert( 'Update Work Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );

  }

  delete( arg ) {
      this.id = arg;

  }

  deleteConfirm() {
      let no;
      const i = this.workArr.indexOf( this.workArr.find( response => response.workId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.workMasterService.deleteWorkMaster( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }else  if ( no == 101 ) {
              bootbox.alert( 'You cannot Delete!This Work Master Linked to Some Other...' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.workArr.splice( i, 1 );
              bootbox.alert( 'Successfully deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              this.getWorkMasterAll();
        
          }
           }, error => {
          bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }

  getworktypeMaster() {
    this.worktypeMasterService.getworktypeMasterAll().subscribe( response => {
        this.worktypeArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( 'Get Section All Error! - HTTP Status ' + error.status );
        this.isLoading = false;
    } );
}
add() {
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
    $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
     this.worktypeMasterService.addWorktypeMaster( this.addworktype ).subscribe( response => {
      $( '#editWorkType' ).modal( 'hide' );
      this.getworktypeMaster();
         bootbox.alert('Successfully Added');
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            this.getWorkTypeAll();
    }, error => {
        bootbox.alert( 'Add  Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
    } );
    this.addworktype.workType="";
    this.getworktypeMaster();
}

}
