import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import * as moment from 'moment';
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { TenderActionService } from "src/app/EngSection/WorkProgress/tender-action/tender-action.service";
import { TenderService } from "src/app/EngSection/WorkProgress/tender/tender.service";
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-tender-action',
    templateUrl: './tender-action.component.html',
    styleUrls: ['./tender-action.component.scss']
  })
export class TenderActionEntryComponent implements OnInit {
    tempdate: any;

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    tenderArr : TenderPojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    addTender = new TenderActionPojo();
    editTender = new TenderActionPojo();
    estimateArr: EstimatePojo[]=[];
    
  constructor(private tenderService:TenderService,private tenderactionService: TenderActionService,private tenderBidderMasterService: TenderBidderMasterService,private estimateService:EstimateService) { }

  ngOnInit() {
      
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
      this.getTenderAll();
      this.getTenderBidderMasterAll();
      this.getEstimateAll();
  }
  noEnter( e ) {
      return !( window.event && e.keyCode == 13 );
  }
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }

  getEstimateAll() {
      this.isLoading = true;
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Estimate All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  
  getTenderAll() {
      this.tenderService.getTenderAll().subscribe( response => {
          this.tenderArr = response;
      }, error => {
          bootbox.alert( 'Get Tender All Error! - HTTP Status ' + error.status );
      } );
  }
 
  addTenderAction() {
      
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      if( this.tempdate != null || this.tempdate != undefined){
          this.addTender.tActionDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
      }
      this.addTender.userId = this.loggedUser.userId;
      this.addTender.selStatus = false;
      this.tenderactionService.addTenderAction( this.addTender ).subscribe( response => {
             bootbox.alert('Successfully Added');
             ( <HTMLFormElement>document.getElementById( 'addTenderForm' ) ).reset();
              this.reset();
           $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }, error => {
          bootbox.alert( 'Add TenderAction Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
 
  reset(){
      this.addTender= new TenderActionPojo();
      this.tempdate = null;
      }
}
