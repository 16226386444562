import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo, ManagementRolePojo } from "src/app/pojo/management-user-pojo";
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { LoggedUserDetailsPojo } from "src/app/pojo/logged-user-details-pojo";
import { RoleMasterService } from "src/app/EngSection/Admin/role-master/role-master.service";
import { UserCreationService } from "src/app/EngSection/Admin/user-creation/user-creation.service";

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-user-creation',
    templateUrl: './user-creation.component.html',
    styleUrls: ['./user-creation.component.scss'],
    providers: [RoleMasterService, UserCreationService]
} )

export class UserCreationComponent implements OnInit {

    userCreationArr: MUserMasterPojo[] = [];
    userCreationSingle = new MUserMasterPojo();
    roles: MRolePojo[];
    editUser = new MUserMasterPojo();
    addUser = new MUserMasterPojo();
    loggedUser = new MUserMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private roleMasterService: RoleMasterService, private userCreationService: UserCreationService) { }

    ngOnInit() {
        
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
        
        this.getRoleAll();
        this.getManagementUserAll();

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        $( '#checkSpinner' ).hide();
    }

    getRoleAll() {
        this.roleMasterService.getMRoleAll().subscribe( response => {
            this.roles = response;
        }, error => {
            bootbox.alert( 'Get M Role All Service Error! - HTTP Status ' + error.status );
        } );
    }


    getManagementUserAll() {
        this.userCreationService.getManagementUser().subscribe( response => {
            this.userCreationArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( 'Get M User Master All Service Error! - HTTP Status ' + error.status );
            this.isLoading = false;
        } );
    }

    getRoleKeyByRoleId( id ) {
        if ( this.roles.find( r => r.roleId == id ) ) {
            return this.roles.find( r => r.roleId == id ).roleKey;
        } else {
            return id;
        }
    }
    
    checkUserName( userName ) {
        if ( userName != "" ) {
            this.userCreationService.checkUserName( userName ).subscribe( response => {
                if ( response ) {
                     } else {
                    alert( "Username Already exist.." );
                       }
            }, error => {
                alert( 'Check User Name Service Error! - HTTP Status ' + error.status );
                    } );
        }
    }

     phoneNumValidationForAdd( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'addMobile' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
        }
    }
    
    phoneNumValidationForEdit( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'editMobile' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
        }
    }

    insertUser() {
       this.addUser.roleId = +( <HTMLFormElement>document.getElementById( 'roleId' ) ).value;
        this.addUser.roleKey.roleKey = this.getRoleKeyByRoleId( this.addUser.roleId );
        this.addUser.userId = this.loggedUser.userId;
        this.addUser.companyId = 1;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
          this.userCreationService.addManagementUser( this.addUser ).subscribe( response => {
            bootbox.alert( 'User has been created successfully.' );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getManagementUserAll();
        }, error => {
            bootbox.alert( 'Add M User Master Service Error! - HTTP Status ' + error.status );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.id = arg.userId;
        this.editUser = arg;
         }
    
    updateUser() {
        let no;
        const i = this.userCreationArr.indexOf( this.userCreationArr.find( r => r.userId == this.id ) );
        this.editUser.userId = this.userCreationArr[i].userId;
        this.editUser.roleId = ( <HTMLFormElement>document.getElementById( 'editroleId' ) ).value;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.userCreationService.updateManagementUser( this.editUser ).subscribe( response => {
            no = response;
            bootbox.alert( 'User has been updated.' );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getManagementUserAll();
        }, error => {
            bootbox.alert( 'Update M User Master Service Error! - HTTP Status ' + error.status );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }
    
    deleteConfirm() {
        let no;
        const i = this.userCreationArr.indexOf( this.userCreationArr.find( r => r.userId == this.id ) );
        this.userCreationSingle = this.userCreationArr[i];
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.userCreationService.deleteManagementUser( this.id, 1 ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( 'This row already deleted!' );
                this.userCreationArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.userCreationArr.splice( i, 1 );
                bootbox.alert('Deleted');
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getManagementUserAll();
        }, error => {
            bootbox.alert( 'Delete M User Master Service Error! - HTTP Status ' + error.status );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
   
}