import { Component, OnInit } from '@angular/core';
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { ManagementRolePojo, MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { RoleMasterService } from "src/app/EngSection/Admin/role-master/role-master.service";
import { ResponsePojo } from "src/app/Pojo/response.pojo";
declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-role-master',
    templateUrl: './role-master.component.html',
    styleUrls: ['./role-master.component.scss'],
    providers: [RoleMasterService]
} )

export class RoleMasterComponent implements OnInit {

    roleMasterArr: MRolePojo[] = [];
  roleMaster = new MRolePojo();
    addRoleFun = new ManagementRolePojo();
    editRoleFun = new ManagementRolePojo();
    loggedUser = new MUserMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean;

    constructor( private roleMasterService: RoleMasterService ) { }

    ngOnInit() {

        this.getMRoleAll();
        $( '#addAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    }

    getMRoleAll() {
        this.isLoading = true;
        this.roleMasterService.getMRoleAll().subscribe( response => {
            this.roleMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( 'M Role All Service Error! - HTTP Status ' + error.status );
            this.isLoading = false;
        } );
    }

    onChangeAdmin( event ) {
        if ( ( <HTMLInputElement>document.getElementById( 'chkAdmin' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkRoleMaster' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkUsercreation' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkBackup' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkSettings' ) ).checked = true;
               
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkAdmin' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkRoleMaster' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkUsercreation' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkBackup' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkSettings' ) ).checked = false;
             
              }
    }
    onChangeMaster( event ) {
        if ( ( <HTMLInputElement>document.getElementById( 'chkMaster' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkReceiptType' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTenderBidder' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkWorkMaster' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkSection' ) ).checked = true;
              
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkAdmin' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkReceiptType' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTenderBidder' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkWorkMaster' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkSection' ) ).checked = false;
            
              }
    }
    onChangeWorkProgress( event ) {
        if ( ( <HTMLInputElement>document.getElementById( 'chkWorkProgress' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkEstimation' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkQuotation' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTender' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkReceipt' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTederActionEntry' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTenderBidderDocument' ) ).checked = true;
              
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkWorkProgress' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkEstimation' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkQuotation' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTender' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkReceipt' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTederActionEntry' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTenderBidderDocument' ) ).checked = false;
            
              }
    }
    onChangeEntryList( event ) {
        if ( ( <HTMLInputElement>document.getElementById( 'chkEntryList' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkEstimationList' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkQuotationList' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTenderList' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkReceiptList' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTenderActionList' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTenderBidderDocumentList' ) ).checked = true;
              
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkEntryList' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkEstimationList' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkQuotationList' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTenderList' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkReceiptList' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTenderActionList' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTenderBidderDocumentList' ) ).checked = false;
             
              }
    }
    onChangeWork( event ) {
        if ( ( <HTMLInputElement>document.getElementById( 'chkWork' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkWorkOrder' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkAgreement' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkWorkProgress' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkPaymentProgress' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkWorkCompletion' ) ).checked = true;
              
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkWork' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkWorkOrder' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkAgreement' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkWorkProgress' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkPaymentProgress' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkWorkCompletion' ) ).checked = false;
             
              }
    }
    onChangeAction(event){
        if ( ( <HTMLInputElement>document.getElementById( 'chkAction' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkTenderAction' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkQuotationAction' ) ).checked = true;
              
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkAction' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkTenderAction' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkQuotationAction' ) ).checked = false;
              }
    }
    onChangeReport(event){
        if ( ( <HTMLInputElement>document.getElementById( 'chkReport' ) ).checked ) {
            ( <HTMLInputElement>document.getElementById( 'chkProgressReport' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkPaymentReport' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkBidderReport' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkTenderReport' ) ).checked = true;
            ( <HTMLInputElement>document.getElementById( 'chkQuotationReport' ) ).checked = true;
                
              } else if ( ( <HTMLInputElement>document.getElementById( 'chkReport' ) ).checked == false ) {
            ( <HTMLInputElement>document.getElementById( 'chkProgressReport' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkPaymentReport' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkBidderReport' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkTenderReport' ) ).checked = false;
            ( <HTMLInputElement>document.getElementById( 'chkQuotationReport' ) ).checked = false;
           
              }
    }
             
    addRole() {
        if ( ( <HTMLInputElement>document.getElementById( 'chkRoleMaster' ) ).checked ) this.addRoleFun.rolemaster = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkUsercreation' ) ).checked ) this.addRoleFun.usercreation = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkBackup' ) ).checked ) this.addRoleFun.backup = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkReceiptType' ) ).checked ) this.addRoleFun.receipttype = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderBidder' ) ).checked ) this.addRoleFun.tenderbidder = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkWorkMaster' ) ).checked ) this.addRoleFun.workmaster = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkEstimation' ) ).checked ) this.addRoleFun.estimation = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkQuotation' ) ).checked ) this.addRoleFun.quotation = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTender' ) ).checked ) this.addRoleFun.tender = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkEstimationList' ) ).checked ) this.addRoleFun.estimationlist = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkQuotationList' ) ).checked ) this.addRoleFun.quotationlist = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderList' ) ).checked ) this.addRoleFun.tenderlist = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkReceipt' ) ).checked ) this.addRoleFun.receipt = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkReceiptList' ) ).checked ) this.addRoleFun.receiptlist = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderAction' ) ).checked ) this.addRoleFun.tenderaction = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkQuotationAction' ) ).checked ) this.addRoleFun.quotationaction = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTederActionEntry' ) ).checked ) this.addRoleFun.tenderactionentry = true;
       
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderBidderDocument' ) ).checked ) this.addRoleFun.tenderbidderdoc = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderActionList' ) ).checked ) this.addRoleFun.tenderactionlist = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderBidderDocumentList' ) ).checked ) this.addRoleFun.tenderbidderdoclist = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkWorkOrder' ) ).checked ) this.addRoleFun.workorder = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkAgreement' ) ).checked ) this.addRoleFun.agreement = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkWorkProgress' ) ).checked ) this.addRoleFun.workProgress = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkPaymentProgress' ) ).checked ) this.addRoleFun.paymentprogress = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkWorkCompletion' ) ).checked ) this.addRoleFun.workcompletion = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkProgressReport' ) ).checked ) this.addRoleFun.progressreport = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkPaymentReport' ) ).checked ) this.addRoleFun.paymentreport = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkBidderReport' ) ).checked ) this.addRoleFun.bidderreport = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkSettings' ) ).checked ) this.addRoleFun.settings = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkTenderReport' ) ).checked ) this.addRoleFun.tenderreport = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkQuotationReport' ) ).checked ) this.addRoleFun.quotationreport = true;
        if ( ( <HTMLInputElement>document.getElementById( 'chkSection' ) ).checked ) this.addRoleFun.sectionmaster = true;
         
        if ( this.addRoleFun.receipttype || this.addRoleFun.tenderbidder || this.addRoleFun.sectionmaster || this.addRoleFun.workmaster) {
            this.addRoleFun.master =  true;
        }
        if ( this.addRoleFun.rolemaster || this.addRoleFun.usercreation || this.addRoleFun.backup|| this.addRoleFun.settings) {
            this.addRoleFun.admin =  true;
        }if ( this.addRoleFun.estimation || this.addRoleFun.quotation || this.addRoleFun.tender|| this.addRoleFun.receipt|| this.addRoleFun.tenderactionentry|| this.addRoleFun.tenderbidderdoc) {
            this.addRoleFun.workprogress =  true;
        }
        if ( this.addRoleFun.workorder || this.addRoleFun.workcompletion || this.addRoleFun.workProgress|| this.addRoleFun.paymentprogress|| this.addRoleFun.agreement) {
            this.addRoleFun.work =  true;
        }
        if ( this.addRoleFun.estimationlist || this.addRoleFun.quotationlist || this.addRoleFun.tenderlist|| this.addRoleFun.receiptlist|| this.addRoleFun.tenderactionlist|| this.addRoleFun.tenderbidderdoclist) {
            this.addRoleFun.entrylist =  true;
        }if ( this.addRoleFun.tenderaction || this.addRoleFun.quotationaction) {
            this.addRoleFun.action =  true;
        }if ( this.addRoleFun.progressreport ==  true || this.addRoleFun.paymentreport ==  true || this.addRoleFun.bidderreport ==  true
                || this.addRoleFun.tenderreport ==  true || this.addRoleFun.quotationreport ==  true) {
            this.addRoleFun.report =  true;
        }
         $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
       ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        let d = new MRolePojo();
        d.userId = this.loggedUser.userId;
        d.userRole = ( <HTMLInputElement>document.getElementById( 'roleName' ) ).value;
        d.roleKey = JSON.stringify( this.addRoleFun );
        this.roleMasterService.addMRole( d ).subscribe( response => {
            bootbox.alert( 'Successfully Added' );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addRole' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getMRoleAll();
        }, error => {
            bootbox.alert( 'Add M Role Service Error! - HTTP Status ' + error.status );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickupdate( arg ) {
        this.id = arg;
        const i = this.roleMasterArr.indexOf( this.roleMasterArr.find( r => r.roleId == this.id ) );
        ( <HTMLFormElement>document.getElementById( 'editRoleName' ) ).value = this.roleMasterArr[i].userRole;
        this.editRoleFun = JSON.parse( this.roleMasterArr[i].roleKey );
    }

    updateRole() {
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditReceiptTypeMaster' ) ).checked ) {
            this.editRoleFun.receipttype = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditTenderBidderMaster' ) ).checked ) {
            this.editRoleFun.tenderbidder = true;
        }
       
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditWorkMaster' ) ).checked ) {
            this.editRoleFun.workmaster = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditRoleMaster' ) ).checked ) {
            this.editRoleFun.rolemaster = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditUsercreation' ) ).checked ) {
            this.editRoleFun.usercreation = true;
        }
       
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditBackup' ) ).checked ) {
            this.editRoleFun.backup = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditEstimation' ) ).checked ) {
            this.editRoleFun.estimation = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditQuotation' ) ).checked ) {
            this.editRoleFun.quotation = true;
        }
       
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditTender' ) ).checked ) {
            this.editRoleFun.tender = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditEstimationList' ) ).checked ) {
            this.editRoleFun.estimationlist = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditQuotationList' ) ).checked ) {
            this.editRoleFun.quotationlist = true;
        }
       
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditTenderList' ) ).checked ) {
            this.editRoleFun.tenderlist = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditReceipt' ) ).checked ) {
            this.editRoleFun.receipt = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditReceiptList' ) ).checked ) {
            this.editRoleFun.receiptlist = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditTenderAction' ) ).checked ) {
            this.editRoleFun.tenderaction = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditTenderBidderDocument' ) ).checked ) {
            this.editRoleFun.tenderbidderdoc = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditTenderActionList' ) ).checked ) {
            this.editRoleFun.tenderactionlist = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditTenderBidderDocumentList' ) ).checked ) {
            this.editRoleFun.tenderbidderdoclist = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditWorkOrder' ) ).checked ) {
            this.editRoleFun.workorder = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditAgreement' ) ).checked ) {
            this.editRoleFun.agreement = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditWorkProgress' ) ).checked ) {
            this.editRoleFun.workProgress = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditPaymentProgress' ) ).checked ) {
            this.editRoleFun.paymentprogress = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditWorkCompletion' ) ).checked ) {
            this.editRoleFun.workcompletion = true;
        }
       
        if ( ( <HTMLInputElement>document.getElementById( 'chkeditQuotationAction' ) ).checked ) {
            this.editRoleFun.tenderaction = true;
        } if ( ( <HTMLInputElement>document.getElementById( 'chkeditTederActionEntry' ) ).checked ) {
            this.editRoleFun.tenderactionentry = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'editchkProgressReport' ) ).checked ) {
            this.editRoleFun.progressreport = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'editchkPaymentReport' ) ).checked ) {
            this.editRoleFun.paymentreport = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'editchkBidderReport' ) ).checked ) {
            this.editRoleFun.bidderreport = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditSettings' ) ).checked ) {
            this.editRoleFun.settings = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'editchkTenderReport' ) ).checked ) {
            this.editRoleFun.tenderreport = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'editchkQuotationReport' ) ).checked ) {
            this.editRoleFun.quotationreport = true;
        }
        if ( ( <HTMLInputElement>document.getElementById( 'chkEditSectionMaster' ) ).checked ) {
            this.editRoleFun.sectionmaster = true;
        }
       
        if ( this.editRoleFun.receipttype ||  this.editRoleFun.tenderbidder == true||  this.editRoleFun.sectionmaster == true ||  this.editRoleFun.workmaster == true ) {
            this.editRoleFun.master = true;
        }
        if ( this.editRoleFun.rolemaster ||  this.editRoleFun.usercreation == true ||  this.editRoleFun.backup == true ||  this.editRoleFun.settings == true ) {
            this.editRoleFun.admin = true;
        }
        if ( this.editRoleFun.estimation ||  this.editRoleFun.quotation == true ||  this.editRoleFun.tender == true ||  this.editRoleFun.receipt == true ||  this.editRoleFun.tenderactionentry == true ||  this.editRoleFun.tenderbidderdoc == true ) {
            this.editRoleFun.workprogress = true;
        }
        if ( this.editRoleFun.estimationlist ||  this.editRoleFun.quotationlist == true ||  this.editRoleFun.tenderlist == true ||  this.editRoleFun.receiptlist == true||  this.editRoleFun.tenderactionlist == true ||  this.editRoleFun.tenderbidderdoclist == true ) {
            this.editRoleFun.entrylist = true;
        }
        if ( this.editRoleFun.workorder ||  this.editRoleFun.workProgress == true ||  this.editRoleFun.agreement == true ||  this.editRoleFun.paymentprogress == true||  this.editRoleFun.workcompletion == true ) {
            this.editRoleFun.work = true;
        }
        if ( this.editRoleFun.tenderaction ||  this.editRoleFun.quotationaction == true  ) {
            this.editRoleFun.action = true;
        } if ( this.editRoleFun.progressreport == true || this.editRoleFun.paymentreport == true || this.editRoleFun.bidderreport == true
                || this.editRoleFun.tenderreport== true || this.editRoleFun.quotationreport == true) {
            this.editRoleFun.report = true;
        }
       $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        let d = new MRolePojo();
        const i = this.roleMasterArr.indexOf( this.roleMasterArr.find( r => r.roleId == this.id ) );
        d.roleId = this.roleMasterArr[i].roleId;
        d.userRole = ( <HTMLFormElement>document.getElementById( 'editRoleName' ) ).value;
        d.roleKey = JSON.stringify( this.editRoleFun );
        d.userId = this.loggedUser.userId;
        this.roleMasterService.updateMRole( d ).subscribe( response => {
            bootbox.alert( 'Successfully Updated' );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getMRoleAll();
        }, error => {
            bootbox.alert( 'Update M Role Service Error! - HTTP Status ' + error.status );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }
    deleteClick(id){
        this.id = id;
        }
        delete() {
              let no;
              const i = this.roleMasterArr.indexOf( this.roleMasterArr.find( r => r.roleId == this.id ) );
              this.roleMaster = this.roleMasterArr[i];
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
             this.roleMasterService.deleteMRole( this.id,1,this.loggedUser.userId ).subscribe( login1 => {
                  let no:ResponsePojo = login1;
                // alert(login1);
                  if(login1===101)
                  {
                  bootbox.alert('Cannot Delete - This role linked to User');
                  
                  }
                  else if (login1 ===0 ) {
                      bootbox.alert( 'Already deleted' );
                      
                  } else {
                      this.roleMasterArr.splice( i, 1 );
                      bootbox.alert({ message: 'Deleted',className:'text-danger',closeButton:'false'});
                      this.getMRoleAll();
                  }
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                  $( '#deleteModal' ).modal( 'hide' );
              }, error => {
                  bootbox.alert( 'Error' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              } );
          }
}
