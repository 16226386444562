import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";

@Injectable( {
    providedIn: 'root'
} )

export class TenderBidderMasterService {

    constructor( private httpclient: HttpClient ) { }
    
    getTenderBidderAll(): Observable<TenderBidderMasterPojo[]> {
        const url = StaticVariable.porturl + 'TenderBidderMasterAll/';
        return this.httpclient.get<TenderBidderMasterPojo[]>( url );
    }
     addTenderBidder( arg: TenderBidderMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddTenderBidderMaster';
        return this.httpclient.post( url, arg );
    }

    updateTenderBidder( arg: TenderBidderMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateTenderBidderMaster';
        return this.httpclient.put( url, arg );
    }

    deleteTenderBidder( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteTenderBidderMaster/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getTenderBidderMasterById(id): Observable<TenderBidderMasterPojo> {
        const url = StaticVariable.porturl + 'TenderBidderMasterById/'+id;
        return this.httpclient.get<TenderBidderMasterPojo>( url );
    }
    autoGenerateRegNum():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateRegisterNo/';
        return this.httpclient.get<any>(url);
    }
}
