import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { StaticVariable } from "src/app/globle.class";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";

@Injectable()

export class LoginService {

    constructor( private httpclient: HttpClient ) { }

    checkLogin( userLogin: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'Login';
        return this.httpclient.post( url, userLogin );
    }

    userRegister( user: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddManagementUserMaster';
        return this.httpclient.post( url, user );
    }

    checkUserName( userName ): Observable<any> {
        const url = StaticVariable.porturl + 'ManagementCheckUserName/' + userName;
        return this.httpclient.post( url, null );
    }
    checkUserpassword( userId: number, password: string ): Observable<boolean> {
        const url = StaticVariable.porturl + 'CheckMUserPassword/' + userId + '/' + password;
        return this.httpclient.get<boolean>( url );
    }
    updateUserpassword( userId: number, newPassword: string ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateMUserPassword/' + userId + '/' + newPassword;
        return this.httpclient.put( url, userId );
    }

    updatePassword( login: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'ManagementPasswordUpdate';
        return this.httpclient.put<any>( url, login );
    }
}
