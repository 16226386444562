import { Component, OnInit } from '@angular/core';
import { SettingPojo } from "src/app/Pojo/settings.pojo";
import { SettingService } from "src/app/EngSection/Admin/settings/settings.service";

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [SettingService]
})
export class SettingsComponent implements OnInit {
 setting = new SettingPojo();
 isLoading:boolean = true;
  constructor(private settingService:SettingService) { }

  ngOnInit() {
      this.settingService.getSettingsById(1).subscribe( response => {
          this.setting = response;
          ( <HTMLInputElement>document.getElementById( 'headingName' ) ).value = this.setting.pdfHeading;
          ( <HTMLInputElement>document.getElementById( 'placeName' ) ).value= this.setting.pdfPlace;
         
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Settings Service Error! HTTP Status ' + error.status } );
          this.isLoading = false;
      } );
  }
  update() {
      
      let heading = ( <HTMLInputElement>document.getElementById( 'headingName' ) ).value;
      let place = ( <HTMLInputElement>document.getElementById( 'placeName' ) ).value;
     
      if ( heading !='' && heading != undefined && heading != null ) {
          if ( place !='' && place != undefined && place != null ) {
              $( '#backupLoading' ).addClass( 'fa fa-spinner fa fa-spin' );
              this.setting.pdfHeading = heading;
              this.setting.pdfPlace = place;
              this.setting.settingId = 1;
          this.settingService.updateMRole( this.setting ).subscribe( response => {
              bootbox.alert('Successfully Updated');
              $( '#backupLoading' ).removeClass( 'fa fa-spinner fa fa-spin' ); 
                  }, error => {
              bootbox.alert('Error');
              $( '#backupLoading' ).removeClass( 'fa fa-spinner fa fa-spin' ); 
               } );
      }else{
          bootbox.alert('Please Fill the Place Name');
      }
      } else{
          bootbox.alert('Please Fill the PDF Header Name');
      }
  }
}
