import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

export class WorkCompletionPojo
{
      workCompId: number;
      workCompDate: Date;
      workCompTime: string;
      orderId: number;
      status: string;
      userId: number;
      completionNo: string;
      imageList:FtpImagePojo[];
}