export class ReceiptPojo{ 
  receiptId:number;  
  receiptDate: Date;
  receiptTime: string;
  estimateId: number;
  bidderId: number;
  mrDate: Date;
  mrTime: string;
  mrNo: number;
  receiptTypeId: number;
  amount: number;
  userId: number;
    
}