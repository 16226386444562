import { Component, OnInit } from '@angular/core';
import { ResponsePojo } from "src/app/pojo/response.pojo";
import { BackupService } from "src/app/EngSection/Admin/backup/backup.service";
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss'],
  providers: [BackupService]
})
export class BackupComponent implements OnInit {
response = new ResponsePojo();

  constructor( private backupService:BackupService) { }

  ngOnInit() {
       }  

  backUpData() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#backupLoading' ).addClass( 'fa fa-spinner fa fa-spin' );
      this.backupService.backUpData().subscribe( response => {
          this.response = response;
          alert(this.response.responseText);
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#backupLoading' ).removeClass( 'fa fa-spinner fa fa-spin' ); 
          }, error => {
          bootbox.alert( 'BackUp Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#backupLoading' ).removeClass( 'fa fa-spinner fa fa-spin' ); 
          } );
  }

}
