import { Component, OnInit } from '@angular/core';
import { TenderService } from "src/app/EngSection/WorkProgress/tender/tender.service";
import { TenderPojo } from "src/app/Pojo/Tender-pojo";
import { TenderReportPojo } from "src/app/Pojo/tender-report.pojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { SettingService } from "src/app/EngSection/Admin/settings/settings.service";
import { SettingPojo } from "src/app/Pojo/settings.pojo";
import * as moment from 'moment';
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-tender-report',
  templateUrl: './tender-report.component.html',
  styleUrls: ['./tender-report.component.scss']
})
export class TenderReportComponent implements OnInit {
    tenderArr:TenderPojo[] = [];
    tenderId;
    resultTenderId;
    payReport = new TenderReportPojo();
    tenderFlag:boolean = false;
    viewFlag:boolean = false;
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    estimateArr:EstimatePojo[] = [];
    body = [];
     content = [];
    documentTime: string;
     setting = new SettingPojo();
     status;
  constructor(private tenderService:TenderService,private tenderBidderMasterService: TenderBidderMasterService,private estimateService:EstimateService,private settingService:SettingService) { }

  ngOnInit() {
      $( '#showAfterClick' ).hide();
      this.getTenderAll();
      this.getTenderBidderMasterAll();
      this.getEstimateAll();
      this.getPdfSettings();
  }
  getPdfSettings(){
      this.settingService.getSettingsById(1).subscribe( response => {
          this.setting = response;
      }, error => {
          bootbox.alert( { message: 'Get Settings Service Error! HTTP Status ' + error.status } );
      } );
  }
  getTenderAll() {
      this.tenderService.getTenderAll().subscribe( response => {
          this.tenderArr = response;
      }, error => {
          bootbox.alert( 'Tender  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getBidderNameById( id ) {
      if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
          return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
      } else {
          return id;
      }
  }
  getTenderNumById( id ) {
      if ( this.tenderArr.find( r => r.tenderId == id ) ) {
          return this.tenderArr.find( r => r.tenderId == id ).tenderNo;
      } else {
          return id;
      }
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
      } ); 
  }
  getEstimateNumById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
  show(){
      this.tenderFlag = false;
      this.payReport = new TenderReportPojo();
      $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
     this.viewFlag = false;
      this.tenderService.getTenderReportById(this.tenderId).subscribe( response => {
          this.resultTenderId = this.tenderId;
         $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          this.payReport = response;
         
          this.viewFlag = true;
          if(this.payReport.actionList != null){
              this.tenderFlag = true;
          }
          
      }, error => {
          bootbox.alert( 'Get Report Service Error! - HTTP Status ' + error.status );
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
          this.viewFlag = false;
      } );
  }
  downloadPDF() {
      this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      this.content.push( {
          text: this.setting.pdfHeading, fontSize: 20, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: this.setting.pdfPlace + '\n', fontSize: 18, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
      } );
      this.content.push( {
          text: 'TENDER REPORT', fontSize: 13, alignment: 'center', bold: true
      } );

      
         this.content.push( {
             text: 'SELECTED TENDER NUMBER : ' + this.getTenderNumById(this.resultTenderId) + '\n', fontSize: 12, alignment: 'center',  bold: true
         } );  
         
         if(this.payReport.tpojo.tenderId !=0){
         this.content.push( {
             text: '\nTENDER DETAILS' + '\n', fontSize: 12,  alignment: 'center',   bold: true
         } );
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
         } );

     this.content.push(
             {
                 columns: [
                     {
                         width: '25%',
                         text: 'Tender Number', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':'+this.payReport.tpojo.tenderNo, fontSize: 10,
                     },
                     {
                         width: '25%',
                         text: 'Tender Date', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':'+moment(this.payReport.tpojo.tenderDate).format('DD/MM/YYYY'), fontSize: 10,
                     },
                 ]
             } );
     this.content.push(
             {
                 columns: [
                     {
                         width: '25%',
                         text: 'EMD Amount', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':' + this.payReport.tpojo.emdAmount, fontSize: 10,
                     },
                     {
                         width: '25%',
                         text: 'Tender Open Date', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':' +moment(this.payReport.tpojo.tenderOpenDate).format('DD/MM/YYYY'), fontSize: 10, 
                     },
                 ]
             } );
     this.content.push(
             {
                 columns: [
                     {
                         width: '25%',
                         text: 'Tender Close Date', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':' +moment(this.payReport.tpojo.tenderCloseDate).format('DD/MM/YYYY'), fontSize: 10,
                     },
                     {
                         width: '25%',
                         text: 'File Date', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':'+moment(this.payReport.tpojo.fileDate).format('DD/MM/YYYY'), fontSize: 10,
                     },
                 ]
             } );
     this.content.push(
             {
                 columns: [
                     {
                         width: '25%',
                         text: 'Estimate Number', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':' +this.getEstimateNumById(this.payReport.tpojo.estimateId), fontSize: 10,
                     },
                     {
                         width: '25%',
                         text: 'Tender form Cost', fontSize: 10
                     },
                     {
                         width: '25%',
                         text: ':'+this.payReport.tpojo.tenderFormCost, fontSize: 10,
                     },
                 ]
             } );
         }
         if(this.tenderFlag == true){
         this.content.push( {
             text: '\nTENDER ACTION DETAILS' + '\n', fontSize: 12,  alignment: 'center', bold: true
         } );
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
         } );
         this.body.push( [
                          { text: 'S.No', fontSize: 12, alignment: 'center', style: 'tableHeader', bold: true },
                          { text: 'Tennder Action Number', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
                          { text: 'Tender Action Date', fontSize: 12, alignment: 'center', style: 'tableHeader', bold: true },
                          { text: 'Bidder Name', fontSize: 12, alignment: 'center', style: 'tableHeader',bold: true },
                          { text: 'Action Amount', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
                          { text: 'Status', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
                          
                      ] );

                      for ( let i = 0; i < this.payReport.actionList.length; i++ ) {
                          if(this.payReport.actionList[i].selStatus == true){
                              this.status = 'SELECTED';
                          }if(this.payReport.actionList[i].selStatus == false){
                              this.status = 'NOT SELECTED';
                          }
                            this.body.push( [
                              { text: ( i + 1 ), fontSize: 10, alignment: 'center' },
                              { text: this.payReport.actionList[i].tenderActionNo, fontSize: 10, alignment: 'center' },
                              { text: moment(this.payReport.actionList[i].tActionDate).format( 'DD/MM/YYYY' ), fontSize: 10, alignment: 'center' },
                              { text: this.getBidderNameById(this.payReport.actionList[i].bidderId), fontSize: 10, alignment: 'center' },
                              { text: this.payReport.actionList[i].actionAmount, fontSize: 10,  alignment: 'center' },
                              { text: this.status, fontSize: 10,  alignment: 'center' },
                                ] );
                      }

                      this.content.push( {
                          'table': {
                              headerRows: 1,
                              'body': this.body,
                              widths: ['5%', '25%', '15%', '30%', '15%', '10%']
                          }
                      } );
         }
                      this.content.push( {
                          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
                          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
                      } );


                      const dd = {
                          'content': this.content,
                           /* 'content': [
                                      {qr:'1'},
                                      ],*/
                          pageOrientation: 'landscape',
                          pageSize: 'A4',
                          footer: ( currentPage, pageCount ) => {
                              return {
                                  margin: 10,
                                  columns: [
                                      {
                                          fontSize: 9,
                                          text: [
                                              {
                                                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                                  '\n',
                                                  margin: [0, 20]
                                              },
                                              {
                                                  text: this.setting.pdfHeading + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                              }
                                          ],
                                          alignment: 'center'
                                      }
                                  ]
                              };
                          }
                      };

                      pdfMake.createPdf( dd ).open();
                      this.body = [];
                      this.content = [];
                    }
}
