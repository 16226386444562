import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";


@Injectable( {
    providedIn: 'root'
} )

export class QuotationService {

    constructor( private httpclient: HttpClient ) { }
    
    getQuotationAll(): Observable<QuotationPojo[]> {
        const url = StaticVariable.porturl + 'QuotationAll/';
        return this.httpclient.get<QuotationPojo[]>( url );
    }
    getUniqueQuotationAll(): Observable<QuotationPojo[]> {
        const url = StaticVariable.porturl + 'GetQuotationListNotInQuotationDocument/';
        return this.httpclient.get<QuotationPojo[]>( url );
    }
     addQuotation( arg: QuotationPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddQuotation';
        return this.httpclient.post( url, arg );
    }

    updateQuotation( arg: QuotationPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateQuotation';
        return this.httpclient.put( url, arg );
    }
    updateQuotationList( arg: QuotationPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateQuotationList';
        return this.httpclient.put( url, arg );
    }

    deleteQuotation( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteQuotation/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getQuotationById(id): Observable<QuotationPojo> {
        const url = StaticVariable.porturl + 'QuotationById/'+id;
        return this.httpclient.get<QuotationPojo>( url );
    }
    getQuotationReportById(id): Observable<QuotationPojo[]> {
        const url = StaticVariable.porturl + 'GetQuotationByEstimateId/'+id;
        return this.httpclient.get<QuotationPojo[]>( url );
    }
}
