import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

export class EstimatePojo {

    estimateId:number;
    workId:number;
    estimateNo:string;
    estimateDate:Date;
    estimateTime:string;
    estimateVaue:number;
    estimateType:string;
    sanctionLevelId:number;
    estimateQuotationCompany:string;
    fileName:string;
    fileAddressPath:string;
    imageId:number;
    documentTypeId:number;
    userId:number;
    status:string;
    approvedBy:string;
    approvedDate:Date;
imageList:FtpImagePojo[];
}