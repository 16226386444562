import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
import { AgreementPojo } from "src/app/Pojo/agreement.pojo";
import { WorkProgressPojo } from "src/app/Pojo/work-progress.pojo";
import { PaymentProgressPojo } from "src/app/Pojo/payment-progress.pojo";
import { WorkCompletionPojo } from "src/app/Pojo/work-completion.pojo";

export class WorkProgressReportPojo {

    workPojo = new WorkMasterPojo();
    estimatePojo = new  EstimatePojo();
    actionPojo:TenderActionPojo[];  
    qPojo:QuotationPojo[];
    orderPojo = new WorkOrderPojo();
    agreementPojo = new AgreementPojo();
    progressPojo = new WorkProgressPojo();
    paymentList:PaymentProgressPojo[];
    completionPojo = new WorkCompletionPojo();
    code:number;
    response:string;
}