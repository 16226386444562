import { Component, OnInit } from '@angular/core';
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
import { WorkOrderService } from "src/app/EngSection/Work/work-order/work-order.service";
import * as moment from 'moment';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { EstimateBidderPojo } from "src/app/Pojo/estimate-bidder-pojo";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-work-order',
    templateUrl: './work-order.component.html',
    styleUrls: ['./work-order.component.scss']
})
export class WorkOrderComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    autoClose: true;
    addOrderDate: NgbDateStruct;
    addEEDate: NgbDateStruct;
    editOrderDate: NgbDateStruct;
    editEEDate: NgbDateStruct;

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    workorderArr: WorkOrderPojo[] = [];
    loggedUser = new MUserMasterPojo();
    addWork = new WorkOrderPojo();
    editWork = new WorkOrderPojo();
    estimateArr: EstimatePojo[] = [];
    estimateApprovedArr: EstimatePojo[] = [];
    tenderbidderArr: TenderBidderMasterPojo[] = [];
    estimateBidder = new EstimateBidderPojo();
    estimateEditBidder = new EstimateBidderPojo();

    selectedFiles: File[] = [];
    progressStatus = 0;
    fileincreament = 0;
    docCount = 0;
    imagePojo = new FtpImagePojo();
    status;
    fileNameArr = [];
    imageUploadStatus = false;
    imageEdit = true;
    fileObj: any;
    imageUploadLoading: boolean = false;

    editselectedFiles: File[] = [];
    editfileincreament = 0;
    editfileNameArr = [];
    editimageUploadStatus = false;
    editimageEdit = true;
    editfileObj: any;
    editimageUploadLoading: boolean = false;
    editdocCount = 0;
    addedFileCount: number = 0;
    tempFtpArr: FtpImagePojo[] = [];
    editimagePojo = new FtpImagePojo();
    deleteFileIncreament = 0;
    newFile: boolean = false;

    constructor(private workOrderservice: WorkOrderService, private calendar: NgbCalendar, private estimateService: EstimateService, private tenderBidderMasterService: TenderBidderMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();

        this.getWorkOrderAll();
        this.getEstimateAll();
        this.getAutoOrderNum();
        this.getTenderBidderMasterAll();
        this.getEstimateApprovedAll();
    }

    calcBalanceAmt(e) {
        this.addWork.gstAmount = (this.addWork.gstPercentage / 100) * this.addWork.taxableTenderAmount;
        this.addWork.lessAmount = (this.addWork.withHeldPercentage / 100) * this.addWork.finalTenderAmount;
        this.addWork.finalTenderAmount = this.addWork.taxableTenderAmount + this.addWork.gstAmount;
        this.addWork.netAmount = this.addWork.finalTenderAmount - this.addWork.gstAmount;
        this.addWork.itAmount = (this.addWork.itPercentage / 100) * this.addWork.taxableTenderAmount;
        this.addWork.templeGstAmount = (this.addWork.templeGstPercentage / 100) * this.addWork.taxableTenderAmount;
        this.addWork.finalAmount = this.addWork.finalTenderAmount - this.addWork.gstAmount - this.addWork.itAmount - this.addWork.templeGstAmount - this.addWork.lessAmount;
        // this.addWork.balanceAmount = this.addWork.tenderAmount - this.addWork.lessAmount - this.addWork.releaseAmount;
        this.addWork.balanceAmount = this.addWork.finalTenderAmount - this.addWork.lessAmount - this.addWork.itAmount - this.addWork.templeGstAmount - this.addWork.releaseAmount;
        // this.addWork.finalAmount = this.addWork.tenderAmount - this.addWork.lessAmount;
        // this.addWork.finalAmount = this.addWork.tenderAmount - this.addWork.lessAmount - this.addWork.gstAmount -this.addWork.itAmount - this.addWork.templeGstAmount;
    }

    calcEditBalanceAmt(e) {
        this.editWork.gstAmount = (this.editWork.gstPercentage / 100) * this.editWork.taxableTenderAmount;
        this.editWork.lessAmount = (this.editWork.withHeldPercentage / 100) * this.editWork.finalTenderAmount;
        this.editWork.finalTenderAmount = this.editWork.taxableTenderAmount + this.editWork.gstAmount;
        this.editWork.netAmount = this.editWork.finalTenderAmount - this.editWork.gstAmount;
        this.editWork.itAmount = (this.editWork.itPercentage / 100) * this.editWork.taxableTenderAmount;
        this.editWork.templeGstAmount = (this.editWork.templeGstPercentage / 100) * this.editWork.netAmount;
        this.editWork.balanceAmount = this.editWork.finalTenderAmount - this.editWork.lessAmount - this.editWork.itAmount - this.editWork.templeGstAmount - this.editWork.releaseAmount;
        this.editWork.finalAmount = this.editWork.finalTenderAmount - this.editWork.itAmount - this.editWork.templeGstAmount - this.editWork.lessAmount-this.editWork.gstAmount;
        // this.editWork.balanceAmount = this.editWork.tenderAmount - this.editWork.lessAmount - this.editWork.releaseAmount;
        // this.editWork.finalAmount = this.editWork.tenderAmount - this.editWork.lessAmount;
        // this.editWork.balanceAmount = this.editWork.tenderAmount - this.editWork.lessAmount - this.editWork.gstAmount - this.editWork.itAmount - this.editWork.templeGstAmount - this.editWork.releaseAmount;
        // this.editWork.finalAmount = this.editWork.tenderAmount - this.editWork.lessAmount - this.editWork.gstAmount - this.editWork.itAmount - this.editWork.templeGstAmount;

    }


    getWorkOrderAll() {
        this.workOrderservice.getWorkOrderAll().subscribe(response => {
            this.workorderArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('Work Order  All Service Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }
    getTenderBidderMasterAll() {
        this.tenderBidderMasterService.getTenderBidderAll().subscribe(response => {
            this.tenderbidderArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('Get Tenderbidder Master All Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }
    getEstimateAll() {
        this.estimateService.getEstimateAll().subscribe(response => {
            this.estimateArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('EstimateType  All Service Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }
    getEstimateApprovedAll() {
        this.estimateService.getEstimateApprovedAll().subscribe(response => {
            this.estimateApprovedArr = response;
        }, error => {
            bootbox.alert('EstimateApproved  All Service Error! - HTTP Status ' + error.status);
        });
    }
    getEstimateByBidderNum() {
        let no = this.getEstimateNumById(this.addWork.estimateId);
        this.estimateService.getEstimateByBidderNum(no).subscribe(response => {
            this.estimateBidder = response;
        }, error => {
            bootbox.alert('Service Error! - HTTP Status ' + error.status);
        });
    }
    getEditEstimateByBidderNum() {
        let no = this.getEstimateNumById(this.editWork.estimateId);
        this.estimateService.getEstimateByBidderNum(no).subscribe(response => {
            this.estimateEditBidder = response;
        }, error => {
            bootbox.alert('Service Error! - HTTP Status ' + error.status);
        });
    }
    getEstimateNumById(id) {
        if (this.estimateArr.find(r => r.estimateId == id)) {
            return this.estimateArr.find(r => r.estimateId == id).estimateNo;
        } else {
            return id;
        }
    }
    getBidderNameById(id) {
        if (this.tenderbidderArr.find(r => r.bidderId == id)) {
            return this.tenderbidderArr.find(r => r.bidderId == id).bidderName;
        } else {
            return id;
        }
    }
    getAutoOrderNum() {
        this.workOrderservice.autoGenerateEstNo().subscribe(response => {
            this.addWork = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('Get Auto Order Number Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }

    delete(arg) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.workorderArr.indexOf(this.workorderArr.find(response => response.orderId == this.id));
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.workOrderservice.deleteWorkOrder(this.id, this.loggedUser.userId).subscribe(response => {
            no = response;
            if (no == 0) {
                bootbox.alert('This row is already deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } else if (no == 101) {
                bootbox.alert('You Cannot Delete!This Work Order Linked to SomeOther...');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } else {
                this.workorderArr.splice(i, 1);
                bootbox.alert('Successfully deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
                $('#deleteModal').modal('hide');
                this.getWorkOrderAll();
            }

        }, error => {
            bootbox.alert('Delete  Service Error! - HTTP Status ' + error.status);
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }
    setFiles(images) {
        this.docCount = 0;
        $('#reasonModal').modal('show');
        for (let i = 0; i < images.files.length; i++) {
            this.selectedFiles.push(images.files[i]);
        } this.docCount = this.selectedFiles.length;
        // this.status = "Documents Still Not Uploading";
    }
    saveFileName() {
        if ((<HTMLInputElement>document.getElementById('fileName')).value != '') {
            let fileName = (<HTMLInputElement>document.getElementById('fileName')).value;
            this.fileNameArr.push(fileName);

            $('#reasonModal').modal('hide');
            (<HTMLInputElement>document.getElementById('fileName')).value = '';
        }

        else {
            bootbox.alert('Please Enter the FileName');
        }
    }
    modalShow() {
        $('#myModal1').modal('show');
    }
    changeImage() {
        this.imageEdit = true;
    }
    uploadFiles() {
        this.imageUploadLoading = true;
        if (this.selectedFiles.length != this.fileincreament) {
            this.id = 3;
            this.workOrderservice.uploadImage(this.selectedFiles[this.fileincreament], this.id, this.addWork.orderNo, this.fileNameArr[this.fileincreament]).subscribe((res) => {
                this.imagePojo = res;
                this.fileincreament++;
                this.uploadFiles();
                this.imageUploadLoading = false;

            }, er => {
                alert("Fialed image uploading..." + er);
                $('#addBeforeClick').show(); $('#addAfterClick').hide();
                this.imageUploadLoading = false;
            });
        } else {
            this.addWork.userId = this.loggedUser.userId;
            this.workOrderservice.addWorkOrder(this.addWork).subscribe(response => {

                (<HTMLFormElement>document.getElementById('addWorkForm')).reset();
                $('#addModal').modal('hide');
                bootbox.alert('Successfully Added');
                this.getAutoOrderNum();
                this.reset();
                this.getWorkOrderAll();
                $('#addBeforeClick').show(); $('#addAfterClick').hide();
                (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            }, error => {
                bootbox.alert('Add Work Service Error! - HTTP Status ' + error.status);
                (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                $('#addBeforeClick').show(); $('#addAfterClick').hide();
            });
        }
    }
    removeFile(images, i) {
        this.selectedFiles.splice(i, 1);
        this.fileNameArr.splice(i, 1);
        this.docCount--;
    }

    noEnter(e) {
        return !(window.event && e.keyCode == 13);
    }
    addWorkOrder() {
        (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
        $('#addBeforeClick').hide(); $('#addAfterClick').show();
        if (this.addOrderDate != null && this.addOrderDate != undefined) {
            this.addWork.orderDate = moment(this.addOrderDate.year + '-' + this.addOrderDate.month + '-' + this.addOrderDate.day, 'YYYY-MM-DD').toDate();
        }
        if (this.addEEDate != null && this.addEEDate != undefined) {
            this.addWork.eeDate = moment(this.addEEDate.year + '-' + this.addEEDate.month + '-' + this.addEEDate.day, 'YYYY-MM-DD').toDate();
        }
        this.addWork.userId = this.loggedUser.userId;
        this.addWork.bidderId = this.estimateBidder.bidderId;
        if (this.selectedFiles.length != 0) {
            this.uploadFiles();
        } else {
            this.workOrderservice.addWorkOrder(this.addWork).subscribe(response => {
                let d = new WorkOrderPojo();
                d = response;
                if (d.code == 101) {
                    (<HTMLFormElement>document.getElementById('addWorkForm')).reset();
                    $('#addModal').modal('hide');
                    bootbox.alert('Successfully Added');
                    this.getAutoOrderNum();
                    this.reset();
                    this.getWorkOrderAll();
                    $('#addBeforeClick').show(); $('#addAfterClick').hide();
                    (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                } if (d.code == 102) {
                    bootbox.alert(d.response);
                    $('#addBeforeClick').show(); $('#addAfterClick').hide();
                    (<HTMLInputElement>document.getElementById('addButton')).disabled = false;


                }
            }, error => {
                bootbox.alert('Add Work Service Error! - HTTP Status ' + error.status);
                (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                $('#addBeforeClick').show(); $('#addAfterClick').hide();
            });
        }
    }

    reset() {
        this.addWork = new WorkOrderPojo();
        this.imageEdit = true;
        this.addOrderDate = null;
        this.addEEDate = null;
        this.imageUploadLoading = false;
        this.selectedFiles = [];
        this.docCount = 0;
        this.status = "";
    }
    /*EDIT FILES*/
    editModalShow() {
        $('#myEditModal1').modal('show');
    }
    removeEditFile(images, i) {
        this.editselectedFiles.splice(i, 1);
        this.editfileNameArr.splice(i, 1);
        this.addedFileCount = this.editselectedFiles.length;
    }
    removeEditFileForEst(item, i) {
        this.tempFtpArr.push(item);
        this.editWork.imageList.splice(i, 1);

        this.editdocCount = this.editWork.imageList.length;
    }
    setEditFiles(editimages) {
        $('#editreasonModal').modal('show');
        for (let i = 0; i < editimages.files.length; i++) {
            this.editselectedFiles.push(editimages.files[i]);
        } this.addedFileCount = this.editselectedFiles.length;
        // this.status = "Documents Still Not Uploading";
        this.newFile = true;
    }
    saveEditFileName() {
        if ((<HTMLInputElement>document.getElementById('editfileName')).value != '') {
            let fileName = (<HTMLInputElement>document.getElementById('editfileName')).value;
            this.editfileNameArr.push(fileName);
            $('#editreasonModal').modal('hide');
            (<HTMLInputElement>document.getElementById('editfileName')).value = '';
        } else {
            bootbox.alert('Please Enter the FileName');
        }
    }

    uploadEditFiles() {
        if (this.editselectedFiles.length !== this.editfileincreament) {
            this.id = 3;
            this.workOrderservice.uploadImage(this.editselectedFiles[this.editfileincreament], this.id, this.editWork.orderNo, this.editfileNameArr[this.editfileincreament]).subscribe((res) => {
                this.editimagePojo = res;
                this.editfileincreament++;
                this.uploadEditFiles();
                this.editimageUploadLoading = false;
            }, er => {
                alert("Fialed image uploading..." + er);
                $('#editBeforeClick').show(); $('#editAfterClick').hide();
                this.editimageUploadLoading = false;
            });
        } else {
            this.workOrderservice.updateWorkOrder(this.editWork).subscribe(response => {
                $('#editModal').modal('hide');
                bootbox.alert('successfully Updated');
                this.editReset();
                this.getWorkOrderAll();
                (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                $('#editBeforeClick').show(); $('#editAfterClick').hide();

            }, error => {
                bootbox.alert('Update Work Service Error! - HTTP Status ' + error.status);
                (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                $('#editBeforeClick').show(); $('#editAfterClick').hide();
            });
        }
    }

    clickEdit(arg) {
        this.editWork = Object.assign({}, arg);
        this.estimateEditBidder.bidderName = this.getBidderNameById(this.editWork.bidderId);
        this.estimateEditBidder.bidderId = this.editWork.bidderId;
        if (this.editWork.orderDate != null && this.editWork.orderDate != undefined) {
            this.editOrderDate = { day: moment(this.editWork.orderDate).date(), month: moment(this.editWork.orderDate).month() + 1, year: moment(this.editWork.orderDate).year() };
        }
        if (this.editWork.eeDate != null && this.editWork.eeDate != undefined) {
            this.editEEDate = { day: moment(this.editWork.eeDate).date(), month: moment(this.editWork.eeDate).month() + 1, year: moment(this.editWork.eeDate).year() };
        }
        this.editdocCount = this.editWork.imageList.length;
        this.addedFileCount = 0;
    }


    editWorkOrder() {
        if (this.editOrderDate != null && this.editOrderDate != undefined) {
            this.editWork.orderDate = moment(this.editOrderDate.year + '-' + this.editOrderDate.month + '-' + this.editOrderDate.day, 'YYYY-MM-DD').toDate();
        }
        if (this.editEEDate != null && this.editEEDate != undefined) {
            this.editWork.eeDate = moment(this.editEEDate.year + '-' + this.editEEDate.month + '-' + this.editEEDate.day, 'YYYY-MM-DD').toDate();
        }
        this.editWork.userId = this.loggedUser.userId;
        this.editWork.bidderId = this.estimateEditBidder.bidderId;
        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        if (this.editselectedFiles.length != 0 && this.tempFtpArr.length != 0) {
            for (let j = 0; j < this.tempFtpArr.length; j++) {
                this.workOrderservice.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe(response => {
                    this.deleteFileIncreament++;
                    if (this.tempFtpArr.length == this.deleteFileIncreament) {
                        this.uploadEditFiles();
                    }
                }, error => {
                    bootbox.alert('Delete Service Error! - HTTP Status ' + error.status);
                });
            }
        } else if (this.editselectedFiles.length != 0) {
            this.uploadEditFiles();
        } else if (this.tempFtpArr.length != 0) {
            for (let j = 0; j < this.tempFtpArr.length; j++) {
                this.workOrderservice.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe(response => {
                    this.deleteFileIncreament++;
                    if (this.tempFtpArr.length == this.deleteFileIncreament) {
                        this.workOrderservice.updateWorkOrder(this.editWork).subscribe(response => {
                            $('#editModal').modal('hide');
                            bootbox.alert('successfully Updated');
                            this.editReset();
                            this.getWorkOrderAll();

                            this.newFile = false;
                            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                            $('#editBeforeClick').show(); $('#editAfterClick').hide();

                        }, error => {
                            bootbox.alert('Update Work Service Error! - HTTP Status ' + error.status);
                            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                            $('#editBeforeClick').show(); $('#editAfterClick').hide();
                        });

                    }
                }, error => {
                    bootbox.alert('Delete Service Error! - HTTP Status ' + error.status);
                });
            }
        } else {
            this.workOrderservice.updateWorkOrder(this.editWork).subscribe(response => {
                let d = new WorkOrderPojo();
                d = response;
                if (d.code == 102) {
                    bootbox.alert(d.response);
                    (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                    $('#editBeforeClick').show(); $('#editAfterClick').hide();
                } if (d.code == 101) {
                    $('#editModal').modal('hide');
                    bootbox.alert('successfully Updated');
                    this.editReset();
                    this.getWorkOrderAll();

                    this.newFile = false;
                    (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                    $('#editBeforeClick').show(); $('#editAfterClick').hide();
                }
            }, error => {
                bootbox.alert('Update Work Service Error! - HTTP Status ' + error.status);
                (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                $('#editBeforeClick').show(); $('#editAfterClick').hide();
            });

        }

    }

    editReset() {
        this.editselectedFiles = [];
        this.tempFtpArr = [];
        this.deleteFileIncreament = 0;
        this.editfileincreament = 0;
        this.addedFileCount = 0;
        this.newFile = false;
        this.addedFileCount = 0;
    }
}


