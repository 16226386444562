import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";

import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { AgreementPojo } from "src/app/Pojo/agreement.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class AgreementService {

    constructor( private httpclient: HttpClient ) { }
    
    getAgreementAll(): Observable<AgreementPojo[]> {
        const url = StaticVariable.porturl + 'AgreementAll/';
        return this.httpclient.get<AgreementPojo[]>( url );
    }
     addAgreement( arg: AgreementPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddAgreement';
        return this.httpclient.post( url, arg );
    }

    updateAgreement( arg: AgreementPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateAgreement';
        return this.httpclient.put( url, arg );
    }

    deleteAgreement( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteAgreement/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
     
    getAgreementById(id): Observable<AgreementPojo> {
        const url = StaticVariable.porturl + 'AgreementById/'+id;
        return this.httpclient.get<AgreementPojo>( url );
    }
    autoGenerateAgreementNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateAgreementNo/';
        return this.httpclient.get<any>(url);
    }
   uploadImage(file: File,id:number,no:string,desc:string): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+no+'/'+desc;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }
   deleteFtpImage( id: number): Observable<any> {
       const url = StaticVariable.porturl + 'FtpImageDelete/' + id;
       return this.httpclient.delete<any>( url );
   }
}
