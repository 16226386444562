import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { WorkOrderService } from "src/app/EngSection/Work/work-order/work-order.service";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { AgreementPojo } from "src/app/Pojo/agreement.pojo";
import { AgreementService } from "src/app/EngSection/Work/agreement/agreement.service";
import * as moment from 'moment';
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-agreement',
    templateUrl: './agreement.component.html',
    styleUrls: ['./agreement.component.scss']
  })
export class AgreementComponent implements OnInit {
    model: NgbDateStruct;
   date: { year: number, month: number };
   autoClose:true;
   addDate: NgbDateStruct;
   editDate: NgbDateStruct;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    agreementArr:AgreementPojo[]=[];
    loggedUser = new MUserMasterPojo();
    addAgree = new AgreementPojo();
    editAgree = new AgreementPojo();
    workorderArr:WorkOrderPojo[] = [];
    estimateArr:EstimatePojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
    
    selectedFiles: File[] = [];
    progressStatus = 0;
    fileincreament = 0;
    docCount = 0;
    imagePojo = new FtpImagePojo();
    status;
    fileNameArr=[];
    imageUploadStatus = false;
    imageEdit = true;
    fileObj: any;
    imageUploadLoading: boolean = false;
    
    editselectedFiles: File[] = [];
    editfileincreament = 0;
    editfileNameArr=[];
    editimageUploadStatus = false;
    editimageEdit = true;
    editfileObj: any;
    editimageUploadLoading: boolean = false;
    editdocCount = 0;
    addedFileCount:number =0;
    tempFtpArr:FtpImagePojo[]=[];
    editimagePojo = new FtpImagePojo();
    deleteFileIncreament = 0;
    newFile : boolean = false;
  constructor(private workOrderservice:WorkOrderService,private calendar: NgbCalendar,private estimateService:EstimateService,
          private tenderBidderMasterService: TenderBidderMasterService,private agreementService:AgreementService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getAgreementrAll();
      this.getWorkOrderAll();
      this.getAutoAgreementrNum();
  }
  getWorkOrderAll() {
      this.workOrderservice.getWorkOrderAll().subscribe( response => {
          this.workorderArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Work Order  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getAutoAgreementrNum() {
      this.agreementService.autoGenerateAgreementNo().subscribe( response => {
          this.addAgree = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Agreement All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getAgreementrAll() {
      this.agreementService.getAgreementAll().subscribe( response => {
          this.agreementArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Agreement All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
 
  getOrderNumById( id ) {
      if ( this.workorderArr.find( r => r.orderId == id ) ) {
          return this.workorderArr.find( r => r.orderId == id ).orderNo;
      } else {
          return id;
      }
  }
  getOrderDateById( id ) {
      if ( this.workorderArr.find( r => r.orderId == id ) ) {
          return this.workorderArr.find( r => r.orderId == id ).orderDate;
      } else {
          return id;
      }
  }
 
  clickEdit( arg ) {
      this.editAgree = Object.assign( {}, arg );
      if(this.editAgree.agreementDate != null && this.editAgree.agreementDate != undefined){
          this.editDate = { day: moment( this.editAgree.agreementDate ).date(), month: moment( this.editAgree.agreementDate ).month()+1, year: moment( this.editAgree.agreementDate ).year() };
           }
      this.editdocCount = this.editAgree.imageList.length;
  }
    

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.agreementArr.indexOf( this.agreementArr.find( response => response.agreementId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.agreementService.deleteAgreement( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }else if ( no == 101 ) {
              bootbox.alert( 'You Cannot Delete!This Agreement Linked to SommeOther...' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.agreementArr.splice( i, 1 );
              bootbox.alert( 'Successfully deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              this.getWorkOrderAll();
          }
         
      }, error => {
          bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
  setFiles(images) {
        this.docCount = 0;
      $( '#reasonModal' ).modal( 'show' );
       for(let i=0;i<images.files.length;i++) {   
       this.selectedFiles.push(images.files[i]);
   }   this.docCount = this.selectedFiles.length;
         // this.status = "Documents Still Not Uploading";
   }
       saveFileName(){
       if(( <HTMLInputElement>document.getElementById( 'fileName' ) ).value!='')
       {
           let fileName = ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value; 
       this.fileNameArr.push(fileName);
      
       $( '#reasonModal' ).modal( 'hide' );
       ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value ='';
       }
      
  else{
      bootbox.alert('Please Enter the FileName');
  }
 }  
  modalShow(){
      $( '#myModal1' ).modal( 'show' );
  }
  changeImage() {
      this.imageEdit = true;
  }
  uploadFiles() {
      this.imageUploadLoading = true;
     if(this.selectedFiles.length != this.fileincreament) {
     this.id = 4;
     this.agreementService.uploadImage( this.selectedFiles[this.fileincreament] , this.id, this.addAgree.agreementNo,this.fileNameArr[this.fileincreament] ).subscribe(( res ) => {
         this.imagePojo = res;
         this.fileincreament++;
         this.uploadFiles();
         this.imageUploadLoading = false;
        
     }, er => { alert( "Fialed image uploading..." + er ); 
     $('#addBeforeClick').show();$('#addAfterClick').hide();
     this.imageUploadLoading = false; } );
     }else{
         this.addAgree.userId = this.loggedUser.userId;
         this.agreementService.addAgreement( this.addAgree ).subscribe( response => {
             
              ( <HTMLFormElement>document.getElementById( 'addWorkForm' ) ).reset();
              $( '#addModal' ).modal( 'hide' );
              bootbox.alert('Successfully Added');
              this.reset();
             this.getAgreementrAll();
             this.getAutoAgreementrNum();
             $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
             ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              }, error => {
             bootbox.alert( 'Add  Service Error! - HTTP Status ' + error.status );
             ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
             $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
         } );
     }
     }
 removeFile(images,i) {
     this.selectedFiles.splice(i,1);
     this.fileNameArr.splice(i,1);
     this.docCount--;
 }

 noEnter( e ) {
     return !( window.event && e.keyCode == 13 );
 }
 addAgreement(){
     ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
     $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
     if(this.addDate != null && this.addDate != undefined){
         this.addAgree.agreementDate = moment( this.addDate.year + '-' + this.addDate.month + '-' + this.addDate.day, 'YYYY-MM-DD' ).toDate();
         }
     if(this.selectedFiles.length != 0 ){
         this.uploadFiles();
     }else{
       this.addAgree.userId = this.loggedUser.userId;
     this.agreementService.addAgreement( this.addAgree ).subscribe( response => {
         
          ( <HTMLFormElement>document.getElementById( 'addWorkForm' ) ).reset();
          $( '#addModal' ).modal( 'hide' );
          bootbox.alert('Successfully Added');
          this.reset();
         this.getAgreementrAll();
         this.getAutoAgreementrNum();
         $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
         ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          }, error => {
         bootbox.alert( 'Add  Service Error! - HTTP Status ' + error.status );
         ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
         $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
     } );
     }
    
 }
 reset(){
     this.addAgree = new AgreementPojo();
     this.imageEdit = true;
     this.addDate = null;
     this.imageUploadLoading = false;
     this.selectedFiles = [];
     this.docCount =0;
     this.status = "";
       }
 /*EDIT FILES*/
  editModalShow(){
     $( '#myEditModal1' ).modal( 'show' );
 }
 removeEditFile(images,i) {
     this.editselectedFiles.splice(i,1);
     this.editfileNameArr.splice(i,1);
     this.addedFileCount = this.editselectedFiles.length;
   }
 removeEditFileForEst(item,i){
   this.tempFtpArr.push(item);
   this.editAgree.imageList.splice(i,1);
   this.editdocCount = this.editAgree.imageList.length;
}
 setEditFiles(editimages) {
     $( '#editreasonModal' ).modal( 'show' );
      for(let i=0;i<editimages.files.length;i++) {   
      this.editselectedFiles.push(editimages.files[i]);
  }  this.addedFileCount = this.editselectedFiles.length;
     // this.status = "Documents Still Not Uploading";
      this.newFile = true;
  }
      saveEditFileName(){
      if(( <HTMLInputElement>document.getElementById( 'editfileName' ) ).value!='')
      {
          let fileName = ( <HTMLInputElement>document.getElementById( 'editfileName' ) ).value; 
      this.editfileNameArr.push(fileName);
      $( '#editreasonModal' ).modal( 'hide' );
      ( <HTMLInputElement>document.getElementById( 'editfileName' ) ).value ='';
      }
     
 else{
     bootbox.alert('Please Enter the FileName');
 }
}  
 uploadEditFiles()
 {
if(this.editselectedFiles.length !== this.editfileincreament)
 {
this.id = 4;
this.agreementService.uploadImage( this.editselectedFiles[this.editfileincreament] , this.id, this.editAgree.agreementNo,this.editfileNameArr[this.editfileincreament] ).subscribe(( res ) => {
    this.editimagePojo = res;
    this.editfileincreament++;
    this.uploadEditFiles();
    this.editimageUploadLoading = false;
   
}, er => { alert( "Fialed image uploading..." + er ); 
$( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
this.editimageUploadLoading = false; } );   
  
}else{
    this.agreementService.updateAgreement( this.editAgree ).subscribe( response => {
        $( '#editModal' ).modal( 'hide' );
        bootbox.alert('successfully Updated');
        this.editReset();
        this.newFile = false;
        this.getAgreementrAll();
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
             
    }, error => {
        bootbox.alert( 'Update  Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
    } );
}
}
 editAgreement(){
    if(this.editDate != null && this.editDate != undefined){
        this.editAgree.agreementDate = moment( this.editDate.year + '-' + this.editDate.month + '-' + this.editDate.day, 'YYYY-MM-DD' ).toDate();
        }
     this.editAgree.userId = this.loggedUser.userId;
    ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
    $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();

  if(this.editselectedFiles.length != 0 && this.tempFtpArr.length != 0){
      for(let j=0;j<this.tempFtpArr.length;j++)
      {
          this.agreementService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe( response => {
          this.deleteFileIncreament++;
          if(this.tempFtpArr.length==this.deleteFileIncreament)
              {
              this.uploadEditFiles();
              }
          }, error => {
              bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
               } );
      }
} else if(this.editselectedFiles.length != 0){
    this.uploadEditFiles();
} else if(this.tempFtpArr.length != 0){
    for(let j=0;j<this.tempFtpArr.length;j++)
    {
        this.agreementService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe( response => {
        this.deleteFileIncreament++;
        if(this.tempFtpArr.length==this.deleteFileIncreament)
            {
            this.agreementService.updateAgreement( this.editAgree ).subscribe( response => {
                $( '#editModal' ).modal( 'hide' );
                bootbox.alert('successfully Updated');
                this.editReset();
                this.newFile = false;
                this.getAgreementrAll();
                ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
                $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
                     
            }, error => {
                bootbox.alert( 'Update  Service Error! - HTTP Status ' + error.status );
                ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
                $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            } );
            }
        }, error => {
            bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
             } );
    }
} else{
    this.agreementService.updateAgreement( this.editAgree ).subscribe( response => {
        $( '#editModal' ).modal( 'hide' );
        bootbox.alert('successfully Updated');
        this.editReset();
        this.newFile = false;
        this.getAgreementrAll();
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
             
    }, error => {
        bootbox.alert( 'Update  Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
    } );
}

}
editReset(){
   
    this.editselectedFiles = [];
    this.tempFtpArr =[];
    this.deleteFileIncreament =0;
    this.editfileincreament = 0;
    this.addedFileCount =0;
    this.newFile = false;
    this.addedFileCount = 0;
}
}


