import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { StaticVariable } from "src/app/globle.class";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { Observable } from "rxjs";

@Injectable()

export class UserCreationService {

    constructor( private httpclient: HttpClient ) { }

    getManagementUser(): Observable<MUserMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetMUserMasterAll';
        return this.httpclient.get<MUserMasterPojo[]>( url );
    }

    checkUserName( userName ): Observable<any> {
        const url = StaticVariable.porturl + 'CheckUserName/' + userName;
        return this.httpclient.post( url, null );
    }

    addManagementUser( arg: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddMUserMaster';
        return this.httpclient.post( url, arg );
    }

    deleteManagementUser( userId: number, loggedUserId: number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteMUserMaster/' + userId + '/' + loggedUserId;
        return this.httpclient.delete<any>( url );
    }

    updateManagementUser( data: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateMUserMaster';
        return this.httpclient.put( url, data );
    }
    getMUserMasterById(id: number): Observable<MUserMasterPojo> {
        const url = StaticVariable.porturl + 'MUserMasterById/'+id;
        return this.httpclient.get<MUserMasterPojo>( url );
    }
    
}
