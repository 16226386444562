import { Component, OnInit } from '@angular/core';
import { WorkOrderService } from "src/app/EngSection/Work/work-order/work-order.service";
import { WorkOrderPojo } from "src/app/Pojo/work-order.pojo";
declare var $: any;
declare var bootbox: any;
import * as moment from 'moment';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { PaymentProgressService } from "src/app/EngSection/Work/payment-progress/payment-progress.service";
import { PaymentReportPojo } from "src/app/Pojo/PaymentReportPojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { StaticVariable } from "src/app/globle.class";
import { WorkMasterService } from "src/app/EngSection/Master/work-master/work-master.service";
import { WorkMasterPojo } from "src/app/Pojo/work-master-pojo";
import { ShemeTypeMasterService } from "src/Services/shemme-type.service";
import { WorkTypeMasterService } from "src/Services/work-type-master.service";
import { SchemeTypeMasterPojo } from "src/app/Pojo/scheme-type-master-pojo";
import { WorkTypeMasterPojo } from "src/app/Pojo/work-type-master-pojo";
import { SanctionlevelService } from "src/Services/sanction-level.service";
import { SanctionLevelMasterPojo } from "src/app/Pojo/sanction-level-pojo";
import { SettingService } from "src/app/EngSection/Admin/settings/settings.service";
import { SettingPojo } from "src/app/Pojo/settings.pojo";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {
orderArr : WorkOrderPojo[] = [];

id: number;
isLoading: boolean = true;
orderId;
loggedUser = new MUserMasterPojo();
payReport = new PaymentReportPojo();
tableFlag:boolean = false;
estimateArr:EstimatePojo[] = [];
tenderbidderArr : TenderBidderMasterPojo[] = [];
workArr : WorkMasterPojo[] = [];
shemeArr : SchemeTypeMasterPojo[] = [];
workTypeArr : WorkTypeMasterPojo[] = [];
sanctionLevelArr: SanctionLevelMasterPojo[] = [];
setting = new SettingPojo();

body = [];
content = [];
resultOrderId;
documentTime: string;
fileNum;
workNames;
search;
workFlag:boolean = false;
fileFlag:boolean = false;

showWork:boolean = false;
showWorkOrder:boolean = false;
showEstimation:boolean = false;
showTAction:boolean = false;
showQAction:boolean = false;
totalGiven:number=0;
  constructor(private workMasterService: WorkMasterService,private PaymentProgressService: PaymentProgressService,private workOrderService:WorkOrderService,private estimateService:EstimateService,
          private tenderBidderMasterService: TenderBidderMasterService,private settingService:SettingService,private sanctionLevelService:SanctionlevelService,private schemeTypeService:ShemeTypeMasterService,private workTypeMasterservice:WorkTypeMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      
      $( '#showAfterClick' ).hide();
      this.getWorkMasterAll();
      this.getWorkOrderAll();
      this.getTenderBidderMasterAll();
      this.getEstimateAll();
      this.getShemeTypeAll();
      this.getWorkTypeAll();
      this.getSanctionLevelAll();
      this.getPdfSettings();
  }
  getPdfSettings(){
      this.settingService.getSettingsById(1).subscribe( response => {
          this.setting = response;
      }, error => {
          bootbox.alert( { message: 'Get Settings Service Error! HTTP Status ' + error.status } );
      } );
  }
  getWorkOrderAll() {
      this.workOrderService.getWorkOrderAll().subscribe( response => {
          this.orderArr = response;
      }, error => {
          bootbox.alert( 'Get WorkOrder All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkMasterAll() {
      this.workMasterService.getWorkMasterAll().subscribe( response => {
          this.workArr = response;
      }, error => {
          bootbox.alert( 'Get Work Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getShemeTypeAll() {
      this.schemeTypeService.getSchemeTypeAll().subscribe( response => {
          this.shemeArr = response;
      }, error => {
          bootbox.alert( 'Get ShemeType All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkTypeAll() {
      this.workTypeMasterservice.getWorkTypeAll().subscribe( response => {
          this.workTypeArr = response;
      }, error => {
          bootbox.alert( 'Get WorkType All Error! - HTTP Status ' + error.status );
      } );
  }
  getWorkTypeById( id ) {
      if ( this.workTypeArr.find( r => r.workTypeId == id ) ) {
          return this.workTypeArr.find( r => r.workTypeId == id ).workType;
      } else {
          return id;
      }
  }
  getWorkNameById( id ) {
      if ( this.workArr.find( r => r.workId == id ) ) {
          return this.workArr.find( r => r.workId == id ).workName;
      } else {
          return id;
      }
  }
  getFileNumById( id ) {
      if ( this.workArr.find( r => r.workId == id ) ) {
          return this.workArr.find( r => r.workId == id ).fileNo;
      } else {
          return id;
      }
  }
  getSanctionLevelAll() {
      this.sanctionLevelService.getSanctionLevelAll().subscribe( response => {
          this.sanctionLevelArr = response;
      }, error => {
          bootbox.alert( 'SanctionLevel All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getSchemeTypeById( id ) {
      if ( this.shemeArr.find( r => r.schemeTypeId == id ) ) {
          return this.shemeArr.find( r => r.schemeTypeId == id ).schemeType;
      } else {
          return id;
      }
  }
  getSanctioById( id ) {
      if ( this.sanctionLevelArr.find( r => r.sanctionLevelId == id ) ) {
          return this.sanctionLevelArr.find( r => r.sanctionLevelId == id ).sanctionLevel;
      } else {
          return id;
      }
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getEstimateNumById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
  getOrderNumById( id ) {
      if ( this.orderArr.find( r => r.orderId == id ) ) {
          return this.orderArr.find( r => r.orderId == id ).orderNo;
      } else {
          return id;
      }
  }
  getBidderNameById( id ) {
      if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
          return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
      } else {
          return id;
      }
  }
  searchType(){  
      if(this.search == "FILE"){
          this.workFlag = false;
          this.fileFlag = true;
      }
 if(this.search == "WORK"){
     this.workFlag = true;
     this.fileFlag = false;
      }
  }
  show(){
      this.payReport = new PaymentReportPojo();
      this.tableFlag = false;
      $( '#showBeforeClick' ).hide(); $( '#showAfterClick' ).show();
      this.PaymentProgressService.getPaymentReportByWorkId(this.orderId).subscribe( response => {
          this.payReport = response;
          for(let i=0;i<this.payReport.paymentList.length;i++){
      this.totalGiven = this.totalGiven+this.payReport.paymentList[i].amount;
          }
          this.isLoading = false;
          this.tableFlag = true;
          this.showWork = false;
          this.showWorkOrder = false;
          this.showEstimation = false;
          this.showTAction = false;
          this.showQAction = false;
          this.resultOrderId = this.orderId;
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
            if(this.payReport.workPojo.workId == 0 ){
              this.showWork = false;
          }else{
              this.showWork = true;
          }
          if(this.payReport.orderPojo.orderId == 0 ){
              this.showWorkOrder = false;
          }else{
              this.showWorkOrder = true;
          }
          if(this.payReport.estimatePojo.estimateId == 0 ){
              this.showEstimation = false;
          }else{
              this.showEstimation = true;
          }
          if(this.payReport.actionPojo.tActionId == 0 ){
              this.showTAction = false;
          }else{
              this.showTAction = true;
          }
          if(this.payReport.qPojo.quotationId == 0 ){
              this.showQAction = false;
          }else{
              this.showQAction = true;
          }
          
      }, error => {
          bootbox.alert( ' Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
          $( '#showBeforeClick' ).show(); $( '#showAfterClick' ).hide();
      } );
      
  } 
  
  getOrderBalanceAmtById( id ) {
      if ( this.orderArr.find( r => r.orderId == id ) ) {
          return this.orderArr.find( r => r.orderId == id ).balanceAmount;
      } else {
          return id;
      }
  }
  downloadPDF() {
      this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      this.content.push( {
          text: this.setting.pdfHeading, fontSize: 20, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: this.setting.pdfPlace + '\n', fontSize: 18, alignment: 'center', bold: true
      } );
      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
      } );
      this.content.push( {
          text: 'PAYMENT REPORT', fontSize: 13, alignment: 'center', bold: true
      } );

      
      if(this.search == "FILE"){
         this.content.push( {
             text: 'SELECTED FILE NUMBER : ' + this.getFileNumById(this.resultOrderId) + '\n', fontSize: 12, alignment: 'center',  bold: true
         } );  
      }
      if(this.search == "WORK"){
          this.content.push( {
              text: 'SELECTED FILE NAME : ' + this.getWorkNameById(this.resultOrderId) + '\n', fontSize: 12, alignment: 'center',  bold: true
          } );  
       }
       this.content.push( {
          text: '\n', fontSize: 12, alignment: 'center'
      } );
       if(this.showWork == true){
           
           this.content.push( {
               text: 'FILE DETAILS' + '\n', fontSize: 12, alignment: 'center',  bold: true
           } );
           this.content.push( {
               text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
               fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
           } );
       this.content.push(
               {
                   columns: [
                       {
                           width: '25%',
                           text: 'File Name', fontSize: 12,bold: true 
                       },
                       {
                           width: '25%',
                           text: ':'+this.payReport.workPojo.workName, fontSize: 12,
                       },
                       {
                           width: '25%',
                           text: 'File Number', fontSize: 12,bold: true
                       },
                       {
                           width: '25%',
                           text: ':'+this.payReport.workPojo.fileNo, fontSize: 12,
                       },
                   ]
               } );
       this.content.push(
               {
                   columns: [
                       {
                           width: '25%',
                           text: 'Work Type', fontSize: 12,bold: true
                       },
                       {
                           width: '25%',
                           text: ':' +this.getWorkTypeById(this.payReport.workPojo.workTypeId), fontSize: 12,
                       },
                       {
                           width: '25%',
                           text: 'Project Date', fontSize: 12, bold: true
                       },
                       {
                           width: '25%',
                           text: ':' +moment(this.payReport.workPojo.projectDate).format('DD/MM/YYYY'), fontSize: 12, 
                       },
                   ]
               } );
       this.content.push(
               {
                   columns: [
                       {
                           width: '25%',
                           text: 'Scheme Type', fontSize: 12, bold: true
                       },
                       {
                           width: '25%',
                           text: ':' + this.getSchemeTypeById(this.payReport.workPojo.schemeTypeId), fontSize: 12,
                       },
                       {
                           width: '25%',
                           text: 'Project Status', fontSize: 12,bold: true
                       },
                       {
                           width: '25%',
                           text: ':'+this.payReport.workPojo.status, fontSize: 12,
                       },
                   ]
               } );
       }
       if(this.showWorkOrder == true){
           this.content.push( {
               text: '\nWORK ORDER DETAILS' + '\n', fontSize: 12,alignment:'center', bold: true
           } );
           this.content.push( {
               text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
               fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
           } );
          
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Estimation Number', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.getEstimateNumById(this.payReport.orderPojo.estimateId), fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Release Amount', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.orderPojo.releaseAmount, fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Bidder Name', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +this.getBidderNameById(this.payReport.orderPojo.bidderId), fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Balance Amount', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +this.payReport.orderPojo.balanceAmount, fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Deposit Amount', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' + this.payReport.orderPojo.depositAmount, fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Order Date', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+moment(this.payReport.orderPojo.orderDate).format('DD-MM-YYYY'), fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Tender Amount', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' + this.payReport.orderPojo.tenderAmount, fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'EE Date', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+moment(this.payReport.orderPojo.eeDate).format('DD-MM-YYYY'), fontSize: 12
                           },
                       ]
                   } );
           }
       if(this.showEstimation == true){
           this.content.push( {
               text: '\nESTIMATION DETAILS' + '\n', fontSize: 12,alignment: 'center',    bold: true
           } );
           this.content.push( {
               text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
               fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
           } );
          
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Estimation Number', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.getEstimateNumById(this.payReport.estimatePojo.estimateId), fontSize:12
                           },
                           {
                               width: '25%',
                               text: 'Estimated Amount', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.estimatePojo.estimateVaue, fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'File Name', fontSize: 12,bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +this.getWorkNameById(this.payReport.estimatePojo.workId), fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Estimation Date', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +moment(this.payReport.estimatePojo.estimateDate).format('DD/MM/YYYY'), fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Company Name', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' + this.payReport.estimatePojo.estimateQuotationCompany, fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Approved By', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.estimatePojo.approvedBy, fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Sanction Level', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' + this.getSanctioById(this.payReport.estimatePojo.sanctionLevelId), fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Approved Date', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+moment(this.payReport.estimatePojo.approvedDate).format('DD-MM-YYYY'), fontSize: 12
                           },
                       ]
                   } );
           }
       if(this.showTAction == true){
           this.content.push( {
               text: '\nTENDER ACTION DETAILS' + '\n', fontSize: 12,  alignment: 'center', bold: true
           } );
           this.content.push( {
               text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
               fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
           } );
          
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Tender Action Number', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.payReport.actionPojo.tenderActionNo, fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Bidder Name', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.getBidderNameById(this.payReport.actionPojo.bidderId), fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Tender Action Date ', fontSize: 12,  bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +moment(this.payReport.actionPojo.tActionDate).format('DD-MM-YYYY'), fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Action Amount', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +this.payReport.actionPojo.actionAmount, fontSize: 12
                           },
                       ]
                   } );
           
           }
       if(this.showQAction == true){
           this.content.push( {
               text: '\nQUOTATION ACTION DETAILS' + '\n', fontSize: 12, alignment: 'center',  bold: true
           } );
           this.content.push( {
               text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
               fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
           } );
           
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Estimation Number', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.getEstimateNumById(this.payReport.qPojo.estimateId), fontSize:12
                           },
                           {
                               width: '25%',
                               text: 'Bidder Name', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':'+this.getBidderNameById(this.payReport.qPojo.bidderId), fontSize: 12
                           },
                       ]
                   } );
           this.content.push(
                   {
                       columns: [
                           {
                               width: '25%',
                               text: 'Quotation Date ', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +moment(this.payReport.qPojo.quotationDate).format('DD-MM-YYYY'), fontSize: 12
                           },
                           {
                               width: '25%',
                               text: 'Quotation Amount', fontSize: 12, bold: 'true'
                           },
                           {
                               width: '25%',
                               text: ':' +this.payReport.qPojo.quotationAmount, fontSize: 12
                           },
                       ]
                   } );
           }
       
       this.content.push( {
           text: '\nPAYMENT DETAILS' + '\n', fontSize: 12,  alignment: 'center', bold: true
       } );
       this.content.push( {
           text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
           fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
       } );
      this.body.push( [
          { text: 'S.No', fontSize: 12, alignment: 'center', style: 'tableHeader', bold: true },
          { text: 'MBook Number', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
          { text: 'Date', fontSize: 12, alignment: 'center', style: 'tableHeader', bold: true },
          { text: 'Amount', fontSize: 12, alignment: 'center', style: 'tableHeader',bold: true },
          { text: 'Balance Amount', fontSize: 12, alignment: 'center', style: 'tableHeader',  bold: true },
         
      ] );

      for ( let i = 0; i < this.payReport.paymentList.length; i++ ) {
            this.body.push( [
              { text: ( i + 1 ), fontSize: 10, alignment: 'center' },
              { text: this.payReport.paymentList[i].voucherNo, fontSize: 10, alignment: 'center' },
              { text: moment(this.payReport.paymentList[i].voucherDate).format( 'DD/MM/YYYY' ), fontSize: 10, alignment: 'center' },
              { text: this.payReport.paymentList[i].amount, fontSize: 10, alignment: 'center' },
              { text: this.getOrderBalanceAmtById(this.payReport.paymentList[i].orderId), fontSize: 10,  alignment: 'center' },
                ] );
      }

      this.content.push( {
          'table': {
              headerRows: 1,
              'body': this.body,
              widths: ['5%', '35%', '30%', '15%', '15%']
          }
      } );

      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center', fontcolor: 'black'
      } );


      const dd = {
          'content': this.content,
          /*  'content': [
                      {qr:'1'},
                      ],*/
          pageOrientation: 'landscape',
          pageSize: 'A4',
          footer: ( currentPage, pageCount ) => {
              return {
                  margin: 10,
                  columns: [
                      {
                          fontSize: 9,
                          text: [
                              {
                                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                  '\n',
                                  margin: [0, 20]
                              },
                              {
                                  text: this.setting.pdfHeading + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                              }
                          ],
                          alignment: 'center'
                      }
                  ]
              };
          }
      };

      pdfMake.createPdf( dd ).open();
      this.body = [];
      this.content = [];
    }
}
