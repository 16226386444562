
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LogDetailsPojo } from "src/app/pojo/log-details-pojo";
import { Router } from "@angular/router";
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
    loggedUser = new MUserMasterPojo();
    logpojo = new LogDetailsPojo();
  constructor(private router: Router ) { }

  ngOnInit() {
  }
  logout() {
      bootbox.confirm( 'Do you want to Logout?', ( result ) => {
          if ( result ) {
              this.logpojo.logDescription = this.loggedUser.name + " is log out from the management page ";
              this.logpojo.userId = this.loggedUser.userId;
              this.logpojo.name = this.loggedUser.name;
              this.logpojo.logName = "log out";
              sessionStorage.setItem( 'loggedUser', null );
              this.router.navigate( [`/login`] );
          }
      } );
  }
}
