
import { Component, OnInit } from '@angular/core';
import { ReceiptTypePojo } from "src/app/Pojo/receipt-type-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { ReceiptTypeMasterService } from "src/app/EngSection/Master/receipt-type-master/receipt-type-master.service";
import { SectionMasterPojo } from 'src/app/Pojo/section-master.pojo';
import { SectionMasterService } from './section-master.service';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-section-master',
  templateUrl: './section-master.component.html',
  styleUrls: ['./section-master.component.scss']
})
export class SectionMasterComponent implements OnInit {

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    sectionArr : SectionMasterPojo[] = [];
    addSection = new SectionMasterPojo();
    editSection = new SectionMasterPojo();
    
  constructor(private sectionMasterService: SectionMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );

      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getSectionMasterAll();
  }
  getSectionMasterAll() {
      this.sectionMasterService.getSectionMasterAll().subscribe( response => {
          this.sectionArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Section All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  add() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
       this.sectionMasterService.addSectionMaster( this.addSection ).subscribe( response => {
        $( '#addModal' ).modal( 'hide' );
        this.getSectionMasterAll();
           bootbox.alert('Successfully Added');
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              
      }, error => {
          bootbox.alert( 'Add  Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
  }
  
  clickEdit( arg ) {
      this.editSection = Object.assign( {}, arg );
       }

  edit() {
       ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.sectionMasterService.updateSectionMaster( this.editSection ).subscribe( response => {
        $( '#editModal' ).modal( 'hide' );
        this.getSectionMasterAll();
            bootbox.alert('Successfully Updated');
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
           }, error => {
          bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.sectionArr.indexOf( this.sectionArr.find( response => response.sectionId  == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.sectionMasterService.deleteSectionMaster( this.id,this.loggedUser.userId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( 'This row is already deleted' );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }else if ( no == 101 ) {
              bootbox.alert( 'You Cannot Delete!This Receipt Type linked to Some Other.. ');
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }  else {
              this.sectionArr.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
              $( '#deleteModal' ).modal( 'hide' );
              bootbox.alert( 'Successfully deleted' );
              this.getSectionMasterAll();
          }
          
      }, error => {
          bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
}

