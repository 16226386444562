import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
  
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { WorkCompletionPojo } from "src/app/Pojo/work-completion.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class WorkCompletionService {

    constructor( private httpclient: HttpClient ) { }
    
    getWorkCompletionAll(): Observable<WorkCompletionPojo[]> {
        const url = StaticVariable.porturl + 'WorkCompletionAll/';
        return this.httpclient.get<WorkCompletionPojo[]>( url );
    }
     addWorkCompletion( arg: WorkCompletionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddWorkCompletion';
        return this.httpclient.post( url, arg );
    }

    updateWorkCompletion( arg: WorkCompletionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateWorkCompletion';
        return this.httpclient.put( url, arg );
    }

    deleteWorkCompletion( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteWorkCompletion/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
     
    getWorkCompletionById(id): Observable<WorkCompletionPojo> {
        const url = StaticVariable.porturl + 'WorkCompletionById/'+id;
        return this.httpclient.get<WorkCompletionPojo>( url );
    }
    autoGenerateCompletionNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateCompletionNo/';
        return this.httpclient.get<any>(url);
    }
   uploadImage(file: File,id:number,no:string,desc:string): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+no+'/'+desc;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }
   deleteFtpImage( id: number): Observable<any> {
       const url = StaticVariable.porturl + 'FtpImageDelete/' + id;
       return this.httpclient.delete<any>( url );
   }
}
