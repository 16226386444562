import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { SectionMasterPojo } from 'src/app/Pojo/section-master.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class SectionMasterService {

    constructor( private httpclient: HttpClient ) { }
    
    getSectionMasterAll(): Observable<SectionMasterPojo[]> {
        const url = StaticVariable.porturl + 'SectionMasterAll/';
        return this.httpclient.get<SectionMasterPojo[]>( url );
    }
     addSectionMaster( arg: SectionMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSectionMaster';
        return this.httpclient.post( url, arg );
    }

    updateSectionMaster( arg: SectionMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSectionMaster';
        return this.httpclient.put( url, arg );
    }

    deleteSectionMaster( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSectionMaster/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
   
}
