import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";

import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { WorkProgressPojo } from "src/app/Pojo/work-progress.pojo";
import { WorkProgressReportPojo } from "src/app/Pojo/work-progress-report.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class WorkProgressService {

    constructor( private httpclient: HttpClient ) { }
    
    getWorkProgressAll(): Observable<WorkProgressPojo[]> {
        const url = StaticVariable.porturl + 'WorkProgressAll/';
        return this.httpclient.get<WorkProgressPojo[]>( url );
    }
     addWorkProgress( arg: WorkProgressPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddWorkProgress';
        return this.httpclient.post( url, arg );
    }

    updateWorkProgress( arg: WorkProgressPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateWorkProgress';
        return this.httpclient.put( url, arg );
    }

    deleteWorkProgress( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteWorkProgress/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
     
    getWorkProgressById(id): Observable<WorkProgressPojo> {
        const url = StaticVariable.porturl + 'WorkProgressById/'+id;
        return this.httpclient.get<WorkProgressPojo>( url );
    }
    autoGenerateEstNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateProgressNo/';
        return this.httpclient.get<any>(url);
    }
   uploadImage(file: File,id:number,no:string,desc:string): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+no+'/'+desc;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }
   deleteFtpImage( id: number): Observable<any> {
       const url = StaticVariable.porturl + 'FtpImageDelete/' + id;
       return this.httpclient.delete<any>( url );
   }
   getWorkProgressReportByDateANdStatus(from,to,status): Observable<WorkProgressPojo[]> {
       const url = StaticVariable.porturl + 'GetWorkProgressByDateAndStatus/'+from+'/'+to+'/'+status;
       return this.httpclient.get<WorkProgressPojo[]>( url );
   }
   getWorkProgressReportByOrderId(id): Observable<WorkProgressPojo> {
       const url = StaticVariable.porturl + 'GetWorkProgressByOrderId/'+id;
       return this.httpclient.get<WorkProgressPojo>( url );
   }
  
}
