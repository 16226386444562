import { Injectable } from '@angular/core';
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable( {
    providedIn: 'root'
} )

export class RoleMasterService {

    constructor( private httpclient: HttpClient ) { }
    
    getMRoleAll(): Observable<MRolePojo[]> {
        const url = StaticVariable.porturl + 'MRoleAll';
        return this.httpclient.get<MRolePojo[]>( url );
    }
    getMRoleByName( roleName: string ): Observable<MRolePojo> {
        const url = StaticVariable.porturl + 'MRoleByName/' + roleName;
        return this.httpclient.get<MRolePojo>( url );
    }

    addMRole( arg: MRolePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddMRole';
        return this.httpclient.post( url, arg );
    }

    updateMRole( arg: MRolePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateMRole';
        return this.httpclient.put( url, arg );
    }

    deleteMRole( arg: number, cid: number, uid: number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteMRole/' + arg + '/' + cid + '/' + uid;
        return this.httpclient.delete<any>( url );
    }
}
