export class QuotationPojo{
    
    quotationId: number;
    quotationDate: Date;
    quotationTime: string;
    estimateId: number;
    bidderId: number;
    quotationAmount: number;
    selstatus: boolean;
    userId: number;
    quotationNo: string;
    
}