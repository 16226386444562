import { Component, OnInit } from '@angular/core';
import { TenderBidderDocumentService } from "src/app/EngSection/WorkProgress/tender-bidder-doc/tender-bidder-doc.service";
import { TenderBidderDocumentPojo } from "src/app/Pojo/tender-bidder-document.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { EstimateTypeMasterPojo } from "src/app/Pojo/estimate-type-pojo";
import * as moment from 'moment';
import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-tender-bidder-doc-list',
  templateUrl: './tender-bidder-doc-list.component.html',
  styleUrls: ['./tender-bidder-doc-list.component.scss']
})
export class TenderBidderDocListComponent implements OnInit {
tenderbiddderdoarr:TenderBidderDocumentPojo[] =[];
public data;
public sortBy = 'i';
public rowsOnPage = 10;
public filterQuery = '';  
public sortOrder = 'asc';
estimateArr: EstimatePojo[]=[];
tenderbidderArr : TenderBidderMasterPojo[] = [];
isLoading:boolean=true;
estimateTpeArr:EstimateTypeMasterPojo[] = [];
tempdate:any;
addTender = new TenderBidderDocumentPojo();

docCount = 0;
imagePojo = new FtpImagePojo();
selectedFiles: File[] = [];
newFile : boolean = false;
fileincreament = 0;
tempFtpArr:FtpImagePojo[]=[];;
deleteFileIncreament = 0;
fileNameArr=[];
addedFileCount:number =0;
loggedUser = new MUserMasterPojo();
id:number;
  constructor(private tenderBidderMasterService: TenderBidderMasterService,private estimateTypeservice:EstimateTypeMasterPojoService,
          private tenderbidderDocumentService:TenderBidderDocumentService,private estimateService:EstimateService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      
      $( '#addAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
    
      this.getTenderBidderDocumentListAll();
      this.getEstimateAll();
      this.getTenderBidderMasterAll();
      this.getEstimateTypeAll();
  }
  
  
  getTenderBidderDocumentListAll() {
      this.tenderbidderDocumentService.getTenderBidderDocumentAll().subscribe( response => {
          this.tenderbiddderdoarr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Document All Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimateTypeAll() {
      this.estimateTypeservice.getEstimateTypeAll().subscribe( response => {
          this.estimateTpeArr = response;
      }, error => {
          bootbox.alert( 'Estimate Type All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getTenderBidderMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
         
      } );
  }
  getEstimatteNumberById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
      getBidderNameById( id ) {
          if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
              return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
          } else {
              return id;
          }
      }
      getestimateTypeById(id){
          if ( this.estimateTpeArr.find( r => r.estimateTypeId == id ) ) {
              return this.estimateTpeArr.find( r => r.estimateTypeId == id ).estimateType;
          } else {
              return id;
          }
      }
      clickEdit(item){
          this.addTender = item;
          this.docCount = this.addTender.imageList.length;
          if(this.addTender.tBDocDate != null && this.addTender.tBDocDate != undefined){
              this.tempdate = { day: moment( this.addTender.tBDocDate ).date(), month: moment( this.addTender.tBDocDate ).month()+1, year: moment( this.addTender.tBDocDate ).year() };
               }
         
      }
      modalShow(){
          $( '#myModal1' ).modal( 'show' );
      }
      removeFile(images,i) {
            this.selectedFiles.splice(i,1);
            this.addedFileCount = this.selectedFiles.length;
      }
      removeFileForEst(item,i){
          this.tempFtpArr.push(item);
          this.addTender.imageList.splice(i,1);
          this.fileNameArr.splice(i,1);
          this.docCount = this.addTender.imageList.length;
      }
      setFiles(images) {
          ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value ="";
          $( '#reasonModal' ).modal( 'show' );
           for(let i=0;i<images.files.length;i++) { 
           this.selectedFiles.push(images.files[i]);
           this.addedFileCount = this.selectedFiles.length;
       } 
           this.newFile = true;
          // this.status = "Documents Still Not Uploading";
       } 
           saveFileName(){
           if(( <HTMLInputElement>document.getElementById( 'fileName' ) ).value!='')
           {
               let fileName = ( <HTMLInputElement>document.getElementById( 'fileName' ) ).value; 
           this.fileNameArr.push(fileName);
           $( '#reasonModal' ).modal( 'hide' );
           }
      else{
          bootbox.alert('Please Enter the FileName');
      }
    } 
      uploadFiles()
      {
     if(this.selectedFiles.length !== this.fileincreament)
      {
     this.id = 2 ;
     this.tenderbidderDocumentService.uploadImage( this.selectedFiles[this.fileincreament] , this.id, this.addTender.tBDocNo,this.fileNameArr[this.fileincreament] ).subscribe(( res ) => {
         this.imagePojo = res;
         this.fileincreament++;
         this.uploadFiles();
     }, er => { alert( "Failed image uploading..." + er ); 
     $('#addBeforeClick').show();$('#addAfterClick').hide();
     } );
 }else{
     this.tenderbidderDocumentService.updateTenderBidderDocument(this.addTender).subscribe( response => {
         $( '#editModal' ).modal( 'hide' );
         bootbox.alert('Successfully Updated');
         $('#addBeforeClick').show();$('#addAfterClick').hide();
          this.getTenderBidderDocumentListAll();
          this.newFile = false;
          this.reset();
         this.isLoading = false;
     }, error => {
         bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
         $('#addBeforeClick').show();$('#addAfterClick').hide();
         this.isLoading = false;
     } );
 }
    }
   update(){
     $('#addBeforeClick').hide();$('#addAfterClick').show();
     if( this.tempdate != null || this.tempdate != undefined){
         this.addTender.tBDocDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
     }
       if(this.selectedFiles.length != 0 && this.tempFtpArr.length != 0){
           for(let j=0;j<this.tempFtpArr.length;j++)
           {
               this.tenderbidderDocumentService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe( response => {
               this.deleteFileIncreament++;
               if(this.tempFtpArr.length==this.deleteFileIncreament)
                   {
                   this.uploadFiles();
                   }
               }, error => {
                   bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                    } );
           }
     } else if(this.selectedFiles.length != 0){
         this.uploadFiles();
     } else if(this.tempFtpArr.length != 0){
         for(let j=0;j<this.tempFtpArr.length;j++)
         {
             this.tenderbidderDocumentService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId).subscribe( response => {
             this.deleteFileIncreament++;
             if(this.tempFtpArr.length==this.deleteFileIncreament)
                 {
                 this.tenderbidderDocumentService.updateTenderBidderDocument(this.addTender).subscribe( response => {
                     $( '#editModal' ).modal( 'hide' );
                     bootbox.alert('Successfully Updated');
                     $('#addBeforeClick').show();$('#addAfterClick').hide();
                      this.getTenderBidderDocumentListAll();
                      this.newFile = false;
                      this.reset();
                     this.isLoading = false;
                 }, error => {
                     bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
                     $('#addBeforeClick').show();$('#addAfterClick').hide();
                     this.isLoading = false;
                 } );
                 }
             }, error => {
                 bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                  } );
         }
     } else{
         this.tenderbidderDocumentService.updateTenderBidderDocument(this.addTender).subscribe( response => {
             $( '#editModal' ).modal( 'hide' );
             bootbox.alert('Successfully Updated');
             $('#addBeforeClick').show();$('#addAfterClick').hide();
              this.getTenderBidderDocumentListAll();
              this.newFile = false;
              this.reset();
             this.isLoading = false;
         }, error => {
             bootbox.alert( 'Update Service Error! - HTTP Status ' + error.status );
             $('#addBeforeClick').show();$('#addAfterClick').hide();
             this.isLoading = false;
         } );
     }
    
     }

 delete( arg ) {
     this.id = arg;
 }

 deleteConfirm() {
     let no;
     const i = this.tenderbiddderdoarr.indexOf( this.tenderbiddderdoarr.find( response => response.tBDocId  == this.id ) );
     $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
     this.tenderbidderDocumentService.deleteTenderBidderDocument( this.id,this.loggedUser.userId ).subscribe( response => {
         no = response;
         if ( no == 0 ) {
             bootbox.alert( 'This row is already deleted' );
             $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
         }else if ( no == 101 ) {
             bootbox.alert( 'You cannot Delete!This TenderBidder Linked to SomeOther...' );
             $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
         } else {
             this.tenderbiddderdoarr.splice( i, 1 );
             bootbox.alert( 'Successfully deleted' );
             $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
             $( '#deleteModal' ).modal( 'hide' );
             this.getTenderBidderDocumentListAll();
         }
        
     }, error => {
         bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
         $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
     } );
 }
 reset(){
     this.selectedFiles = [];
     this.tempFtpArr =[];
     this.deleteFileIncreament =0;
     this.fileincreament = 0;
     this.addedFileCount =0;
 }
}
