import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { QuotationPojo } from "src/app/Pojo/quotation.pojo";
import { QuotationService } from "src/app/EngSection/WorkProgress/quotation/quotation-service";
import { EstimateTypeMasterPojoService } from "src/Services/estimate-type.service";
import { EstimatePojo } from "src/app/Pojo/estimate.pojo";
import { EstimateService } from "src/app/EngSection/WorkProgress/estimate/estimate.service";
import { TenderBidderMasterService } from "src/app/EngSection/Master/tender-bidder-master/tender-bidder-master.service";
import { TenderBidderMasterPojo } from "src/app/Pojo/tender-bidder-pojo";
import * as moment from 'moment';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss']
})
export class QuotationListComponent implements OnInit {
    tempdate:any;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    
    id: number;
    isLoading: boolean = true;
    
    loggedUser = new MUserMasterPojo();
    quotationArr : QuotationPojo[] = [];
    addQuotation = new QuotationPojo();
    estimateArr:EstimatePojo[] = [];
    tenderbidderArr : TenderBidderMasterPojo[] = [];
  constructor(private quotationService: QuotationService,private estimateService:EstimateService,
          private tenderBidderMasterService: TenderBidderMasterService) { }

  ngOnInit() {
      this.loggedUser = JSON.parse( sessionStorage.getItem( 'loggedUser' ) );
      $( '#addAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.getQuotationAll();
      this.getEstimateAll();
      this.getTenderMasterMasterAll();
  }
  getQuotationAll() {
      this.quotationService.getQuotationAll().subscribe( response => {
          this.quotationArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( 'EstimateType  All Service Error! - HTTP Status ' + error.status );
          this.isLoading = false;
      } );
  }
  getTenderMasterMasterAll() {
      this.tenderBidderMasterService.getTenderBidderAll().subscribe( response => {
          this.tenderbidderArr = response;
      }, error => {
          bootbox.alert( 'Get Tenderbidder Master All Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimateAll() {
      this.estimateService.getEstimateAll().subscribe( response => {
          this.estimateArr = response;
      }, error => {
          bootbox.alert( 'Estimate  All Service Error! - HTTP Status ' + error.status );
      } );
  }
  getEstimatteNumberById( id ) {
      if ( this.estimateArr.find( r => r.estimateId == id ) ) {
          return this.estimateArr.find( r => r.estimateId == id ).estimateNo;
      } else {
          return id;
      }
  }
      getBidderNameById( id ) {
          if ( this.tenderbidderArr.find( r => r.bidderId == id ) ) {
              return this.tenderbidderArr.find( r => r.bidderId == id ).bidderName;
          } else {
              return id;
          }
      }
      clickEdit(item){
          this.addQuotation = item;
          if(this.addQuotation.quotationDate != null && this.addQuotation.quotationDate != undefined){
              this.tempdate = { day: moment( this.addQuotation.quotationDate ).date(), month: moment( this.addQuotation.quotationDate ).month()+1, year: moment( this.addQuotation.quotationDate ).year() };
               }
         
      }
    update() {
        if( this.tempdate != null || this.tempdate != undefined){
            this.addQuotation.quotationDate = moment( this.tempdate.year + '-' + this.tempdate.month + '-' + this.tempdate.day, 'YYYY-MM-DD' ).toDate();
        }
        this.addQuotation.userId = this.loggedUser.userId;
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
          $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
          this.quotationService.updateQuotation( this.addQuotation ).subscribe( response => {
              bootbox.alert('Successfully Updated');
              $( '#editModal' ).modal( 'hide' );
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            
          }, error => {
              bootbox.alert( 'Update Quotation Service Error! - HTTP Status ' + error.status );
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
               } );
      }

      delete( arg ) {
          this.id = arg;
      }

      deleteConfirm() {
          let no;
          const i = this.quotationArr.indexOf( this.quotationArr.find( response => response.quotationId  == this.id ) );
          $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
           this.quotationService.deleteQuotation( this.id,this.loggedUser.userId ).subscribe( response => {
              no = response;
              if ( no == 0 ) {
                  bootbox.alert( 'This row is already deleted' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                    }else if ( no == 101 ) {
                        bootbox.alert( 'You cannot Delete!This Quotation Linked to SomeOther..' );
                        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                          } else {
                  this.quotationArr.splice( i, 1 );
                  bootbox.alert( 'Successfully deleted' );
                  $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                  $( '#deleteModal' ).modal( 'hide' );
                  this.getQuotationAll();
                    }
                 
          }, error => {
              bootbox.alert( 'Delete  Service Error! - HTTP Status ' + error.status );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
                 } );
      }
      
      reset(){
          this.addQuotation= new QuotationPojo();
          this.tempdate = null;
          }
}

