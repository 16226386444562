import { FtpImagePojo } from "src/app/Pojo/FtpImage-pojo.";

export class AgreementPojo{
    
      agreementId: number;
      agreementDate: Date;
      agreementTime: string;
      orderId: number;
      agreementNo: string;
      userId: number;
imageList:FtpImagePojo[];
}