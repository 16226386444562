import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { TenderActionPojo } from "src/app/Pojo/tender-action.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class TenderActionService {

    constructor( private httpclient: HttpClient ) { }
    
    getTenderActionAll(): Observable<TenderActionPojo[]> {
        const url = StaticVariable.porturl + 'TenderActionAll/';
        return this.httpclient.get<TenderActionPojo[]>( url );
    }
    getUniqueTenderActionListAll(): Observable<TenderActionPojo[]> {
        const url = StaticVariable.porturl + 'GetTenderActionListNotInTenderDocument';
        return this.httpclient.get<TenderActionPojo[]>( url );
    }
     addTenderAction( arg: TenderActionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddTenderAction';
        return this.httpclient.post( url, arg );
    }

    updateTenderAction( arg: TenderActionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateTenderAction';
        return this.httpclient.put( url, arg );
    }
    updateTenderActionList(list:TenderActionPojo[]): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateTenderActionList';
        return this.httpclient.put( url,list);
    }

    deleteTenderAction( id: number,uid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteTenderAction/' + id+'/'+uid;
        return this.httpclient.delete<any>( url );
    }
   
    getTenderActionById(id): Observable<TenderActionPojo> {
        const url = StaticVariable.porturl + 'TenderActionById/'+id;
        return this.httpclient.get<TenderActionPojo>( url );
    }
   
}
